import React from "react";
import styles from "./index.module.scss";

export default function BreadCrumbSection({content}) {

  return (
    <div className={styles.breadcrumb}>
        <div className="container">
            <div className={styles.breadcrumb__content}>
                <span>{content}</span>
            </div>
        </div>
    </div>
  );
}
