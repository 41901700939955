import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';

import { getToken } from "../../utils/tokenConfig";
import * as message from './messageActions';

const getAllInterventionStart = () => ({
    type: types.GET_ALL_INTERVENTIONS_START
})

const getAllInterventionSuccess = (data) => ({
    type: types.GET_ALL_INTERVENTIONS_SUCCESS,
    payload: data
})

const getAllInterventionFailed = () => ({
    type: types.GET_ALL_INTERVENTIONS_FAILED
})

const createInterventionStart = () => ({
  type: types.CREATE_INTERVENTION_START
})

const createInterventionSuccess = (data) => ({
  type: types.CREATE_INTERVENTION_SUCCESS,
  payload: data
})

const createInterventionFailed = () => ({
  type: types.CREATE_INTERVENTION_FAILED
})

export const getAllInterventions = () => dispatch => {
    dispatch(getAllInterventionStart())
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_INTERVENTIONS
    axios 
      .get(endpoint, config)
      .then(res => {
        // console.log(res)
        dispatch(getAllInterventionSuccess(res.data))
      }).catch(err => {
          dispatch(getAllInterventionFailed())
      });
  }

export const createIntervention = (data) => dispatch => {
    //console.log(data);
    dispatch(createInterventionStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('access_token',getToken())
    const endpoint = endpoints.CREATE_INTERVENTION
    //console.log(JSON.stringify(Object.fromEntries(formData)));
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        // console.log(res)
        dispatch(createInterventionSuccess(res.data.data))
        dispatch(getAllInterventions())
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        // console.log(err)
        dispatch(createInterventionFailed(err.response.data))
      });
  }

  const getAllScholarshipsStart = () => ({
    type: types.GET_ALL_SCHOLARSHIPS_START
})

const getAllScholarshipsSuccess = (data) => ({
    type: types.GET_ALL_SCHOLARSHIPS_SUCCESS,
    payload: data
})

const getAllScholarshipsFailed = () => ({
    type: types.GET_ALL_SCHOLARSHIPS_FAILED
})

const createScholarshipStart = () => ({
  type: types.CREATE_SCHOLARSHIP_START
})

const createScholarshipSuccess = (data) => ({
  type: types.CREATE_SCHOLARSHIP_SUCCESS,
  payload: data
})

const createScholarshipFailed = () => ({
  type: types.CREATE_SCHOLARSHIP_FAILED
})

export const getAllScholarships = () => dispatch => {
    dispatch(getAllScholarshipsStart())
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_SCHOLARSHIPS
    axios 
      .get(endpoint, config)
      .then(res => {
        //console.log(res)
        dispatch(getAllScholarshipsSuccess(res.data))
      }).catch(err => {
          dispatch(getAllScholarshipsFailed())
      });
  }

export const createScholarship = (data) => dispatch => {
    //console.log(data);
    dispatch(createScholarshipStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('access_token',getToken())
    const endpoint = endpoints.CREATE_SCHOLARSHIP
    //console.log(JSON.stringify(Object.fromEntries(formData)));
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        //console.log(res)
        dispatch(createScholarshipSuccess(res.data.data))
        dispatch(getAllScholarships())
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        //console.log(err)
        dispatch(createScholarshipFailed(err.response.data))
      });
  }

const getAllTeachersStatsStart = () => ({
    type: types.GET_ALL_TEACHERS_STATS_START
})

const getAllTeachersStatsSuccess = (data) => ({
    type: types.GET_ALL_TEACHERS_STATS_SUCCESS,
    payload: data
})

const getAllTeachersStatsFailed = () => ({
    type: types.GET_ALL_TEACHERS_STATS_FAILED
})

const createTeachersStatStart = () => ({
  type: types.CREATE_TEACHERS_STAT_START
})

const createTeachersStatSuccess = (data) => ({
  type: types.CREATE_TEACHERS_STAT_SUCCESS,
  payload: data
})

const createTeachersStatFailed = () => ({
  type: types.CREATE_TEACHERS_STAT_FAILED
})

export const getAllTeachersStats = () => dispatch => {
    dispatch(getAllTeachersStatsStart())
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_TEACHERS_STATS
    axios 
      .get(endpoint, config)
      .then(res => {
        //console.log(res)
        dispatch(getAllTeachersStatsSuccess(res.data))
      }).catch(err => {
          dispatch(getAllTeachersStatsFailed())
      });
  }

export const createTeachersStat = (data) => dispatch => {
    //console.log(data);
    dispatch(createTeachersStatStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('access_token',getToken())
    const endpoint = endpoints.CREATE_TEACHERS_STAT
    //console.log(JSON.stringify(Object.fromEntries(formData)));
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        //console.log(res)
        dispatch(createTeachersStatSuccess(res.data.data))
        dispatch(getAllTeachersStats())
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        //console.log(err)
        dispatch(createTeachersStatFailed(err.response.data))
      });
  }

const getAllOEIStatsSuccess = (data) => ({
    type: types.GET_ALL_OEI_STATS_SUCCESS,
    payload: data
})
const createOEIStatSuccess = (data) => ({
  type: types.CREATE_TEACHERS_STAT_SUCCESS,
  payload: data
})
export const getAllOEIStats = () => dispatch => {
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_OEI_STATS
    axios 
      .get(endpoint, config)
      .then(res => {
        //console.log(res)
        dispatch(getAllOEIStatsSuccess(res.data))
      }).catch(err => {
        //   dispatch(getAllOEIStatsFailed())
      });
  }

export const createOEIStat = (type,data) => dispatch => {
    dispatch(message.messageStart());
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('type',type)
    formData.append('access_token',getToken())
    const endpoint = endpoints.CREATE_OEI_STAT
    // console.log(JSON.stringify(Object.fromEntries(formData)));
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        // console.log(res)
        // dispatch(createTeachersStatSuccess(res.data.data))
        dispatch(getAllOEIStats())
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        //console.log(err)
        // dispatch(createTeachersStatFailed(err.response.data))
      });
  }