import React, { useEffect, useState } from "react";

export default function BudgetCard({name,budget,spent,identity, showDetails}) {
    const [budgetState,setBudgetState] = useState(0);
    const [spentState,setSpentState] = useState(0);

    const percentage = spentState*100/budget;
    let status = "danger"
    if (percentage<=40) {
        status = "success"
    }
    else if (percentage > 40 && percentage <= 100){
        status = "warning"
    }
    else if (percentage >100 ){
        status = "danger"
    }

    useEffect(() => {
        let timer = 0;
        if(budgetState < budget){
            timer = setTimeout(function(){
                setBudgetState(budgetState + budget/20);
                setSpentState(spentState + spent/20);
                clearInterval(timer);
            },50)
        }
        return (()=>clearInterval(timer));
      });
    return (
        <div className="col-lg-4 col-md-6 col-sm-6 mt-2 p-2">
            <div className="card" style={{overflow:"hidden"}}>
                <div className="card-body">
                    <div className="d-flex"><h6 className="flex-fill" style={{wordBreak: "break-all"}}>{name}</h6><button onClick={()=>showDetails(identity)} className="btn btn-outline-success" style={{minWidth:"110px", height:"40px"}}>View more</button></div>
                    {/* <p>Spent: {(spentState}/></p> */}
                    <div className="mt-2 progress">
                        <div className={"progress-bar bg-"+status} role="progressbar" style={{width: `${spentState*100/budget}%`}} aria-valuenow={50} aria-valuemin="0" aria-valuemax={100}>{`${(spentState*100/budget)}%`}</div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}
