import React, { useEffect, useState } from "react";
import { Converter } from "../helper";

export default function BudgetCard({project_name,budget,spent}) {
    const [budgetState,setBudgetState] = useState(0);
    const [spentState,setSpentState] = useState(0);
    const percentage = spentState*100/budget;
    let status = "danger"
    if (percentage<=40) {
        status = "success"
    }
    else if (percentage > 40 && percentage <= 100){
        status = "warning"
    }
    else if (percentage >100 ){
        status = "danger"
    }
    
    useEffect(() => {
        let timer = 0;
        if(budgetState < budget){
            timer = setTimeout(function(){
                setBudgetState(budgetState + budget/20);
                setSpentState(spentState + spent/20);
                // console.log(timer);
                clearInterval(timer);
            },50)
        }
        return (()=>clearInterval(timer));
      });
    return (
        <div className="card mt-2" style={{overflow:"hidden"}}>
            <div className="card-body">
                <h4>
                    <span style={{
                        position: "absolute",
                        fontSize: "70px",
                        transformOrigin: "left",
                        transform: "rotate(-25deg)",
                        color: "#0976bc21"
                    }}
                    >{project_name.split(" ").map((n)=>n[0]).join(" ")}</span>
                    {project_name}</h4>
                <p>Budget: <Converter value={budgetState}/></p>
                <p>Spent: <Converter value={spentState}/></p>
                <p>Percentage spent: {`${(spentState*100/budget).toFixed(2)}%`}</p>
                <div className="progress">
                    <div className={"progress-bar bg-"+status} role="progressbar" style={{width: `${spentState*100/budget}%`}} aria-valuenow={50} aria-valuemin="0" aria-valuemax={100}>{`${(spentState*100/budget).toFixed(2)}%`}</div>
                </div>
            </div>
        </div>
    );
}
