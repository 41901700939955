import * as types from '../actions/actionTypes';
import slugify from 'slugify';

const projects = {
    name: "",
    details: [],
    isLoading: false,
};
export default function reducer(state = projects, action) {
    switch (action.type) {
        case types.GET_STAKEHOLDER_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_STAKEHOLDER_SUCCESS:
            return {
                ...state,
                name: action.payload.name,
                details: action.payload.details,
                isLoading: false,
            }
        default:
            return state;
    }
}