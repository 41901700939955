import * as types from '../actions/actionTypes';

const education = {
    interventions: [],
    scholarships: [],
    teachersStats: [],
    oeiStats:[],
    isLoading: false,
};
export default function reducer(state = education, action) {
    switch (action.type) {
        case types.GET_ALL_INTERVENTIONS_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_INTERVENTIONS_SUCCESS:
            return {
                ...state,
                interventions: action.payload
            }
        case types.CREATE_INTERVENTION_START:
            return {
                ...state,
                isAdding: true,
            };
        case types.CREATE_INTERVENTION_SUCCESS:
            // const all = [action.payload,...state.interventions];
            return {
                ...state,
                // interventions: all
            }
        case types.CREATE_INTERVENTION_FAILED:
            return {
                ...state,
                addingError: ([action.payload]),
                isAdding:false
            }
            case types.GET_ALL_SCHOLARSHIPS_START:
                return {
                    ...state,
                    isLoading: true,
                };
            case types.GET_ALL_SCHOLARSHIPS_SUCCESS:
                return {
                    ...state,
                    scholarships: action.payload
                }
            case types.CREATE_SCHOLARSHIP_START:
                return {
                    ...state,
                    isAdding: true,
                };
            case types.CREATE_SCHOLARSHIP_SUCCESS:
                // const all = [action.payload,...state.interventions];
                return {
                    ...state,
                    // interventions: all
                }
            case types.CREATE_SCHOLARSHIP_FAILED:
                return {
                    ...state,
                    addingError: ([action.payload]),
                    isAdding:false
                }
        case types.GET_ALL_TEACHERS_STATS_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_TEACHERS_STATS_SUCCESS:
            return {
                ...state,
                teachersStats: action.payload
            }
        case types.CREATE_TEACHERS_STAT_START:
            return {
                ...state,
                isAdding: true,
            };
        case types.CREATE_TEACHERS_STAT_SUCCESS:
            // const all = [action.payload,...state.interventions];
            return {
                ...state,
                // interventions: all
            }
        case types.CREATE_TEACHERS_STAT_FAILED:
            return {
                ...state,
                addingError: ([action.payload]),
                isAdding:false
            }

        case types.GET_ALL_OEI_STATS_SUCCESS:
            return {
                ...state,
                oeiStats: action.payload,
                // building: action.payload.building,
            }
        default:
            return state;
    }
}