import React from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector} from "react-redux";
import Layout from '../../components/Layout';

export default function Stakeholders(props) {
    const {first_name} = useSelector(state => state.auth.user);

  return (

    <Layout>
        <BreadCrumbSection
            content={`Home`}
        />
        <div className="container p-3">
            <div className="p-3 bg-white" style={{height: "70vh", textAlign:"center"}}>
                <h1>Welcome {first_name}.</h1>
            </div>
        </div>
    </Layout>
  )
}
