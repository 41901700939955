import React, {useState,useEffect} from 'react'
import BreadCrumbSection from '../../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import Layout from '../../../components/admin/Layout';
import Modal from 'react-bootstrap/Modal'
import * as grievanceActions from '../../../redux/actions/grievanceActions';
import * as issueActions from '../../../redux/actions/issueActions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import EditableText from '../../../components/admin/EditableText';

export default function IssuesAndGrievances() {
    const {slow_burn,critical} = useSelector(state => state.issues.data);
    const grievances = useSelector(state => state.grievances.data);
    const [createIssueModal, setCreateIssueModal] = useState(false);
    const [createGrievanceModal, setCreateGrievanceModal] = useState(false);
    const [createData, setCreateData] = useState({
        "received_on":moment().format("YYYY/MM/DD"),
    })
    const dispatch = useDispatch();

    const handleCreateData = (e)=>{
        let field = e.target.id;
        setCreateData({...createData, [field] :e.target.value});
    }
    const handleCreate = (e) => {
        e.preventDefault()
        // let data = Object.assign({},createData);
        // delete data.ended;
        // createData.end_on || delete data.end_on
        if(createData.type){
            dispatch(issueActions.createIssues(createData))
        } else{
            const data = createData;
            // delete data.received_on
            dispatch(grievanceActions.createGrievances(data))
        }
                        
        setCreateData({});
        setCreateIssueModal(false);
        setCreateGrievanceModal(false);
    }
    const handleUpdateStatus = (id,status) => {
        dispatch(issueActions.updateIssue({id,status}))
    }
    const trashIssue = (id) => {
        dispatch(issueActions.deleteIssue(id))
    }
    const handleUpdateReceived = (id, received_on) => {
        dispatch(issueActions.updateIssue({id,received_on}))
    }
    const handleUpdateSubmitted = (id, submitted_on) => {
        dispatch(issueActions.updateIssue({id,submitted_on}))
    }
    const handleUpdateClosedOn = (id, closed_on) => {
        dispatch(issueActions.updateIssue({id,closed_on}))
    }
    const handleUpdateDescription = (id,description) => {
        dispatch(issueActions.updateIssue({id,description}))
    }
    const handleUpdateGrievances = (id,data) => {
        let formData = {};
        let dataToArray = Object.entries(data);
        //console.log(dataToArray)
        formData[dataToArray[0][1]] = dataToArray[1][1];
        dispatch(grievanceActions.update({id,...formData}))
    }

    // const showDetails = (index)=>{
    //     setView(true)
    //     setExpanded(issues[index])
    //     console.log(issues[index])
    // }
    useEffect(() => {
        dispatch(issueActions.getAllIssues());
        dispatch(grievanceActions.getAllGrievances());
    },[]);

  return (

    <Layout>
        <BreadCrumbSection
            content="Here you can manage all issues and grievances"
        />
        <div className="container p-3">
            <div className="row">
                <div className="col-md-12">
                    <div className="card mt-2">
                        <div className="card-header d-flex justify-content-between">
                            <span>Critical Issues</span>
                            <button className="btn btn-outline-success" onClick={()=>{setCreateData({...createData,type:"critical"}); setCreateIssueModal(true);}}><i className="fa fa-plus"></i> Add an Issue</button>
                        </div>
                        <div className="card-body row d-flex justify-content-center">
                            <div style={{overflow:"scroll"}}><table class="table bg-white">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Issue Description</th>
                                        <th>Date Received</th>
                                        <th>Date Submitted</th>
                                        <th>Status</th>
                                        <th>Close Out Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {critical && critical.map(
                                    (item,index)=> 
                                    <tr>
                                        <td>{index+1}</td>
                                        <td><EditableText value={item.description} onEnter={value=>handleUpdateDescription(item.id,value)}/></td>
                                        <td>{item.received_on ? <EditableText value={item.received_on} onEnter={value=>handleUpdateReceived(item.id,value)}/> : <span className="text-info">Not set</span>}</td>
                                        <td><EditableText value={item.submitted_on} onEnter={value=>handleUpdateSubmitted(item.id,value)}/></td>
                                        <td>{item.status == 0 ? <div className="badge badge-danger">Pending</div> : 
                                                item.status == 1 ? <div className="badge badge-warning">Ongoing</div> : 
                                                item.status == 2 ? <div className="badge badge-info">Escalated</div> : 
                                                item.status == 3 ? <div className="badge badge-success">Closed</div> : ""
                                                }</td>
                                        <td>{item.closed_on ? <EditableText value={item.closed_on} onEnter={value=>handleUpdateClosedOn(item.id,value)}/> : <span className="text-info">Not closed yet</span>}</td>
                                        <td>
                                            {
                                                item.received_on ? 
                                                    item.status == 0 ?
                                                        <React.Fragment>
                                                            <button className="btn btn-info mr-2" onClick={()=>handleUpdateStatus(item.id,2)}>Escalate</button>
                                                            <button className="btn btn-warning" onClick={()=>handleUpdateStatus(item.id,1)}>Mark as Ongoing</button>
                                                        </React.Fragment> 
                                                        :
                                                        item.status == 1 ?
                                                            <button className="btn btn-info" onClick={()=>handleUpdateStatus(item.id,2)}>Escalate</button>
                                                            :
                                                            item.status == 2 ?
                                                                <React.Fragment>
                                                                    <button className="btn btn-danger" onClick={()=>handleUpdateStatus(item.id,0)}>De-escalate</button>
                                                                    <button className="btn btn-success" onClick={()=>handleUpdateStatus(item.id,3)}>Mark as Complete</button>
                                                                </React.Fragment>
                                                                :
                                                                ""
                                                    :
                                                    <button className="btn btn-outline-success" onClick={()=>handleUpdateReceived(item.id,moment().format("YYYY-MM-DD"))}>Mark as received</button>
                                            }
                                            <button onClick={()=> trashIssue(item.id)} className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"red"}}>
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    )
                               }
                               </tbody>
                            </table></div>
                        </div>
                    </div>
                    <div className="card mt-2">
                        <div className="card-header d-flex justify-content-between">
                            <span>Slow Burn Issues</span>
                            <button className="btn btn-outline-success" onClick={()=>{setCreateData({...createData,type:"slow_burn"}); setCreateIssueModal(true);}}><i className="fa fa-plus"></i> Add an Issue</button>
                        </div>
                        <div className="card-body row d-flex justify-content-center">
                            <div style={{overflow:"scroll"}}><table class="table bg-white">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Issue Description</th>
                                        <th>Date Received</th>
                                        <th>Date Submitted</th>
                                        <th>Status</th>
                                        <th>Close Out Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {slow_burn && slow_burn.map(
                                    (item,index)=> 
                                    <tr>
                                        <td>{index+1}</td>
                                        <td><EditableText value={item.description} onEnter={value=>handleUpdateDescription(item.id,value)}/></td>
                                        <td>{item.received_on ? <EditableText value={item.received_on} onEnter={value=>handleUpdateReceived(item.id,value)}/> : <span className="text-info">Not set</span>}</td>
                                        <td><EditableText value={item.submitted_on} onEnter={value=>handleUpdateSubmitted(item.id,value)}/></td>
                                        
                                        <td>{item.status == 0 ? <div className="badge badge-danger">Pending</div> : 
                                                item.status == 1 ? <div className="badge badge-warning">Ongoing</div> : 
                                                item.status == 2 ? <div className="badge badge-info">Escalated</div> : 
                                                item.status == 3 ? <div className="badge badge-success">Closed</div> : ""
                                                }</td>
                                        <td>{item.closed_on ? <EditableText value={item.closed_on} onEnter={value=>handleUpdateClosedOn(item.id,value)}/> : <span className="text-info">Not closed yet</span>}</td>
                                        <td>
                                            
                                        {
                                                item.received_on ? 
                                                    item.status == 0 ?
                                                        <React.Fragment>
                                                            <button className="btn btn-info mr-2" onClick={()=>handleUpdateStatus(item.id,2)}>Escalate</button>
                                                            <button className="btn btn-warning" onClick={()=>handleUpdateStatus(item.id,1)}>Mark as Ongoing</button>
                                                        </React.Fragment> 
                                                        :
                                                        item.status == 1 ?
                                                            <button className="btn btn-info" onClick={()=>handleUpdateStatus(item.id,2)}>Escalate</button>
                                                            :
                                                            item.status == 2 ?
                                                                <React.Fragment>
                                                                    <button className="btn btn-danger" onClick={()=>handleUpdateStatus(item.id,0)}>De-escalate</button>
                                                                    <button className="btn btn-success" onClick={()=>handleUpdateStatus(item.id,3)}>Mark as Complete</button>
                                                                </React.Fragment>
                                                                :
                                                                ""
                                                    :
                                                    <button className="btn btn-outline-success" onClick={()=>handleUpdateReceived(item.id)}>Mark as received</button>
                                            }
                                            <button onClick={()=> trashIssue(item.id)} className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"red"}}>
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    )
                               }
                               </tbody>
                            </table></div>
                        </div>
                    </div>
                    <div className="card mt-2">
                        <div className="card-header d-flex justify-content-between">
                            <span>Grievances</span>
                            <button className="btn btn-outline-success"  onClick={()=>setCreateGrievanceModal(true)}><i className="fa fa-plus"></i> Add Grievance</button>
                        </div>
                        <div className="card-body row d-flex justify-content-center">
                            <div style={{overflow:"scroll"}}><table class="table bg-white">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Reference Number</th>
                                        <th>Project OML</th>
                                        <th>Complainant Name</th>
                                        <th>Nature of Complaint</th>
                                        <th>Status</th>
                                        <th>Date Received</th>
                                        <th>Date Submitted</th>
                                        <th>Action Taken</th>
                                        <th>Final Resolution</th>
                                        <th>Close Out Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {grievances && grievances.map(
                                    (item,index)=> 
                                    <tr>
                                        <td>{index+1}</td>
                                        <td style={{wordBreak: "break-word"}}><EditableText value={item.reference_number} onEnter={value=>{handleUpdateGrievances(item.id,{prop:"reference_number",value})}}/></td>
                                        <td style={{wordBreak: "break-word"}}><EditableText value={item.project_oml} onEnter={value=>{handleUpdateGrievances(item.id,{prop:"project_oml",value})}}/></td>
                                        <td><EditableText value={item.complainant_name} onEnter={value=>{handleUpdateGrievances(item.id,{prop:"complainant_name",value})}}/></td>
                                        <td><EditableText value={item.nature_of_complaint} onEnter={value=>{handleUpdateGrievances(item.id,{prop:"nature_of_complaint",value})}}/></td>
                                        <td>{item.status == 0 ? <div className="badge badge-danger">Pending</div> : 
                                                item.status == 1 ? <div className="badge badge-success">Completed</div> : ""
                                                }</td>
                                        <td><EditableText value={item.received_on} onEnter={value=>{handleUpdateGrievances(item.id,{prop:"received_on",value})}}/></td>
                                        <td><EditableText value={item.submitted_on} onEnter={value=>{handleUpdateGrievances(item.id,{prop:"submitted_on",value})}}/></td>
                                        <td><EditableText value={item.action_taken} onEnter={value=>{handleUpdateGrievances(item.id,{prop:"action_taken",value})}}/></td>
                                        <td>{item.final_resolution ? <EditableText value={item.final_resolution} onEnter={value=>{handleUpdateGrievances(item.id,{prop:"final_resolution",value})}}/> : <span className="text-info">Not resolved</span>}</td>
                                        <td>{item.close_out_date ? <EditableText value={item.close_out_date} onEnter={value=>{handleUpdateGrievances(item.id,{prop:"close_out_date",value})}}/> : <span className="text-info">Not closed yet</span>}</td>
                                        <td>{!item.close_out_date ? <button className="btn btn-success" onClick={()=>handleUpdateGrievances(item.id,{prop:"close_out_date",value: moment().format("YYYY-MM-DD")})}>Close</button> : ""}</td>
                                    </tr>
                                    )
                               }
                               </tbody>
                            </table></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <Modal show={createIssueModal} onHide={()=>setCreateIssueModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add an Issue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleCreate}>
                <div className="form-group">
                    <label>Date Received</label><br/>
                    <DatePicker id="received_on"  className="form-control"
                        selected={createData.received_on ? moment(createData.received_on, 'YYYY/MM/DD').toDate() : moment().toDate()}
                        onChange={(e)=>setCreateData({...createData, 'received_on' :moment(e).format('YYYY/MM/DD')})}
                    />
                </div>
                <div className="form-group">
                    <label>Issue Description</label>
                    <textarea rows={5} className="form-control" id="description" value={createData.description} onChange={handleCreateData}></textarea>
                </div>  
                <button className={'btn btn-success w-100 mt-3'}>Add</button>
            </form>
        </Modal.Body>
      </Modal>
      <Modal show={createGrievanceModal} onHide={()=>{setCreateGrievanceModal(false); setCreateData({})}}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Grievance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleCreate}>
                <div className="form-group">
                    <label>Date Received</label><br/>
                    <DatePicker id="received_on"  className="form-control"
                        selected={createData.received_on ? moment(createData.received_on, 'YYYY/MM/DD').toDate() : moment().toDate()}
                        onChange={(e)=>setCreateData({...createData, 'received_on' :moment(e).format('YYYY/MM/DD')})}
                    />
                </div>
                <div className="form-group">
                    <label>Reference Number</label>
                    <input required type="text" className="form-control" id="reference_number" value={createData.reference_number} onChange={handleCreateData}/>
                </div>
                <div className="form-group">
                    <label>Project/OML</label>
                    <input type="text" className="form-control" id="project_oml" value={createData.project_oml} onChange={handleCreateData}/>
                </div>
                <div className="form-group">
                    <label>Complainant's Name</label>
                    <input required type="text" className="form-control" id="complainant_name" value={createData.complainant_name} onChange={handleCreateData}/>
                </div>
                <div className="form-group">
                    <label>Nature of Complaint</label>
                    <input required type="text" className="form-control" id="nature_of_complaint" value={createData.nature_of_complaint} onChange={handleCreateData}/>
                </div>
                <div className="form-group">
                    <label>Action Taken</label>
                    <textarea rows={5} className="form-control" id="action_taken" value={createData.action_taken} onChange={handleCreateData}></textarea>
                </div>
                <div className="form-group">
                    <label>Final Resolution</label>
                    <textarea rows={5} className="form-control" id="final_resolution" value={createData.final_resoultion} onChange={handleCreateData}></textarea>
                </div>
                <button className={'btn btn-success w-100 mt-3'}>Submit</button>
            </form>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}
