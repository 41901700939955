import React, {useState,useEffect} from 'react'
import BreadCrumbSection from '../../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import Layout from '../../../components/admin/Layout';
import * as userActions from '../../../redux/actions/userActions'
import * as endpoints from '../../../utils/endpoints';
import * as tokenConfig from '../../../utils/tokenConfig';
import axios from "../../../utils/axios-base";
import * as message from '../../../redux/actions/messageActions';

export default function CurrentYearBudget() {
    const {data} = useSelector(state => state.activities);
    const dispatch = useDispatch();
    const handleEffect = (item)=>{
        const token = tokenConfig.getToken()
        const config = {
           headers: {
             "Accept": "application/json, text/plain, */*",
           }
        }
        const formData = JSON.parse(item.data);
        formData.access_token = token;
        const endpoint = endpoints.DOMAIN_URL
        axios 
          .post(`${endpoint}${item.path}`, formData, config)
          .then(res => {
             let update = new FormData();
             update.append('status',1);
             update.append('id',item.id);
             update.append('access_token',token);
            axios 
              .post(`${endpoints.BASE_URL}activities/update`, update, config)
              .then(res => {
                  //console.log(res);
                dispatch(userActions.getAllActivities());
                dispatch(message.messageSuccess({message:res.data.message}));
              }).catch(err => {
                //console.log(err.response);
                // dispatch(userFailed())
              });
          }).catch(err => {
            //console.log(err.response);
            // dispatch(userFailed())
          });
    }
    const trashActivity = (id) => {
        dispatch(userActions.deleteActivity(id))
    }
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    const activity = path => {
        
        let split_path = path.split('/').filter(item=>isNaN(item));

        let output = "";
        let reverse_path = split_path.reverse();
        reverse_path.forEach(element => {
            if(element!="api" && element != "v1"){
                output = output + capitalizeFirstLetter(element) + " "
            }
        });
        return output;
    }
    
    useEffect(() => {
        dispatch(userActions.getAllActivities());
        // dispatch(documentActions.getAllDocuments());
        // return () => {
        //     dispatch(budgetActions.getAllCurrentProjects());
        // };
    },[]);

  return (

    <Layout>
        <BreadCrumbSection
            content="Manage and view User Activities"
        />
        <div className="container p-3">
            <div className="row"> 
                <div className="col-md-12">
                    <div className="card mt-2">
                        <div className="card-header d-flex justify-content-between">
                            <span className="my-auto" style={{height:"min-content"}}>Users</span>
                        </div>
                        <div className="card-body row">
                            <div style={{overflow:"scroll"}}><table class="table bg-white">
                                <thead>
                                    <tr>
                                        <th>Activity</th>
                                        <th>User</th>
                                        <th>Data</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data && data.map(
                                    (item,index)=> 
                                    <tr>
                                        <td>{activity(item.path)}</td>
                                        <td>{item.user.first_name+" "+item.user.last_name}</td>
                                        <td>{Object.entries(JSON.parse(item.data)).map(item2=>
                                            item2[0].split("_")[item2[0].split("_").length - 1] != "id" && item2[0].split("_")[item2[0].split("_").length - 1] != "token" ?
                                            <React.Fragment>
                                                <b>{item2[0]}: </b>
                                                <span>{item2[1]}</span><br/>
                                            </React.Fragment>:""
                                        )}</td>
                                        <td>{item.status ==0 ? <div className="badge badge-danger">Pending</div> 
                                        : <div className="badge badge-success">Effected</div>}</td>
                                        <td>
                                            {item.status == 0 ? <button className="btn btn-info mr-2" onClick={()=>handleEffect(item)}>Approve</button> : ""}

                                            <button onClick={()=> trashActivity(item.id)} className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"red"}}>
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    )
                               }
                               </tbody>
                            </table></div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  )
}
