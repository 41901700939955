import React, { useState } from 'react'
import BreadCrumbSection from '../BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import * as currencyActions from '../../redux/actions/currencyActions'
import * as userActions from '../../redux/actions/userActions'
import EditableText from '../admin/EditableText';

export default function Settings() {
    const {type} = useSelector(state => state.auth.user)
    const {conversion} = useSelector(state => state.currency)
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);
    const [changePassword, setChangePassword] = useState({
        old_password:"",
        new_password:"",
        conpassword: "",
    })
    const passwordMismatch = changePassword.new_password !== changePassword.conpassword;
    
    const handleUpdate = (conversion) => {
        dispatch(currencyActions.updateUSD({conversion}))
    }
    const handleChangePassword = (e)=>{
        let field = e.target.id;
        setChangePassword({...changePassword, [field] :e.target.value});
    }
    const handleChange = (e) => {
        e.preventDefault()
        if(passwordMismatch){
            return
        }
        let data = changePassword;
        delete data.conpassword;

        dispatch(userActions.changePassword(data));
        setChangePassword({
        old_password:"",
        new_password:"",
        conpassword: "",});
        // setCreate(false);
    }

  return (

    <>
        <BreadCrumbSection
            content="Settings"
        />
        <div className="container p-3">
            <div className="row"> 
                <div className="col-md-12 form-group text-center">
                    {type == 5 ?
                    <>
                    <h3>US Dollars to naira conversion rate: </h3> <br/>
                    <EditableText value={parseFloat(conversion)}  onEnter={value=>handleUpdate(value)} /> Naira/$
                        </>
                        :""
                }
                    <h3 className="mt-3">Change password</h3>
                    <form onSubmit={handleChange} className="w-100 m-auto" style={{maxWidth:500}}>
                        <div className="form-group">
                            <label>Old Password</label>
                            <input required type="password" autoComplete="old-password" className="form-control" id="old_password" value={changePassword.old_password} onChange={handleChangePassword}/>
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input required type="password" autoComplete="new-password" className="form-control" id="new_password" value={changePassword.new_password} onChange={handleChangePassword}/>
                        </div>
                        <div className="form-group">
                            <label>Confirm Password</label>
                            <input required type="password" autoComplete="new-password" className={`form-control${passwordMismatch?" is-invalid":""}`} id="conpassword" value={changePassword.conpassword} onChange={handleChangePassword}/>
                            {!passwordMismatch || <div class="invalid-feedback">
                            Password Mismatch.
                            </div>}
                        </div>
                        <button className={'btn btn-success w-100 mt-3'}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </>
  )
}
