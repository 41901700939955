import React, {useEffect} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import * as documentActions from '../../redux/actions/documentActions';
import style from './index.module.scss'
import Layout from '../../components/Layout';

export default function Document(props) {
    const all_documents = useSelector(state => state.documents.menu);
    // const {name,documents} = useSelector(state => state.document);
     const dispatch = useDispatch();
     const {document} = props.match.params;
     let identity = []
     const findRec = (element,index)=>{
         //console.log(element.id==document);
        if(element.children.length>0){
            if (!element.children.find((element2,index2)=> findRec(element2,index2))){
                return false;
            }else{
                identity.push(index);
                return true;
            }
        }
        if(element.id==document){
            identity.push(index);
        }
        return element.id==document;
    }

    const identityRecur = (item,identity)=>{
        if(identity.length>1 && Array.isArray(item[identity[0]].children)){
            let nextIdentity = identity;
            let nextItem = item[identity[0]].children;
            nextIdentity.shift();
            return identityRecur(nextItem,nextIdentity)
        }
        return item[identity[0]];
    }
     all_documents.find((element,index) => findRec(element,index));
     const viewDocument = identityRecur(all_documents,identity.reverse());
     //console.log(identity);
     //console.log(viewDocument);

     //console.log(document)
    // useEffect(() => {
    //     dispatch(documentActions.getDocumentDetails(document));
    //     return () => {
    //         dispatch(documentActions.getDocumentDetails(document));
    //     };
    // },[all_documents]);

  return (

    <Layout>
        <BreadCrumbSection
            content={`Document Management / Documents / ${viewDocument && viewDocument.name}`}
        />
        <div className="container p-3">
            <div className="p-3 bg-white">
                {viewDocument && viewDocument.documents.map(item=>(
                    <div className="col-md-12">
                        <b>{item.title}:</b><a className="card-body" href={`http://docs.google.com/gview?url=${item.url}&embedded=true`} className="btn text-success" target="_blank">View document</a>
                    </div>
                ))}
            </div>
        </div>
    </Layout>
  )
}
