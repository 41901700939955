import * as types from '../actions/actionTypes';

const documents = {
    budget: [],
    general: [],
    menu: [],
    isLoading: false,
    error: [],
};
export default function reducer(state = documents, action) {
    switch (action.type) {
        case types.GET_ALL_DOCUMENTS_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_DOCUMENTS_SUCCESS:
            return {
                ...state,
                budget: action.payload.budget,
                general: action.payload.general,
                menu: action.payload.menu,
                isAdding:false
            }
        case types.CREATE_DOCUMENT_START:
            return {
                ...state,
                isAdding: true,
            };
        case types.CREATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                budget: action.payload.budget,
                general: [action.payload.general],
                menu: [action.payload.menu],
                isAdding:false
            }
        case types.CREATE_DOCUMENT_FAILED:
            return {
                ...state,
                isLoading:false
            }
        default:
            return state;
    }
}