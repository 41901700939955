import React, {useEffect} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import * as budgetActions from '../../redux/actions/budgetActions';
import Layout from '../../components/Layout';
import moment from 'moment';
import { Converter } from '../../components/helper';

export default function HistoricalBudget() {
    const {all_projects} = useSelector(state => state.budget);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(budgetActions.getAllProjects());
        return () => {
            dispatch(budgetActions.getAllProjects());
        };
    },[]);

  return (

    <Layout>
        <BreadCrumbSection
            content="Funding and Budget / Historical Budget"
        />
        <div className="container p-3">
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Project</th>
                            <th>Communities</th>
                            <th>Percentage</th>
                            <th>Year</th>
                            <th>Budget</th>
                            <th>Spent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {all_projects.map(item=> {return {...item, budget: parseFloat(item.budget), spent: parseFloat(item.spent)}}).map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.name}</td>
                                <td>{item.communities && item.communities.map(community=> community.name + ', ')}</td>
                                <td>
                                    {
                                    item.spent/item.budget > 1 ?
                                        <div className="badge badge-danger"> {(item.spent*100/item.budget).toFixed(2)}% </div> :
                                        item.spent/item.budget > 0.4 ?
                                            <div className="badge badge-warning"> {(item.spent*100/item.budget).toFixed(2)}%</div> :
                                            <div className="badge badge-success"> {(item.spent*100/item.budget).toFixed(2)}% </div>
                                    }
                                    </td>
                                <td>{moment(item.start_on,'YYYY-MM-DD').format('YYYY')}</td>
                                <td><Converter value={item.budget}/></td>
                                <td><Converter value={item.spent}/></td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
        </div>
    </Layout>
  )
}
