import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';
import * as tokenConfig from '../../utils/tokenConfig';
import * as message from './messageActions';

const loadAuthUserStart = () => ({
    type: types.LOAD_AUTH_USER_START
})

const loadAuthUserSuccess = () => ({
    type: types.LOAD_AUTH_USER_SUCCESS
})

export const loadAuthUser = () => (dispatch, getState) => {
    dispatch(loadAuthUserStart())
    const token = getState().auth.token;
    if (token) {
        dispatch(loadAuthUserSuccess())
    } else {
        dispatch(loginFailed())
    }
}

const loginStart = () => ({
    type: types.LOGIN_START
})

const loginSuccess = (token, user, modules) => ({
    type: types.LOGIN_SUCCESS,
    token,
    user,
    modules
})

const loginFailed = (error) => ({
    type: types.LOGIN_FAILED,
    error
})

const logoutSuccess = () => ({
    type: types.LOGOUT_SUCCESS
})

export const login = formData => (dispatch) => {
    dispatch(loginStart());
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
    }
    const endPoint = endpoints.LOGIN_URL
    axios
        .post(endPoint, formData, config)
        .then(res => {
            const token = res.data.auth.access_token
            const user = res.data.data
            const modules = res.data.modules
            const expires_at = res.data.auth.expires_at
            tokenConfig.finishAuthentication(token, user, expires_at)
            dispatch(loginSuccess(token, user,modules))
            dispatch(message.messageSuccess({message:"Logged in"}))
        })
        .catch(err => {
            const error = err.response.data.error;
            dispatch(loginFailed(error));
            dispatch(message.messageError({message:"Authentication error"}));
        });
}

export const logout = () => dispatch => {
    let token = tokenConfig.getToken();
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Basic dW5kZWZpbmVkOnVuZGVmaW5lZA=="
        }
    }
    let data = {
        access_token: token
    }
    const endpoint = endpoints.LOGOUT_URL
    axios
        .post(endpoint, data, config)
        .then(res => {
            dispatch(logoutSuccess())
        }).catch(err => {
            dispatch(logoutSuccess())
        });
};
