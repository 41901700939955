export const GET_ALL_COMMUNITIES = '/communities/all'

export const GET_ALL_CURRENT_PROJECTS = '/projects/current/all'

export const GET_ALL_PROJECTS = '/projects/all'
export const PROJECTS = '/projects/'
export const CREATE_PROJECT = '/projects/create'
export const DELETE_PROJECT = '/projects/delete'
export const UPDATE_PROJECT = '/projects/update'

export const GET_ALL_ISSUES = '/issues/all'
export const CREATE_ISSUE = '/issues/create'
export const UPDATE_ISSUE = '/issues/update'
export const ISSUES = '/issues/'

export const GET_ALL_INTERVENTIONS = '/interventions/all'
export const CREATE_INTERVENTION = '/interventions/create'
export const UPDATE_INTERVENTION = '/interventions/update'

export const GET_ALL_SCHOLARSHIPS = '/scholarshipStats/all'
export const CREATE_SCHOLARSHIP = '/scholarshipStats/create'
export const UPDATE_SCHOLARSHIP = '/scholarshipStats/update'

export const GET_ALL_TEACHERS_STATS = '/teachersStats/all'
export const CREATE_TEACHERS_STAT = '/teachersStats/create'
export const UPDATE_TEACHERS_STAT = '/teachersStats/update'

export const GET_ALL_HEALTH_STATS = '/healthStats/all'
export const CREATE_HEALTH_STAT = '/healthStats/create'
export const UPDATE_HEALTH_STAT = '/healthStats/update'

export const GET_ALL_OEI_STATS = '/OEIStats/all'
export const CREATE_OEI_STAT = '/OEIStats/create'
export const UPDATE_OEI_STAT = '/OEIStats/update'

export const GET_ALL_GRIEVANCES = '/grievances/all'
export const CREATE_GRIEVANCE = '/grievances/create'
export const UPDATE_GRIEVANCE = '/grievances/update'

export const GET_ALL_DOCUMENTS = '/documents/structured'
export const CREATE_DOCUMENT = '/documents/create'
export const DOCUMENTS = '/documents'
export const DOCUMENT_CATEGORIES = '/documents/categories'
export const UPDATE_DOCUMENT_CATEGORY = '/documents/categories/update'

export const GET_ALL_STAKEHOLDERS = '/stakeholders/all'
export const STAKEHOLDERS = '/stakeholders/'
export const GET_STAKEHOLDER_DETAILS = '/stakeholders/details/'
export const CREATE_STAKEHOLDER = '/stakeholders/create'
export const UPDATE_STAKEHOLDER = '/stakeholders/update'

export const GET_ALL_SCHEDULES = '/stakeholders/schedules/all'
export const SCHEDULES = '/stakeholders/schedules/'
export const CREATE_SCHEDULE = '/stakeholders/schedules/create'

export const GET_ALL_ENTERPRISE_STATS = '/enterpriseStats/all'
export const CREATE_ENTERPRISE_STAT = '/enterpriseStats/create'
export const UPDATE_ENTERPRISE_STAT = '/enterpriseStats/update'

export const GET_ALL_LOCAL_CONTENT_STATS = '/localContentStats/all'
export const CREATE_LOCAL_CONTENT_STAT = '/localContentStats/create'
export const UPDATE_LOCAL_CONTENT_STAT = '/localContentStats/update'

export const GET_ALL_GMOU_STATS = '/gmouStats/all'
export const CREATE_GMOU_STAT = '/gmouStats/create'
export const UPDATE_GMOU_STAT = '/gmouStats/update'

export const GET_ALL_SOCIAL_INVESTMENT_STATS = '/socialInvestmentStats/all'
export const CREATE_SOCIAL_INVESTMENT_STAT = '/socialInvestmentStats/create'
export const UPDATE_SOCIAL_INVESTMENT_STAT = '/socialInvestmentStats/update'

export const GET_ALL_PHILANTHROPY_STATS = '/philanthropyStats/all'
export const CREATE_PHILANTHROPY_STAT = '/philanthropyStats/create'
export const UPDATE_PHILANTHROPY_STAT = '/philanthropyStats/update'

export const GET_ALL_IDP_STATS = '/idpStats/all'
export const CREATE_IDP_STAT = '/idpStats/create'
export const UPDATE_IDP_STAT = '/idpStats/update'

export const GET_ALL_HUMANITARIAN_STATS = '/humanitarianStats/all'
export const CREATE_HUMANITARIAN_STAT = '/humanitarianStats/create'
export const UPDATE_HUMANITARIAN_STAT = '/humanitarianStats/update'

export const GENERATE_REPORT = '/generate_report'

export const USERS_URL = '/users'

export const AUTH_URL = '/auth'
export const LOGIN_URL = '/auth/login'
export const LOGOUT_URL = '/auth/logout'

// export const DOMAIN_URL = 'http://localhost:8000/'
// export const DOMAIN_URL = 'https://api.firstepdcspme.com/'
export const DOMAIN_URL = 'https://api.dicl-ict.com/'
export const BASE_URL = DOMAIN_URL + 'api/v1/'

