import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Converter } from "../helper";

export default function BudgetCard({title,value,progress,against}) {
    const [budgetState,setBudgetState] = useState(0);

    const percentage = value*100/against;
    let status = "danger"
    if (percentage<=40) {
        status = "success"
    }
    else if (percentage > 40 && percentage <= 100){
        status = "warning"
    }
    else if (percentage >100 ){
        status = "danger"
    }
    useEffect(() => {
        let timer = 0;
        if(budgetState < value){
            timer = setTimeout(function(){
                setBudgetState(budgetState + value/20);
                // console.log(timer);
                clearInterval(1);
            },50)
        }
        return (()=>clearInterval(timer));
      });
    return (
        <div className="card mt-2">
            <div className="card-header">{title}</div>
            <div className="card-body" style={{fontSize:24, fontWeight:"bold"}}>
                <Converter value={budgetState}/>
            </div>
            {
                progress ? (
                    <div className="progress">
                        <div className={"progress-bar bg-"+status} role="progressbar" style={{width: `${value*100/against}%`}} aria-valuenow={value} aria-valuemin="0" aria-valuemax={against}>{ `${(value*100/against).toFixed(2)}%`}</div>
                    </div>
                ):""
            }
            
        </div>
    );
}
