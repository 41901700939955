import React, {useEffect, useState} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import * as gmouActions from '../../redux/actions/gmouActions';
import Layout from '../../components/Layout';
import 'c3/c3.css';

export default function GMOU({type}) {
    const {gmouStats} = useSelector(state => state.gmou);
    const communities = useSelector(state => state.communities);
    const [community, setCommunity] = useState(1);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(gmouActions.getAllGMOUStats());
        return () => {
        };
    },[]);

  return (

    <Layout>
        <BreadCrumbSection
            content={"Dashboard / GMOU"}
        />
        <div className="container p-3">
            <select className="form-control my-5" style={{maxWidth:200}} selected={community} onChange={(e)=>setCommunity(e.target.value)}>
                <option disabled>Select Community</option>
                    {communities.data ? communities.data.map(item=>
                        <option value={item.id}>{item.name}</option>
                    ):""}
                </select>
                <div>
                    
                </div>
            <h4>GMOU Records </h4>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Duration of Tenure (in years)</th>
                            <th>Document</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {gmouStats.length>0 && gmouStats.find(item=>item.id == community).gmou_stats.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td>{item.tenure_duration}</td>
                                <td><a target="_blank" href={item.document.url}>View Document</a></td>
                                
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
        </div>
    </Layout>
  )
}
