import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';
import {getToken} from '../../utils/tokenConfig';
import * as message from './messageActions';

const getStakeholderStart = () => ({
  type: types.GET_STAKEHOLDER_START
})
const getStakeholderSuccess = (data) => ({
  type: types.GET_STAKEHOLDER_SUCCESS,
  payload: data
})
const getStakeholderFailed = () => ({
  type: types.GET_STAKEHOLDER_FAILED
})
export const getStakeholderDetails = (slug) => dispatch => {
  dispatch(getStakeholderStart())
  // const token = tokenConfig.getToken()
  const config = {
    headers: {
     "Accept": "application/json, text/plain, */*",
    }
  }
  //console.log(slug)
  const endpoint = endpoints.GET_STAKEHOLDER_DETAILS + slug;
  axios 
    .get(endpoint, config)
    .then(res => {
      //console.log(res);
      dispatch(getStakeholderSuccess(res.data))
    }).catch(err => {
        dispatch(getStakeholderFailed())
    });
}

const getAllStakeholdersStart = () => ({
  type: types.GET_ALL_STAKEHOLDERS_START
})
const getAllStakeholdersSuccess = (data) => ({
  type: types.GET_ALL_STAKEHOLDERS_SUCCESS,
  payload: data
})
const getAllStakeholdersFailed = () => ({
  type: types.GET_ALL_STAKEHOLDERS_FAILED
})
export const getAllStakeholders = () => dispatch => {
  dispatch(getAllStakeholdersStart())
  // const token = tokenConfig.getToken()
  const config = {
    headers: {
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.GET_ALL_STAKEHOLDERS
  axios 
    .get(endpoint, config)
    .then(res => {
      //console.log(res.data)
      dispatch(getAllStakeholdersSuccess(res.data))
    }).catch(err => {
        dispatch(getAllStakeholdersFailed())
    });
}
export const addStakeholdersDetail = (slug,data,broadcast=true) => dispatch => {
  broadcast && dispatch(message.messageStart());
  
  const formData = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
  }, new FormData());
  formData.append('access_token',getToken())
  const config = {
    headers: {
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.STAKEHOLDERS +slug+ '/add_details'
  axios 
    .post(endpoint, formData, config)
    .then(res => {
      broadcast && dispatch(getAllStakeholders())
      broadcast && dispatch(message.messageSuccess({message:"Stakeholder(s) added"}));
    }).catch(err => {
    });
}
export const trashStakeholder = (slug) => dispatch => {
  dispatch(message.messageStart());
  let data={slug};
  const formData = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
  }, new FormData());
  formData.append('access_token',getToken())
  const config = {
    headers: {
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.STAKEHOLDERS + 'delete'
  axios 
    .post(endpoint, formData, config)
    .then(res => {
      dispatch(message.messageSuccess({message:res.data.message}));
      dispatch(getAllStakeholders())
    }).catch(err => {
      //console.log(err);
    });
}
export const trashStakeholderDetails = (id) => dispatch => {
  let data={id};
  const formData = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
  }, new FormData());
  formData.append('access_token',getToken())
  const config = {
    headers: {
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.STAKEHOLDERS + 'details/delete'
  axios 
    .post(endpoint, formData, config)
    .then(res => {
      dispatch(getAllStakeholders())
      dispatch(message.messageSuccess({message:res.data.message}));
    }).catch(err => {
      //console.log(err);
    });
}

const createStakeholderStart = () => ({
  type: types.CREATE_STAKEHOLDER_START
})
const createStakeholderSuccess = (data) => ({
  type: types.CREATE_STAKEHOLDER_SUCCESS,
  payload: data
})
const createStakeholderFailed = () => ({
  type: types.CREATE_STAKEHOLDER_FAILED
})
export const createStakeholder = (data) => dispatch => {
    dispatch(createStakeholderStart())
    dispatch(message.messageStart());
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const fields = Object.entries(data.fields);
    delete data.fields;
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('access_token',getToken())
    //console.log(data);
    const endpoint = endpoints.CREATE_STAKEHOLDER
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        dispatch(createStakeholderSuccess(res.data.data))
        fields.forEach(([i,value],index)=>{
          let slug = res.data.data.slug;
          let data = value.type=="doc" ? {
            title:value.title,
            value: "Document",
            order: index,
            type: value.type,
            document_id :  value.value.id
          } : {
            title:value.title,
            value: value.value,
            order: index,
            type: value.type
          }
          dispatch(addStakeholdersDetail(slug,data,index == (fields.length - 1)))
        })
        if(fields.length==0){
          dispatch(getAllStakeholders())
          dispatch(message.messageSuccess({message:res.data.message}));
        }
      }).catch(err => {
          dispatch(createStakeholderFailed(err.response.data))
      });
  }
export const updateStakeholder = (payload) => dispatch => {
  dispatch(message.messageStart());
  const fields = Object.entries(payload.fields).filter(item=> !item[1].id);
  const config = {
    headers: {
      "Accept": "application/json, text/plain, */*",
    }
  }
  const formData = new FormData();
  formData.append('access_token',getToken())
  formData.append('name',payload.name)
  formData.append('id',payload.id)
  const endpoint = endpoints.UPDATE_STAKEHOLDER
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        //console.log(res)
          fields.forEach(([i,value],index)=>{
            let slug = payload.slug;
            let data = value.type=="doc" ? {
              title:value.title,
              value: "Document",
              type: value.type,
              order: index,
              document_id :  value.value.id
            } : {
              title:value.title,
              value: value.value,
              order: index,
              type: value.type
            }
            dispatch(addStakeholdersDetail(slug,data,index == (fields.length - 1)))
  })
    dispatch(getAllStakeholders())
    dispatch(message.messageSuccess({message:res.data.message}));
  }).catch(err => {

  });
}

const getAllSchedulesStart = () => ({
  type: types.GET_ALL_SCHEDULES_START
})
const getAllSchedulesSuccess = (data) => ({
  type: types.GET_ALL_SCHEDULES_SUCCESS,
  payload: data
})
const getAllSchedulesFailed = () => ({
  type: types.GET_ALL_SCHEDULES_FAILED
})
export const getAllSchedules = () => dispatch => {
  dispatch(getAllSchedulesStart())
  // const token = tokenConfig.getToken()
  const config = {
    headers: {
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.GET_ALL_SCHEDULES
  axios 
    .get(endpoint, config)
    .then(res => {
      //console.log(res.data)
      dispatch(getAllSchedulesSuccess(res.data))
    }).catch(err => {
        dispatch(getAllSchedulesFailed())
    });
}
const createScheduleStart = () => ({
  type: types.CREATE_SCHEDULE_START
})
const createScheduleSuccess = (data) => ({
  type: types.CREATE_SCHEDULE_SUCCESS,
  payload: data
})
const createScheduleFailed = () => ({
  type: types.CREATE_SCHEDULE_FAILED
})
export const createSchedule = (data) => dispatch => {
  dispatch(message.messageStart());
    dispatch(createScheduleStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('access_token',getToken())
    const endpoint = endpoints.CREATE_SCHEDULE
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        dispatch(createScheduleSuccess(res.data.data))
        dispatch(getAllSchedules())
        dispatch(message.messageSuccess({message:"Your meeting schedule has been fixed."}));
      }).catch(err => {
          dispatch(createScheduleFailed(err.response.data))
      });
  }