import * as types from './../actions/actionTypes';


const initialState = {
  data: [],
  isLoading: false,
  user: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false
      }
    }
    default:
     return state;
  }
}