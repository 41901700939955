import React, {useState,useEffect} from 'react'
import BreadCrumbSection from '../../../components/BreadCrumbSection'
import DocumentCard from '../../../components/admin/DocumentCard'
import { useSelector, useDispatch} from "react-redux";
import * as documentActions from '../../../redux/actions/documentActions';
import Layout from '../../../components/admin/Layout';
import Upload from '../../../components/admin/Upload';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export default function Documents() {
    const documents = useSelector(state => state.documents.menu);
    const [view, setView] = useState(false);
    const [create, setCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    const [expanded, setExpanded] = useState(null);
    const [createData, setCreateData] = useState({
        name: "",
        parent: 0,
        fields: {}
    })
    const [editData, setEditData] = useState({
        id: 0,
        fields: {}
    })
    const identityRecur = (item,identity)=>{
        //console.log(item)
        if(identity.length>1 && Array.isArray(item[identity[0]].children)){
            let nextIdentity = identity;
            let nextItem = item[identity[0]].children;
            nextIdentity.shift();
            return identityRecur(nextItem,nextIdentity)
        }
        return item[identity[0]];
    }
    const createField = (key,type="text")=>{
        setCreateData({...createData,fields:{...createData.fields,[key]:{title:"",value:null,type,status:0}}})
    }
    const addField = (key,type="text")=>{
        setEditData({...editData,fields:{...editData.fields,[key]:{title:"",value:null,type,status:0}}})
    }
    const add = (parent)=>{
        setCreate(true)
        setCreateData({...createData,parent});
    }
    const dispatch = useDispatch();
    // const error = useSelector(state=>state.auth.error[0]);
  
    const trash = (id)=>{
        dispatch(documentActions.trashDocumentCategory(id));
    }
    const trashDocument = (id)=>{
        // console.log(id);
        dispatch(documentActions.trashDocument(id));
    }
    const handleClose = () => setView(false);

    const handleCreateData = (e)=>{
        let field = e.target.id;
        setCreateData({...createData, [field] :e.target.value});
    }
    const handleCreate = (e) => {
        e.preventDefault()
        let data = createData;
        if(data.parent==0){
            delete data.parent
        }
        dispatch(documentActions.createDocumentCategory(data));
        setCreate(false);
        setCreateData({
            name: "",
            parent: 0,
            fields: {}
        })
    }

    const handleEdit = (e) => {
        e.preventDefault()
        let data = Object.assign({},editData);
        //console.log(data);
        dispatch(documentActions.updateDocumentCategory(data));
        setEdit(false);
    }

    const showDetails = (identity)=>{
        setView(true)
        setExpanded(identityRecur(documents,identity))
    }
    useEffect(() => {
        dispatch(documentActions.getAllDocuments());
        dispatch(documentActions.getAllDocuments());
    },[]);

    const viewBuilder = (documents,identity=[])=>{
        return documents.map((item,index)=>(
            <DocumentCard key={index} identity={[...identity,index]} showDetails={showDetails} name={`${item.name}`} id={item.id} trashCallback={()=>trash(item.id)} add={add} info={true}>
                {item.children.length>0 ? viewBuilder(item.children,[...identity,index]) : ""}
            </DocumentCard>
        ));
    }

  return (

    <Layout>
        <BreadCrumbSection
            content="Here you can manage all documents"
        />
        <div className="container p-3">
            <div className="row">
                <div className="col-md-12">
                    <div className="card mt-2">
                        <div className="card-header d-flex justify-content-between">
                            <span className="my-auto" style={{height:"min-content"}}>All Documents</span>
                        </div>
                        <div className="card-body row" style={{maxWidth:700}}>
                            <DocumentCard identity={[]} showDetails={()=>{}} info={false} id={0} trash={false} add={add} name="Documents">
                                {
                                    viewBuilder(documents)
                                }
                            </DocumentCard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <Modal show={create} onHide={()=>setCreate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add a new Document Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleCreate}>
                <div className="form-group">
                    <label>Category Name</label>
                    <input required type="text" className="form-control" id="name" value={createData.name} onChange={handleCreateData}/>
                </div>

                {Object.values(createData.fields).map((item,index)=> 
                    <div className={`form-group d-flex ${(createData.fields[index].title.length > 0 && createData.fields[index].status == 0)? "flex-column":""} mt-3`}>
                        <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={createData.fields[index].title} onChange={(e)=>{
                            setCreateData(
                                {...createData, 
                                fields :{...createData.fields,
                                    [index]:{...createData.fields[index],title:e.target.value}
                                    }
                                })
                            }
                        }/>
                        {createData.fields[index].status == 1 ? <a  style={{width:"50%"}} className="btn btn-secondary" href= {`${createData.fields[index].value.url}`} target="_blank">Document</a> : ""}
                        {createData.fields[index].title.length > 0 && createData.fields[index].status == 0 ? <Upload onUploaded={data=>setCreateData(
                                {...createData, 
                                fields :{...createData.fields,
                                    [index]:{...createData.fields[index],value:{url:data.data.url, id:data.data.id},status:1}
                                    }
                                })
                            } type="menu" title={createData.fields[index].title}/>:""}
                    </div>
                )}

                <button onClick={()=>createField(Object.keys(createData.fields).length,"doc")} className="btn mr-md-3 mr-sm-1" style={{position: "relative", width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                    <i className="fa fa-plus"></i>
                </button>
                <button className={'btn btn-success w-100 mt-3'}>Add</button>
            </form>
        </Modal.Body>
      </Modal>
      <Modal show={view} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Document Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {expanded && (
                <React.Fragment>
                    <div className="mt-3">
                        <b>Category Name: </b>
                        <span>{expanded.name}</span>
                    </div>
                    {
                        expanded.documents.map(item=>
                                <div className="d-flex mt-3">
                                    <b className="mr-3">{item.title}: </b><br/>
                                    <a href={`${item.url}`} className="flex-fill btn btn-secondary" target="_blank">View document</a>
                                    <br/>
                                </div>
                            )
                    }
                </React.Fragment>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{handleClose();setEdit(true); setEditData({...expanded, fields :expanded.documents});}}>
            Edit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={edit} onHide={()=>setEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Document Category</Modal.Title>
        </Modal.Header>
            {expanded && (
        <Modal.Body>
            <form onSubmit={handleEdit}>
                <div className="form-group">
                    <b>Category Name: </b>
                    <input required type="text" className="form-control" id="name" value={editData.name || expanded.name} onChange={ (e)=>
                        setEditData({
                            ...editData, 
                            name: e.target.value
                        })}/>
                </div>
                    {
                        expanded.documents.map(item=>
                                <div className="d-flex mt-3">
                                    <b className="mr-3">{item.title}: </b><br/>
                                    <a href={`${item.url}`} className="flex-fill btn btn-secondary" target="_blank">View document</a>
                                    <button type="button" onClick={()=>{setExpanded({...expanded, documents: expanded.documents.filter(d=> d.id != item.id)}); trashDocument(item.id)}} className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", backgroundColor: "transparent", color:"red"}}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                    <br/>
                                </div>
                            )
                    }
                    <br/>

                {Object.values(editData.fields).filter(field => !(field.id && true)).map((item,index)=> 
                    <div className={`form-group d-flex ${(editData.fields[index].title.length > 0 && editData.fields[index + editData.documents.length].status == 0)? "flex-column":""} mt-3`}>
                        <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={editData.fields[index + editData.documents.length].title} onChange={(e)=>{
                            setEditData(
                                {...editData, 
                                fields :{...editData.fields,
                                    [index + editData.documents.length]:{...editData.fields[index + editData.documents.length],title:e.target.value}
                                    }
                                })
                            }
                        }/>
                        {editData.fields[index + editData.documents.length].status == 1 ? <a  style={{width:"50%"}} className="btn btn-secondary" href= {`${editData.fields[index + editData.documents.length].value.url}`} target="_blank">Document</a> : ""}
                        {editData.fields[index + editData.documents.length].title.length > 0 && editData.fields[index + editData.documents.length].status == 0 ? <Upload onUploaded={data=>setEditData(
                                {...editData, 
                                fields :{...editData.fields,
                                    [index + editData.documents.length]:{...editData.fields[index + editData.documents.length],value:{url:data.data.url, id:data.data.id},status:1}
                                    }
                                })
                            } type="menu" title={editData.fields[index + editData.documents.length].title}/>:""}
                    </div>
                )}
                <button onClick={()=>addField(Object.keys(editData.fields).length,"doc")} className="btn mr-md-3 mr-sm-1" style={{position: "relative", width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                    <i className="fa fa-plus"></i>
                </button>
                <button className={'btn btn-success w-100 mt-3'}>Save</button>
            </form>
        </Modal.Body>)}
      </Modal>
    </Layout>
  )
}
