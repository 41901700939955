import React, { useState } from 'react'
import style from './index.module.scss'

function FileProgress({progress}) {
    return (
    <div className={style._}>
        <div
        className={style.__progress}
        style={{ width: progress + '%' }}
        />
    </div>
    )
        
}

export default FileProgress