import * as types from '../actions/actionTypes';
import moment from 'moment';

const currency = {
    USD: 0,
    conversion: 387.50
};
export default function reducer(state = currency, action) {
    switch (action.type) {
        case types.TOGGLE_CURRENCY:
            return {
                ...state,
                USD: !state.USD
            };
        case types.GET_USD_SUCCESS:
            return {
                ...state,
                conversion: action.payload
            };
        case types.UPDATE_CURRENCY:
            return {
                ...state,
                conversion: action.payload.conversion
            };
        default:
            return state
    }
}