import * as types from '../actions/actionTypes';

const socialInvestment = {
    socialInvestmentStats: [],
    isLoading: false,
};
export default function reducer(state = socialInvestment, action) {
    switch (action.type) {
        case types.GET_ALL_SOCIAL_INVESTMENT_STATS_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_SOCIAL_INVESTMENT_STATS_SUCCESS:
            return {
                ...state,
                socialInvestmentStats: action.payload
            }
        case types.CREATE_SOCIAL_INVESTMENT_STAT_START:
            return {
                ...state,
                isAdding: true,
            };
        case types.CREATE_SOCIAL_INVESTMENT_STAT_SUCCESS:
            return {
                ...state,
                isAdding: false,
            }
        case types.CREATE_SOCIAL_INVESTMENT_STAT_FAILED:
            return {
                ...state,
                addingError: ([action.payload]),
                isAdding:false
            }
        default:
            return state;
    }
}