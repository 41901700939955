import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import styles from "./nav.module.scss";
import SubSubNavLink from './subSubNavLink';

const SubNavLink = ({data}) => {
    const [open,setOpen] = useState(0);
    return (
        
            <div>
                {data.external ? 
                    <a href={`${data.external}`} target="_blank">{data.label}</a>
                :
                <NavLink
                    className={styles.nav__sublink}
                    activeClassName={styles.active}
                    exact
                    to={data.path}
                    onClick={()=>setOpen(!open)}
                >
                    {data.label}
                    {data.submenu && data.submenu.length>0 ?
                        <div className={`${styles.nav__subsublinksContainer} ${!open || styles['nav-active']}`}>
                            {data.submenu.map((submenu) => (
                                <SubSubNavLink data={submenu}/>
                            ))}
                    </div>
                    : ""
                }
            </NavLink>
            }
                </div>
    );
};

export default SubNavLink;