import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';

import { getToken } from "../../utils/tokenConfig";
import * as message from './messageActions';

const getAllIDPStatsStart = () => ({
    type: types.GET_ALL_IDP_STATS_START
})

const getAllIDPStatsSuccess = (data) => ({
    type: types.GET_ALL_IDP_STATS_SUCCESS,
    payload: data
})

const getAllIDPStatsFailed = () => ({
    type: types.GET_ALL_IDP_STATS_FAILED
})

const createIDPStatStart = () => ({
  type: types.CREATE_IDP_STAT_START
})

const createIDPStatSuccess = (data) => ({
  type: types.CREATE_IDP_STAT_SUCCESS,
  payload: data
})

const createIDPStatFailed = () => ({
  type: types.CREATE_IDP_STAT_FAILED
})

export const getAllIDPStats = () => dispatch => {
    dispatch(getAllIDPStatsStart())
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_IDP_STATS
    axios 
      .get(endpoint, config)
      .then(res => {
        //console.log(res)
        dispatch(getAllIDPStatsSuccess(res.data))
      }).catch(err => {
          dispatch(getAllIDPStatsFailed())
      });
  }

export const createIDPStat = (data) => dispatch => {
  dispatch(message.messageStart());
    dispatch(createIDPStatStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('access_token',getToken())
    const endpoint = endpoints.CREATE_IDP_STAT
    //console.log(JSON.stringify(Object.fromEntries(formData)));
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        //console.log(res)
        dispatch(createIDPStatSuccess(res.data.data))
        dispatch(getAllIDPStats())
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        //console.log(err)
        dispatch(createIDPStatFailed(err.response.data))
      });
  }