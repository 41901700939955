import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';
import * as tokenConfig from '../../utils/tokenConfig';
import * as message from './messageActions';


const userStart = () => ({
  type: types.USER_START
})


const userFailed = () => ({
  type: types.USER_FAILED
})

const userSuccess = () => ({
  type:types.USER_SUCCESS
})

const getUserSuccess = (data) => ({
  type: types.GET_USER_SUCCESS,
  data
})

const getAllUserSuccess = (data) => ({
  type: types.GET_ALL_USERS_SUCCESS,
  data
})

export const getAllUsers = () => (dispatch, getState) => {
  dispatch(userStart())
  const token = tokenConfig.getToken()
  const config = {
    headers: {
     'X-Requested-With': 'XMLHttpRequest',
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.USERS_URL
  axios 
    .get(`${endpoint}/all?access_token=${token}`, config)
    .then(res => {
      dispatch(getAllUserSuccess(res.data))
    }).catch(err => {
      //console.log(err.response);
      dispatch(userFailed())
    });
}
const getAllActivitiesSuccess = (data) => ({
  type: types.GET_ALL_ACTIVITIES_SUCCESS,
  data
})
export const getAllActivities = () => (dispatch, getState) => {
  const token = tokenConfig.getToken()
  const config = {
    headers: {
     'X-Requested-With': 'XMLHttpRequest',
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.BASE_URL
  axios 
    .get(`${endpoint}activities?access_token=${token}`, config)
    .then(res => {
      dispatch(getAllActivitiesSuccess(res.data))
    }).catch(err => {
      // dispatch(userFailed())
    });
}

export const deleteActivity = (id) => dispatch => {
  dispatch(message.messageStart());
  const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = new FormData();
    formData.append('access_token',token)
    const endpoint = endpoints.BASE_URL
    axios 
      .post(`${endpoint}activities/${id}/delete`, formData, config)
      .then(res => {
        //console.log(res)
        dispatch(getAllActivities())
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        
      });
  }

export const getUser = () => dispatch => {
  dispatch(userStart())
  const token = tokenConfig.getToken()
  const user = tokenConfig.getUserdata()
  const id = user.id
  const config = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.USERS_URL
  axios
    .get(`${endpoint}/${id}?access_token=${token}`, config)
    .then(res => {
      dispatch(getUserSuccess(res.data))
    }).catch(err => {
      dispatch(userFailed())
    });
}

export const editUser = (formData, id) => dispatch => {
  dispatch(message.messageStart());
   const config = {
     headers: {
       "Content-Type": "multipart/form-data"
     }
  }
  const endpoint = endpoints.USERS_URL
  axios 
  .post(`${endpoint}/${id}`, formData, config)
  .then((res, req) => {
    //console.log(res)
    dispatch(userSuccess(res.data))
    dispatch(message.messageSuccess({message:res.data.message}));
  }).catch(err => {
    //console.log(err.response);
    dispatch(userFailed())
  });
}

export const deleteUser = (id) => dispatch => {
  dispatch(message.messageStart());
    const token = tokenConfig.getToken()
    const config = {
     headers: {
       "Accept": "application/json, text/plain, */*",
     }
  }
  const formData = new FormData();
  formData.append("access_token", token)
  const endpoint = endpoints.USERS_URL
  axios 
    .post(`${endpoint}/${id}/delete`, formData, config)
    .then(res => {
      // dispatch(userSuccess(res.data))
      dispatch(message.messageSuccess({message:res.data.message}));
    }).catch(err => {
      //console.log(err.response);
      dispatch(userFailed())
    });
}
export const updateUser = (id,fields) => dispatch => {
  dispatch(message.messageStart());
    const token = tokenConfig.getToken()
    const config = {
     headers: {
       "Accept": "application/json, text/plain, */*",
     }
  }
  //console.log(fields);
  const formData = new FormData();
  formData.append("access_token", token)
  Object.entries(fields).forEach(element => {
    formData.append(element[0], element[1]);
  });
  
  const endpoint = endpoints.USERS_URL
  axios 
    .post(`${endpoint}/${id}/update`, formData, config)
    .then(res => {
      dispatch(getAllUsers());
      dispatch(message.messageSuccess({message:res.data.message}));
    }).catch(err => {
      //console.log(err.response);
      // dispatch(userFailed())
    });
}
export const blockUser = (id) => dispatch => {
  dispatch(message.messageStart());
    const token = tokenConfig.getToken()
    const config = {
     headers: {
       "Accept": "application/json, text/plain, */*",
     }
  }
  const formData = new FormData();
  formData.append("access_token", token)
  const endpoint = endpoints.USERS_URL
  axios 
    .post(`${endpoint}/${id}/block`, formData, config)
    .then(res => {
      dispatch(getAllUsers());
      dispatch(message.messageSuccess({message:res.data.message}));
    }).catch(err => {
      //console.log(err.response);
      // dispatch(userFailed())
    });
}
export const unblockUser = (id) => dispatch => {
  dispatch(message.messageStart());
    const token = tokenConfig.getToken()
    const config = {
     headers: {
       "Accept": "application/json, text/plain, */*",
     }
  }
  const formData = new FormData();
  formData.append("access_token", token)
  const endpoint = endpoints.USERS_URL
  axios 
    .post(`${endpoint}/${id}/unblock`, formData, config)
    .then(res => {
      dispatch(getAllUsers());
      dispatch(message.messageSuccess({message:res.data.message}));
    }).catch(err => {
      //console.log(err.response);
      // dispatch(userFailed())
    });
}
export const changePassword = fields => dispatch => {
    dispatch(message.messageStart());
      const token = tokenConfig.getToken()
      const config = {
       headers: {
         "Accept": "application/json, text/plain, */*",
       }
    }
    const formData = new FormData();
    formData.append("access_token", token)
    Object.entries(fields).forEach(element => {
      formData.append(element[0], element[1]);
    });
    
    const endpoint = endpoints.AUTH_URL
    axios 
      .post(`${endpoint}/password/update`, formData, config)
      .then(res => {
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        // console.log(Object.keys(err.response.data.error)[0]);
        // dispatch(message.messageSuccess({message:"Error"}))
        dispatch(message.messageSuccess({message:err.response.data?.error[Object.keys(err.response.data.error)[0]][0]}));
        // dispatch(userFailed())
      });
}
const addUserStart = () => ({
  type: types.ADD_USER_START
})

const addUserFail = () => ({
  type: types.ADD_USER_FAILED
})

const addUserSuccess = () => ({
  type: types.ADD_USER_SUCCESS
})

export const addUser = formData => dispatch => {
  dispatch(message.messageStart());
 dispatch(addUserStart())
  const config = {
    headers: {
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.AUTH_URL
   axios 
    .post(`${endpoint}/register`, formData, config)
    .then(res => {
      dispatch(getAllUsers());
      dispatch(addUserSuccess())
      dispatch(message.messageSuccess({message:res.data.message}));
    }).catch(err => {
      dispatch(addUserFail())
  });
}
