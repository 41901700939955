import React, {useState,useEffect} from 'react'
import BreadCrumbSection from '../../../components/BreadCrumbSection'
import ProjectCard from '../../../components/admin/ProjectCard'
import { useSelector, useDispatch} from "react-redux";
import * as budgetActions from '../../../redux/actions/budgetActions';
import * as documentActions from '../../../redux/actions/documentActions';
import Layout from '../../../components/admin/Layout';
import Upload from '../../../components/admin/Upload';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from "react-multi-select-component";
import { Converter } from '../../../components/helper';

export default function Projects() {
    const {all_projects} = useSelector(state => state.budget);
    const communities = useSelector(state => state.communities);
    const budget_document_array = useSelector(state => state.documents.budget);
    const budget_document = budget_document_array.length > 0 ? budget_document_array[0].url : null;
    const [view, setView] = useState(false);
    const [create, setCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    const [expanded, setExpanded] = useState(null);
    const [createData, setCreateData] = useState({
        "name":"",
        "community_id":0,
        "budget":0,
        "spent":0,
        "start_on":moment().format("YYYY/MM/DD"),
        "end_on":null,
        "description":"",
        "ended":false,
        fields: {}
    })
    const [editData, setEditData] = useState({
        "id":0,
        "name":"",
        "community_id":null,
        "budget":0,
        "spent":0,
        // "start_on":moment().format("YYYY/MM/DD"),
        "end_on":null,
        "description":"",
        "ended":false,
        fields: {}
    })
    const dispatch = useDispatch();
    // const error = useSelector(state=>state.auth.error[0]);
  
    const handleClose = () => setView(false);

    const handleCreateData = (e)=>{
        let field = e.target.id;
        setCreateData({...createData, [field] :e.target.value});
    }
    const handleEditData = (e)=>{
        let field = e.target.id;
        setEditData({...editData, [field] :e.target.value});
    }
    const trashDetail = (id)=>{
        dispatch(budgetActions.trashDetails(id));
    }
    const handleCreate = (e) => {
        e.preventDefault()
        let data = Object.assign({},createData);
        delete data.ended;
        createData.end_on || delete data.end_on;
        let comm = data.communities.map(item=>item.value);
        let formdata = {...data,communities: comm};
        dispatch(budgetActions.createProject(formdata));
        setCreateData({
            "name":"",
            "communities":[],
            "budget":0,
            "spent":0,
            "start_on":moment().format("YYYY/MM/DD"),
            "end_on":null,
            "description":"",
            "ended":false,
            fields: {},
        });
        setCreate(false);
    }
    const handleEdit = (e) => {
        e.preventDefault()
        let data = Object.assign({},editData);
        delete data.ended;
        delete data.details;
        editData.end_on || delete data.end_on
        let formdata = {...data};
        if(data.communities){
            let comm = data.communities.map(item=>item.value);
            formdata = {...data,communities: comm}
        }
        dispatch(budgetActions.updateProject(formdata));
        setEditData({
            "name":"",
            "budget":0,
            "spent":0,
            "start_on":moment().format("YYYY/MM/DD"),
            "end_on":null,
            "description":"",
            "ended":false,
            details:{},
            fields:{}
        });
        setEdit(false);
    }

    const createField = (key,type="text")=>{
        setCreateData({...createData,fields:{...createData.fields,[key]:{title:"",value:null,type,status:0}}})
    }
    const addField = (key,type="text")=>{
        setEditData({...editData,fields:{...editData.fields,[key]:{title:"",value:null,type,status:0}}})
    }

    const showDetails = (index)=>{
        setView(true)
        setExpanded(all_projects[index])
        //console.log(all_projects[index])
    }
    useEffect(() => {
        dispatch(budgetActions.getAllProjects());
        dispatch(documentActions.getAllDocuments());
        // return () => {
        //     dispatch(budgetActions.getAllCurrentProjects());
        // };
    },[]);

  return (

    <Layout>
        <BreadCrumbSection
            content="Here you can manage all projects"
        />
        <div className="container p-3">
            <div className="row">
                <div className="col-md-12">
                    <div className="card mt-2">
                        <div className="card-header d-flex justify-content-between">
                            Budget Approval Document ({moment().format('YYYY')})
                        </div>
                        <div className="card-body row d-flex justify-content-center">
                            {budget_document && 
                            <div className="w-100 d-flex justify-content-center mb-3">
                                <div className="">
                                    <a href={budget_document} target="_blank" className="btn btn-success">View Current Budget document</a>
                                </div>
                            </div>}
                            <form onSubmit={(e)=>e.preventDefault()} className="w-100" style={{maxWidth:500}}>
                                <div className="form-group">
                                    <label>{budget_document ? "Upload a new": "Upload"} Budget Document</label>
                                    <Upload/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="card mt-2">
                        <div className="card-header d-flex justify-content-between">
                            <span className="my-auto" style={{height:"min-content"}}>All Projects</span>
                            <button onClick={()=>setCreate(true)} className="btn btn-success"><i className="fa fa-plus"></i> Add a new Project</button>
                        </div>
                        <div className="card-body row">
                            {
                                all_projects.map((item,index)=>(
                                    <ProjectCard key={index} identity={index} showDetails={showDetails} name={`${item.name}`} spent={item.spent} budget={item.budget}/>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <Modal show={create} onHide={()=>setCreate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add a new Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleCreate}>
                {/* {error ? <div className="alert alert-danger mb-3">{error}</div> : ""} */}
                <div className="form-group">
                    <label>Project Name</label>
                    <input required type="text" className="form-control" id="name" value={createData.name} onChange={handleCreateData}/>
                </div>
                <div className="form-group">
                    <label>Community</label>
                    <MultiSelect
                        options={communities.data.map(item=> {
                                return {value: item.id, label: item.name}
                            }
                        )}
                        value={createData.communities}
                        onChange={(data) => setCreateData({...createData, communities :data})}
                        labelledBy={"Select"}
                    />
                </div>
                {/* <div className="form-group">
                    <label>Community</label>
                    <select required className="form-control" id="community_id" selected={createData.community_id} onChange={handleCreateData}>
                        <option value="">Select Community</option>
                        {communities.data ? communities.data.map(item=>
                            <option value={item.id}>{item.name}</option>
                        ):""}
                    </select>
                </div> */}
                <div className="form-group">
                    <label>Budget</label>
                    <input required type="number" step="0.01" className="form-control" id="budget" value={createData.budget} onChange={handleCreateData}/>
                </div>
                <div className="form-group">
                    <label>Spent</label>
                    <input type="number" step="0.01" className="form-control" id="spent" value={createData.spent} onChange={handleCreateData}/>
                </div>
                <div className="form-group">
                    <label>Commencement Date</label><br/>
                    
                    <DatePicker id="start_on"  className="form-control"
                        selected={moment(createData.start_on, 'YYYY/MM/DD').toDate()}
                        onChange={(e)=>setCreateData({...createData, 'start_on' :moment(e).format('YYYY/MM/DD')})}
                    />
                </div>
                <div className="form-group">
                    <input className="mr-2" id="ended" type="checkbox" checked={createData.ended} onChange={(e)=>{ setCreateData({...createData,'ended':e.target.checked, 'end_on' : createData.ended ? null : moment().format('YYYY/MM/DD')}); }} />
                    <label> This project has been completed</label>
                </div>
                {
                    createData.ended ? 
                    (
                        <div className="form-group">
                            <label>Completion Date</label><br/>
                            
                            <DatePicker id="start_on"  className="form-control"
                                selected={moment(createData.end_on, 'YYYY/MM/DD').toDate()}
                                onChange={(e)=>setCreateData({...createData, 'end_on' :moment(e).format('YYYY/MM/DD')})}
                            />
                        </div>)
                    : ""
                }
                <div className="form-group">
                    <label>Description</label>
                    <textarea rows={5} className="form-control" id="description" value={createData.description} onChange={handleCreateData}></textarea>
                </div>  

                {Object.values(createData.fields).map((item,index)=> {
                        switch(item.type){
                            case "text":
                                return <div className="form-group d-flex">
                                    <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={createData.fields[index].title} onChange={(e)=>{
                                        setCreateData(
                                            {...createData, 
                                            fields :{...createData.fields,
                                                [index]:{...createData.fields[index],title:e.target.value}
                                                }
                                            })
                                        }
                                    }/>
                                    <input required style={{width:"50%"}} type="text" placeholder="Value" className="form-control" value={createData.fields[index].value} onChange={(e)=>{
                                        setCreateData(
                                            {...createData, 
                                            fields :{...createData.fields,
                                                [index]:{...createData.fields[index],value:e.target.value}
                                                }
                                            })
                                        }
                                    }/>
                                </div> ;
                            case "link":
                                return <div className="form-group d-flex">
                                    <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={createData.fields[index].title} onChange={(e)=>{
                                        setCreateData(
                                            {...createData, 
                                            fields :{...createData.fields,
                                                [index]:{...createData.fields[index],title:e.target.value}
                                                }
                                            })
                                        }
                                    }/>
                                    <input required style={{width:"50%"}} type="text" placeholder="Url" className="form-control" value={createData.fields[index].value} onChange={(e)=>{
                                        setCreateData(
                                            {...createData, 
                                            fields :{...createData.fields,
                                                [index]:{...createData.fields[index],value:e.target.value}
                                                }
                                            })
                                        }
                                    }/>
                                </div> ;
                            case "doc":
                                return <div className={`form-group d-flex ${(createData.fields[index].title.length > 0 && createData.fields[index].status == 0)? "flex-column":""} mt-3`}>
                                    <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={createData.fields[index].title} onChange={(e)=>{
                                        setCreateData(
                                            {...createData, 
                                            fields :{...createData.fields,
                                                [index]:{...createData.fields[index],title:e.target.value}
                                                }
                                            })
                                        }
                                    }/>
                                    {createData.fields[index].status == 1 ? <a  style={{width:"50%"}} className="btn btn-secondary" href= {`${createData.fields[index].value.url}`} target="_blank">Document</a> : ""}
                                    {createData.fields[index].title.length > 0 && createData.fields[index].status == 0 ? <Upload onUploaded={data=>setCreateData(
                                            {...createData, 
                                            fields :{...createData.fields,
                                                [index]:{...createData.fields[index],value:{url:data.data.url, id:data.data.id},status:1}
                                                }
                                            })
                                        } type="general" title={createData.fields[index].title}/>:""}
                                </div> ;
                            default:
                                return "";
                        }
                        }
                )}
                <button onClick={()=>createField(Object.keys(createData.fields).length)} className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                    <i className="fa fa-plus"></i>
                </button>
                <button onClick={()=>createField(Object.keys(createData.fields).length,"link")} className="btn mr-md-3 mr-sm-1" style={{position: "relative", width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                    <i className="fa fa-link">
                        <i style={{fontWeight:"normal",fontSize:"10px",position:"absolute", top:10, right:0,}} className="fa fa-plus"></i>
                    </i>
                </button>
                <button onClick={()=>createField(Object.keys(createData.fields).length,"doc")} className="btn mr-md-3 mr-sm-1" style={{position: "relative", width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                    <i className="fa fa-file">
                        <i style={{fontWeight:"normal",fontSize:"10px",position:"absolute", top:10, right:0,}} className="fa fa-plus"></i>
                    </i>
                </button>
                <button className={'btn btn-success w-100 mt-3'}>Add</button>
            </form>
        </Modal.Body>
      </Modal>
      <Modal show={edit} onHide={()=>setEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Project</Modal.Title>
        </Modal.Header>
            {expanded && (
        <Modal.Body>
            <form onSubmit={handleEdit}>
                <div className="form-group">
                    <label>Project Name</label>
                    <input required type="text" className="form-control" id="name" value={editData.name || expanded.name} onChange={ (e)=>
                        setEditData({
                            ...editData, 
                            name: e.target.value
                        })}/>
                </div>
                <div className="form-group">
                    <label>Communities</label>
                    <MultiSelect
                        options={communities.data.map(item=> {
                                return {value: item.id, label: item.name}
                            }
                        )}
                        value={editData.communities || expanded.communities.map(item=>{return {value: item.id, label: item.name}})}
                        onChange={(data) => setEditData({...editData, communities :data})}
                        labelledBy={"Select"}
                    />
                    {/* <p>{expanded.communities.map(item=>
                        `${item.name}, `
                    )}</p> */}
                    
                </div>
                <div className="form-group">
                    <label>Budget</label>
                    <input required type="number" step="0.01" className="form-control" id="budget" value={editData.budget || expanded.budget} onChange={handleEditData}/>
                </div>
                <div className="form-group">
                    <label>Spent</label>
                    <input type="number" step="0.01" className="form-control" id="spent" value={editData.spent || expanded.spent} onChange={handleEditData}/>
                </div>
                <div className="form-group">
                    <label>Commencement Date</label><br/>
                    <p>{expanded.start_on}</p>
                    
                    {/* <DatePicker id="start_on"  className="form-control"
                        selected={moment(editData.start_on, 'YYYY/MM/DD').toDate()}
                        onChange={(e)=>setEditData({...editData, 'start_on' :moment(e).format('YYYY/MM/DD')})}
                    /> */}
                </div>
                <div className="form-group">
                    <input className="mr-2" id="ended" type="checkbox" checked={editData.ended || expanded.end_on} onChange={(e)=>{ setEditData({...editData,'ended':e.target.checked, 'end_on' : editData.ended ? null : moment().format('YYYY/MM/DD')}); }} />
                    <label> This project has been completed</label>
                </div>
                {
                    editData.ended || expanded.end_on ? 
                    (
                        <div className="form-group">
                            <label>Completion Date</label><br/>
                            
                            <DatePicker id="start_on"  className="form-control"
                                selected={moment(editData.end_on || expanded.end_on , 'YYYY/MM/DD').toDate()}
                                onChange={(e)=>setEditData({...editData, 'end_on' :moment(e).format('YYYY/MM/DD')})}
                            />
                        </div>)
                    : ""
                }
                <div className="form-group">
                    <label>Description</label>
                    <textarea rows={5} className="form-control" id="description" value={editData.description || expanded.description} onChange={handleEditData}></textarea>
                </div> 
                    {
                        expanded.details.map(item=>
                                <div className="mt-3 d-flex">
                                    <b className="mr-4">{item.title}: </b>
                                    {item.type == "text" ? <span className="flex-fill">{item.value}</span>:""}
                                    {item.type == "doc" ? <a href={`${item.document && item.document.url}`} className="flex-fill btn btn-secondary" target="_blank">View document</a>:""}
                                    {item.type == "link" ? <a href={`${item.value}`} className="flex-fill btn btn-outline-success" target="_blank">Visit Link</a>:""}
                                    { item.type != "doc" ? <button type="button" className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", backgroundColor: "transparent", color:"green"}} onClick={()=>{
                                        setExpanded({...expanded,details: expanded.details.filter(detail=>detail!=item)}); 
                                        setEditData(
                                            {...editData, 
                                            fields :{...editData.fields,
                                                [editData.details.length]:{title: item.title,value:item.value,exists:true, type:item.type}
                                                }
                                            }
                                            )
                                        }
                                    }><i className="fa fa-pencil"></i></button> : ""}
                                    <button type="button" onClick={()=>{setExpanded({...expanded, details: expanded.details.filter(d=> d.id != item.id)}); trashDetail(item.id)}} className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", backgroundColor: "transparent", color:"red"}}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </div>
                            )
                    }
                    {Object.values(editData.fields).filter(field => !(field.id && true)).map((item,index)=> {
                            switch(item.type){
                                case "text":
                                    return <div className="form-group d-flex">
                                        {item.exists ?
                                            <b className="mr-4">{editData.fields[index + editData.details.length].title}: </b>
                                            :
                                            <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={editData.fields[index + editData.details.length].title} onChange={(e)=>{
                                                setEditData(
                                                    {...editData, 
                                                    fields :{...editData.fields,
                                                        [index + editData.details.length]:{...editData.fields[index + editData.details.length],title:e.target.value}
                                                        }
                                                    })
                                                }
                                            }/>
                                        }
                                        <input required style={{width:"50%"}} type="text" placeholder="Value" className="form-control" value={editData.fields[index + editData.details.length].value} onChange={(e)=>{
                                            setEditData(
                                                {...editData, 
                                                fields :{...editData.fields,
                                                    [index + editData.details.length]:{...editData.fields[index + editData.details.length],value:e.target.value}
                                                    }
                                                })
                                            }
                                        }/>
                                    </div> ;
                                case "link":
                                    return <div className="form-group d-flex">
                                    {item.exists ?
                                        <b className="mr-4">{editData.fields[index + editData.details.length].title}: </b>
                                        :
                                        <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={editData.fields[index + editData.details.length].title} onChange={(e)=>{
                                            setEditData(
                                                {...editData, 
                                                fields :{...editData.fields,
                                                    [index + editData.details.length]:{...editData.fields[index + editData.details.length],title:e.target.value}
                                                    }
                                                })
                                            }
                                        }/>
                                    }
                                        <input required style={{width:"50%"}} type="text" placeholder="Url" className="form-control" value={editData.fields[index + editData.details.length].value} onChange={(e)=>{
                                            setEditData(
                                                {...editData, 
                                                fields :{...editData.fields,
                                                    [index + editData.details.length]:{...editData.fields[index + editData.details.length],value:e.target.value}
                                                    }
                                                })
                                            }
                                        }/>
                                    </div> ;
                                case "doc":
                                    return <div className={`form-group d-flex ${(editData.fields[index + editData.details.length].title.length > 0 && editData.fields[index + editData.details.length].status == 0)? "flex-column":""} mt-3`}>
                                        <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={editData.fields[index + editData.details.length].title} onChange={(e)=>{
                                            setEditData(
                                                {...editData, 
                                                fields :{...editData.fields,
                                                    [index + editData.details.length]:{...editData.fields[index + editData.details.length],title:e.target.value}
                                                    }
                                                })
                                            }
                                        }/>
                                        {editData.fields[index + editData.details.length].status == 1 ? <a  style={{width:"50%"}} className="btn btn-secondary" href= {`${editData.fields[index + editData.details.length].value.url}`} target="_blank">Document</a> : ""}
                                        {editData.fields[index + editData.details.length].title.length > 0 && editData.fields[index + editData.details.length].status == 0 ? <Upload onUploaded={data=>setEditData(
                                                {...editData, 
                                                fields :{...editData.fields,
                                                    [index + editData.details.length]:{...editData.fields[index + editData.details.length],value:{url:data.data.url, id:data.data.id},status:1}
                                                    }
                                                })
                                            } type="general" title={editData.fields[index + editData.details.length].title}/>:""}
                                    </div> ;
                                default:
                                    return "";
                            }
                            }
                    )}

                    <button type="button" onClick={()=>addField(Object.keys(editData.fields).length)} className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                        <i className="fa fa-plus"></i>
                    </button>
                    <button type="button" onClick={()=>addField(Object.keys(editData.fields).length,"link")} className="btn mr-md-3 mr-sm-1" style={{position: "relative", width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                        <i className="fa fa-link">
                            <i style={{fontWeight:"normal",fontSize:"10px",position:"absolute", top:10, right:0,}} className="fa fa-plus"></i>
                        </i>
                    </button>
                    <button type="button" onClick={()=>addField(Object.keys(editData.fields).length,"doc")} className="btn mr-md-3 mr-sm-1" style={{position: "relative", width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                        <i className="fa fa-file">
                            <i style={{fontWeight:"normal",fontSize:"10px",position:"absolute", top:10, right:0,}} className="fa fa-plus"></i>
                        </i>
                    </button> 
                <button className={'btn btn-success w-100 mt-3'}>Save</button>
            </form>
        </Modal.Body>)}
      </Modal>
      <Modal show={view} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {expanded && (
                <React.Fragment>
                    <div className="mt-3">
                        <b>Project Name: </b>
                        <span>{expanded.name}</span>
                    </div>
                    <div className="mt-3">
                        <b>Communities: </b>
                        <p>{expanded.communities.map(item=>
                            `${item.name}, `
                        )}</p>
                    </div>
                    <div className="mt-3">
                        <b>Budget: </b>
                        <span><Converter value={expanded.budget}/></span>
                    </div>
                    <div className="mt-3">
                        <b>Spent: </b>
                        <span><Converter value={expanded.spent}/></span>
                    </div>
                    <div className="mt-3">
                        <b>Commencement Date: </b>
                        <span>{expanded.start_on}</span>
                    </div>
                    <div className="mt-3">
                        <b>Completed Date: </b>
                        <span>{expanded.end_on || (<span style={{color:"green"}}>Still in progress</span>)}</span>
                    </div>
                    <div className="mt-3">
                        <b>Description: </b>
                        <span>{expanded.description}</span>
                    </div>
                    {expanded.document && (
                    <div className="mt-3">
                        <b>Budget Document: </b>
                        <a className="btn btn-success">Open Document</a>
                    </div>)}
                    {
                        expanded.details.map(item=>
                                <div className="mt-3 d-flex">
                                    <b className="mr-4">{item.title}: </b>
                                    {item.type == "text" ? <span className="flex-fill">{item.value}</span>:""}
                                    {item.type == "doc" ? <a href={`${item.document && item.document.url}`} className="flex-fill btn btn-secondary" target="_blank">View document</a>:""}
                                    {item.type == "link" ? <a href={`${item.value}`} className="flex-fill btn btn-outline-success" target="_blank">Visit Link</a>:""}
                                </div>
                            )
                    }
                </React.Fragment>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={()=> {dispatch(budgetActions.deleteProject(expanded.id)); handleClose();}}>
            Delete
          </Button>
          <Button variant="secondary" onClick={()=>{handleClose();setEdit(true); setEditData({...createData, id :expanded.id,fields:expanded.details, details :expanded.details});}}>
            Edit
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}
