import React,{useEffect} from 'react';
import { useIdleTimer } from 'react-idle-timer'
import './App.css';
import Container from 'react-bootstrap/Container';
import CurrentYearBudget from './pages/CurrentYearBudget'
import HistoricalBudget from './pages/HistoricalBudget'
import Stakeholder from './pages/Stakeholder'
import Document from './pages/Document'
import Issues from './pages/Issues'
import Grievances from './pages/Grievances'
import Education from './pages/Education'
import Health from './pages/Health'
import Enterprise from './pages/Enterprise'
import LocalContent from './pages/LocalContent'
import GMOU from './pages/GMOU'
import SocialInvestments from './pages/SocialInvestments'
import Philanthropy from './pages/Philanthropy'
import IDP from './pages/IDP'
import Humanitarian from './pages/Humanitarian'
import Projects from './pages/admin/Projects'
import Stakeholders from './pages/admin/Stakeholders'
import Documents from './pages/admin/Documents'
import Users from './pages/admin/Users'
import Activities from './pages/admin/Activities'
import Reports from './pages/admin/Reports'
import UserReports from './pages/Reports'
import Welcome from './pages/Welcome'
import IssuesAndGrievances from './pages/admin/IssuesAndGrievances'
import * as tokenConfig from './utils/tokenConfig'
import * as authActions from './redux/actions/authActions'
import * as communityActions from './redux/actions/communityActions'
import * as currencyActions from './redux/actions/currencyActions'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as messageActions from './redux/actions/messageActions';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import SignIn from './pages/SignIn';
import { useDispatch, useSelector } from 'react-redux';
import Dasbhoard from './pages/admin/Dashboard';
import AdminSettings from './pages/admin/Settings';
import Settings from './pages/Settings';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {

  const dispatch = useDispatch()
  const {isAuth,user} = useSelector(state=>state.auth);
  const handleOnIdle = event => {
    isAuth && dispatch(authActions.logout())
  }
 
  const handleOnActive = event => {
  }
 
  const handleOnAction = (e) => {
  }
 
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })
  const message = useSelector(state=>state.message);
  const isCustom = user && user.type == 0;
  const isAdmin = user && user.type >= 3;
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(messageActions.closeMessage());;
  };
  useEffect(() => {
    if (tokenConfig.getToken() && tokenConfig.getUserdata()) {
      dispatch(authActions.loadAuthUser());
    } else {
      dispatch(authActions.logout());
    }
  }, [isAuth])
  useEffect(() => {
      dispatch(currencyActions.getUSD());
      dispatch(communityActions.getAllCommunities());
      //console.log("communities");
  }, [])
  if(!isAuth){
    return (
      <>
        <Router>
            <Switch>
              <Route path="/" component={SignIn} />
            </Switch>
        </Router>
  
      </>)
  }
  return (
    <div className="App">
        <>
          <Router>
              <Switch>
                <Route exact path="/dashboard/reports" component={UserReports}/>
                <Route exact path="/dashboard/humanitarian" component={Humanitarian}/>
                <Route exact path="/dashboard/IDP" component={IDP}/>
                <Route exact path="/dashboard/philanthropy" component={Philanthropy}/>
                <Route exact path="/dashboard/social-investments" component={SocialInvestments}/>
                <Route exact path="/dashboard/gmou" component={GMOU}/>
                <Route exact path="/dashboard/local-content" component={LocalContent}/>
                <Route exact path="/dashboard/enterprise" component={Enterprise}/>
                <Route exact path="/dashboard/health" component={Health}/>
                <Route exact path="/dashboard/education" component={Education}/>
                <Route exact path="/grievances" component={Grievances}/>
                <Route exact path="/issues/critical"><Issues type="critical"/></Route>
                <Route exact path="/issues/slowburn"><Issues type="slow_burn"/></Route>
                <Route exact path="/stakeholders/:stakeholder" component={Stakeholder} />
                <Route exact path="/documents/:document" component={Document} />
                <Route exact path="/historical" component={HistoricalBudget} />
                <Route exact path="/current-year-budget" component={CurrentYearBudget} />
                <Route exact path="/settings" component={Settings} />
                {
                  isCustom ? 
                  <Route exact path="/" component={Welcome} />
                  :
                  <Route exact path="/" component={Education} />
                }
              </Switch>
              {isAdmin?
              <Switch>
                <Route exact path="/admin/issues-grievances" component={IssuesAndGrievances} />
                <Route exact path="/admin/projects" component={Projects} />
                <Route exact path="/admin/stakeholders" component={Stakeholders} />
                <Route exact path="/admin/documents" component={Documents} />
                <Route exact path="/admin/dashboard" component={Dasbhoard} />
                <Route exact path="/admin/users" component={Users} />
                <Route exact path="/admin/activities" component={Activities} />
                <Route exact path="/admin/reports" component={Reports} />
                <Route exact path="/admin/settings" component={AdminSettings} />
              </Switch>
                :
                ""
                }
          </Router>

        </>
      {/* <Container>
      </Container> */}
      <Backdrop style={{
          zIndex: 100,
          color: '#fff',
        }} open={message.loading} onClick={()=>{}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={message.open} autoHideDuration={4000} onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={message.type}>
          {message.content}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
