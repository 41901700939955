import * as types from '../actions/actionTypes';

const humanitarian = {
    humanitarianStats: [],
    isLoading: false,
};
export default function reducer(state = humanitarian, action) {
    switch (action.type) {
        case types.GET_ALL_HUMANITARIAN_STATS_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_HUMANITARIAN_STATS_SUCCESS:
            return {
                ...state,
                humanitarianStats: action.payload
            }
        case types.CREATE_HUMANITARIAN_STAT_START:
            return {
                ...state,
                isAdding: true,
            };
        case types.CREATE_HUMANITARIAN_STAT_SUCCESS:
            return {
                ...state,
                isAdding: false,
            }
        case types.CREATE_HUMANITARIAN_STAT_FAILED:
            return {
                ...state,
                addingError: ([action.payload]),
                isAdding:false
            }
        default:
            return state;
    }
}