import React, {useEffect} from "react";
import styles from "./nav.module.scss";
import logo from '../../../assets/images/gos_logo.png'
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch} from "react-redux";
import * as menuActions from '../../../redux/actions/menuActions';

export default function Nav() {
    const user_type = useSelector(state => state.auth.user.type);
    
    const navItems = [
        {label:"Projects", path:"/admin/projects"},
        {label:"Stakeholders", path:"/admin/stakeholders"},
        {label:"Documents", path:"/admin/documents"},
        {label:"Issues and Grievances", path:"/admin/issues-grievances"},
        {label:"Dashboard", path:"/admin/dashboard"},
    ];

    if(user_type > 3){
        navItems.push({label:"Activities", path:"/admin/activities"})
        navItems.push({label:"Reports", path:"/admin/reports"})
    }
    if(user_type > 4){
        navItems.push({label:"Users", path:"/admin/users"})
    }
    navItems.push({label:<i className="fa fa-gear"></i>, path:"/admin/settings"})
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(menuActions.getAllStakeholders());
        return () => {
            dispatch(menuActions.getAllStakeholders());
        };
      },[]);

  return (
    <div className={styles.nav}>
        <div className={styles.nav__logoContainer}>
            <div className="container" style={{display: "flex", justifyContent:"space-between"}}>
                <NavLink
                    exact
                    to={"/"}
                >
                    <img src={logo} alt="Logo" className={styles.nav__logo} />
                </NavLink>
                <div className={styles.nav__linksContainer}>
                    {Object.keys(navItems).map((item) => (
                    <div className="ml-2">
                        <NavLink
                            className={styles.nav__link}
                            activeClassName={styles.active}
                            exact
                            to={`${navItems[item].path!=null ? navItems[item].path : "#"}`}
                        >
                            {navItems[item].label} {navItems[item].submenu && <i className="fa fa-angle-down"></i>}
                        </NavLink>
                            {navItems[item].submenu ?
                                <div className={styles.nav__sublinksContainer}>
                                    {navItems[item].submenu.map((submenu) => (
                                        <NavLink
                                            className={styles.nav__sublink}
                                            activeClassName={styles.active}
                                            exact
                                            to={submenu.path}
                                        >
                                            {submenu.label}
                                        </NavLink>
                                    ))}
                                </div>
                                
                                : ""
                            }
                    </div>
                    ))}
                </div>
            
            </div>
        </div>
    </div>
  );
}
