import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import styles from "./nav.module.scss";
import SubNavLink from './subNavLink';

const CustomNavLink = ({data}) => {
    const [open,setOpen] = useState(0);
    return (
        <div>
            {data.external ? 
                <a href={`${data.external}`} target="_blank">{data.label}</a>
                :
                <NavLink
                    className={styles.nav__link}
                    activeClassName={styles.active}
                    exact
                    to={`${data.path!==null ? data.path : "#"}`}
                    onClick={()=>setOpen(!open)}
                >
                    {data.label} {data.submenu && <i className="fa fa-angle-down"></i>}
                </NavLink>
            }
                {data.submenu ?
                    <div className={`${styles.nav__sublinksContainer} ${!open || styles['nav-active']}`}>
                        {data.submenu.map((submenu) => (
                            <SubNavLink data={submenu}/>
                        ))}
                    </div>
                    : ""
                }
        </div>
    );
};

export default CustomNavLink;