import React, {useEffect, useState} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import * as healthActions from '../../redux/actions/healthActions';
import Layout from '../../components/Layout';
import C3Chart from 'react-c3js';
import 'c3/c3.css';

export default function Issues({type}) {
    const {healthStats} = useSelector(state => state.health);
    const communities = useSelector(state => state.communities);
    const [community, setCommunity] = useState(1);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(healthActions.getAllHealthStats());
        return () => {
        };
    },[]);
    let chat_data = []

    chat_data = {
          x:"Years",
          columns: [
              ['Years'].concat(healthStats.length>0 ? healthStats.find(item=>item.id == community).health_stats.map(item=>item.year):[]),
              ['Mortality rate per year per community'].concat(healthStats.length>0 ? healthStats.find(item=>item.id == community).health_stats.map(item=>item.mortality):[]),
              ['Birth attendance by medical personel(proportion)'].concat(healthStats.length>0 ? healthStats.find(item=>item.id == community).health_stats.map(item=>item.proportion_of_attended_birth):[]),
              ['Adolescent Pregnancies'].concat(healthStats.length>0 ? healthStats.find(item=>item.id == community).health_stats.map(item=>item.adolescent_pregnancy):[]),
              ['Health Workers'].concat(healthStats.length>0 ? healthStats.find(item=>item.id == community).health_stats.map(item=>item.health_workers):[]),
              ['Health Insurance'].concat(healthStats.length>0 ? healthStats.find(item=>item.id == community).health_stats.map(item=>item.insurance_covered):[]),
          ],
          axes: {
            'Health Workers': 'y2',
            'Health Insurance': 'y2',
            'Birth attendance by medical personel(proportion)': 'y3'
          },
          type: 'bar',
          types: {
            'Mortality rate per year per community': 'line',
            'Birth attendance by medical personel(proportion)': 'line',
            'Adolescent Pregnancies': 'line'
          }
      }

  return (

    <Layout>
        <BreadCrumbSection
            content={"Dashboard / Health"}
        />
        <div className="container p-3">
            <select className="form-control my-5" style={{maxWidth:200}} selected={community} onChange={(e)=>setCommunity(e.target.value)}>
                <option disabled>Select Community</option>
                    {communities.data ? communities.data.map(item=>
                        <option value={item.id}>{item.name}</option>
                    ):""}
                </select>
                <div>
                    
                </div>
            <h4>Health Statistics </h4>
            <C3Chart data={chat_data} axis={{y2:{show:true},y3:{show:true}}}/>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Mortality rate per year per community</th>
                            <th>Birth attendance by medical personel(proportion)</th>
                            <th>Adolescent Pregnancies'</th>
                            <th>Health Workers</th>
                            <th>Number of Health Workers on LG Payroll </th>
                            <th>Health Insurance</th>
                            <th>Percent Health Workers on Government Payroll</th>
                            <th>Nos of Under 5's Attending Health Facility</th>
                            <th>Frequency of Under 5's Attendance at Health Facility</th>
                            <th>Mortality: Nos of Deaths of Under 5's/'000 of population</th>
                            <th>Morbidity: Nos of Reported Specific illnesses of Under 5's/'000 of population </th>
                            <th>Nos of Pregnant Women Attending Health Facility</th>
                            <th>Frequency of Pregnant Women Attendance at Health Facility</th>
                            <th>Mortality: Nos of Deaths of Pregnant Women/'000 of population</th>
                            <th>Morbidity: Nos of Reported Specific illnesses of Pregnant Women /'000 of population </th>
                            <th>Nos of Breastfeeding Women Attending Health Facility</th>
                            <th>Frequency of Breastfeeding Women Attending Health Facility</th>
                            <th>Mortality: Nos of Deaths of  Breastfeeding Women/'000 of population</th>
                            <th>Morbidity: Nos of Reported Specific illnesses of Breastfeeding Women/'000 of population</th>
                        </tr>
                    </thead>
                    <tbody>
                        {healthStats.length>0 && healthStats.find(item=>item.id == community).health_stats.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td>{item.mortality}</td>
                                <td>{item.proportion_of_attended_birth}</td>
                                <td>{item.adolescent_pregnancy}</td>
                                <td>{item.health_workers}</td>
                                <td>{item.fg_health_workers}</td>
                                <td>{item.insurance_covered}</td>
                                <td>{item.hospital_attendance}</td>
                                <td>{item.u5_hospital_attendance}</td>
                                <td>{item.u5_mortality}</td>
                                <td>{item.u5_morbidity}</td>
                                <td>{item.pregnant_hospital_attendance}</td>
                                <td>{item.pregnant_hospital_frequency}</td>
                                <td>{item.pregnant_mortality}</td>
                                <td>{item.pregnant_morbidity}</td>
                                <td>{item.breast_feeding_hospital_attendance}</td>
                                <td>{item.breast_feeding_hospital_frequency}</td>
                                <td>{item.breast_feeding_mortality}</td>
                                <td>{item.breast_feeding_morbidity}</td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
        </div>
    </Layout>
  )
}
