import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';

import { getToken } from "../../utils/tokenConfig";
import * as message from './messageActions';

const getAllEnterpriseStatsStart = () => ({
    type: types.GET_ALL_ENTERPRISE_STATS_START
})

const getAllEnterpriseStatsSuccess = (data) => ({
    type: types.GET_ALL_ENTERPRISE_STATS_SUCCESS,
    payload: data
})

const getAllEnterpriseStatsFailed = () => ({
    type: types.GET_ALL_ENTERPRISE_STATS_FAILED
})

const createEnterpriseStatStart = () => ({
  type: types.CREATE_ENTERPRISE_STAT_START
})

const createEnterpriseStatSuccess = (data) => ({
  type: types.CREATE_ENTERPRISE_STAT_SUCCESS,
  payload: data
})

const createEnterpriseStatFailed = () => ({
  type: types.CREATE_ENTERPRISE_STAT_FAILED
})

export const getAllEnterpriseStats = () => dispatch => {
    dispatch(getAllEnterpriseStatsStart())
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_ENTERPRISE_STATS
    axios 
      .get(endpoint, config)
      .then(res => {
        //console.log(res)
        dispatch(getAllEnterpriseStatsSuccess(res.data))
      }).catch(err => {
          dispatch(getAllEnterpriseStatsFailed())
      });
  }

export const createEnterpriseStat = (data) => dispatch => {
  dispatch(message.messageStart());
    dispatch(createEnterpriseStatStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('access_token',getToken())
    const endpoint = endpoints.CREATE_ENTERPRISE_STAT
    console.log(JSON.stringify(Object.fromEntries(formData)));
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        // console.log(res)
        dispatch(createEnterpriseStatSuccess(res.data.data))
        dispatch(getAllEnterpriseStats())
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        // console.log(err)
        dispatch(createEnterpriseStatFailed(err.response.data))
      });
  }