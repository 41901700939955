import React, {useState,useEffect} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import Layout from '../../components/Layout';
import * as userActions from '../../redux/actions/userActions'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import * as tokenConfig from '../../utils/tokenConfig';
import * as endpoints from  '../../utils/endpoints'
import axios from  '../../utils/axios-base';
import MultiSelect from "react-multi-select-component";
import * as message from '../../redux/actions/messageActions';

export default function Reports() {
    const communities = useSelector(state => state.communities);
    const report_types = [
                        {type:"projects",name:"Funding and Budget"},
                        {type:"slow_burn_issues",name:"Slow Burn Issues"},
                        {type:"critical_issues",name:"Critical Issues"},
                        {type:"grievances",name:"Grievances"},
                        {type:"interventions",name:"First E & P Interventions"},
                        {type:"scholarships",name:"Scholarships"},
                        {type:"teachers",name:"Teachers"},
                        {type:"water",name:"Water Projects"},
                        {type:"sanitary_facilities",name:"Sanitary Facility Projects"},
                        {type:"security",name:"Security"},
                        {type:"power",name:"Power"},
                        {type:"health",name:"Health"},
                        {type:"enterprise",name:"Enterprise"},
                        {type:"procurement",name:"Procurement"},
                        {type:"employment",name:"Employment"},
                        {type:"gmou",name:"GMOU"},
                        {type:"social_investment",name:"Social Investment"},
                        {type:"philanthropy",name:"Philanthropy projects"},
                        {type:"idp",name:"IDP Projects"},
                        {type:"humanitarian",name:"Humanitarian Projects"}
                    ]
    const [data, setData] = useState({
        type:[],
        from:moment().format("YYYY/MM/DD"),
        to:moment().format("YYYY/MM/DD"),
        communities:[],
        community_id:""
    })
    const dispatch = useDispatch();
    
    const handleCreateData = (e)=>{
        let field = e.target.id;
        setData({...data, [field] :e.target.value});
    }
    const handleGenerate = (e) => {
        e.preventDefault();
        const token = tokenConfig.getToken()
        const config = {
            headers: {
            "Content-Type": "application/json"
            }
        }
        let type = data.type.map(item=>item.value);
        let comm = data.communities.map(item=>item.value);
        const formData = {
            ...data,
            type,
            communities: comm,
            access_token: token
        }
        const endpoint = endpoints.GENERATE_REPORT;
        dispatch(message.messageStart());
        //console.log(formData);
        axios 
        .post(`${endpoint}`, formData, config)
        .then((res, req) => {
            //console.log(res.data)
            dispatch(message.messageSuccess({message:"Report generated. Ensure browser pop-up is allowed for this site to view."}));
            window.open(res.data.path, '_blank');
        }).catch(err => {
            //console.log(err.response);
        });
        // setCreate(false);
    }
    useEffect(() => {
        dispatch(userActions.getAllUsers());
        // dispatch(documentActions.getAllDocuments());
        // return () => {
        //     dispatch(budgetActions.getAllCurrentProjects());
        // };
    },[]);

  return (

    <Layout>
        <BreadCrumbSection
            content="Generate Report"
        />
        <div className="container p-3">
            <div className="row"> 
                <div className="col-md-12">
                    <div className="card mt-2">
                        <div className="card-header d-flex justify-content-between">
                            Generate Report
                        </div>
                        <div className="card-body py-5 row d-flex justify-content-center">
                            <form onSubmit={handleGenerate} className="w-100" style={{maxWidth:500}}>
                                <div className="form-group">
                                    <label>Select Category</label>
                                    <MultiSelect
                                        options={report_types.map(item=> {
                                                return {value: item.type, label: item.name}
                                            }
                                        )}
                                        value={data.type}
                                        onChange={(output) => setData({...data, type : output})}
                                        labelledBy={"Select"}
                                    />
                                    {/* <select required className="form-control" id="type" selected={data.type} onChange={handleCreateData}>
                                        <option value="">Select Category</option>
                                        { report_types.map(item=>
                                            <option value={item.type}>{item.name}</option>
                                        )}
                                    </select> */}
                                </div>
                                <div className="form-group">
                                    <label>Date from </label>
                                    <DatePicker id="from"  className="form-control"
                                        selected={moment(data.from, 'YYYY/MM/DD').toDate()}
                                        onChange={(e)=>setData({...data, from :moment(e).format('YYYY/MM/DD')})}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Date to </label>
                                    <DatePicker id="to"  className="form-control"
                                        selected={moment(data.to, 'YYYY/MM/DD').toDate()}
                                        onChange={(e)=>setData({...data, to :moment(e).format('YYYY/MM/DD')})}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Select Community</label>

                                    <MultiSelect
                                        options={communities.data.map(item=> {
                                                return {value: item.id, label: item.name}
                                            }
                                        )}
                                        value={data.communities}
                                        onChange={(output) => setData({...data, communities : output})}
                                        labelledBy={"Select"}
                                    />
                                    {/* <select required className="form-control" id="community_id" selected={data.community_id} onChange={handleCreateData}>
                                        <option value="">Select Community</option>
                                        {communities.data ? communities.data.map(item=>
                                            <option value={item.id}>{item.name}</option>
                                        ):""}
                                    </select> */}
                                </div>
                                <button className={'btn btn-success w-100 mt-3'}>Generate Report</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  )
}
