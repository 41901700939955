import React from 'react';
import Nav from './Nav'

const Layout = ({children}) => {
    return (
        <React.Fragment>
            <Nav/>
            <div>
                {children}
            </div>
        </React.Fragment>
    );
};

export default Layout;