import * as types from '../actions/actionTypes';
import * as tokenConfig from './../../utils/tokenConfig'


const initialState = {
  token: tokenConfig.getToken(),
  user: tokenConfig.getUserdata(),
  isAuth: false,
  isLoading: false,
  error: [],
  data: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_AUTH_USER_START:
    case types.LOGIN_START:
      return {
        ...state,
        isAuth: false,
        error: [],
        isLoading: true,
      }
    case types.LOAD_AUTH_USER_SUCCESS:
      return {
        ...state,
        isAuth: true,
        isLoading: false,
      }
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        user: action.user,
        isAuth: true,
        isLoading: false,
      }
    case types.LOGIN_FAILED:
      return {
        ...state,
        isAuth: false,
        error: [action.error],
        isLoading: false,
      }
    case types.LOGOUT_SUCCESS:
      tokenConfig.deleteToken()
      return {
        ...state,
        token: null,
        user: null,
        isAuth: false,
        isLoading: false,
      }
    default:
      return state;
  }
}