import moment from 'moment';

export const finishAuthentication = (token, userData, expires_at) => {
    localStorage.setItem('spme_jwt', token);
    localStorage.setItem('spme_user_data', JSON.stringify(userData));
    localStorage.setItem('spme_jwt_expire', JSON.stringify(expires_at));
};

export const getToken = () => {
    let token = localStorage.getItem('spme_jwt');
    if (token && !isSessionExpired()) {
        return token;
    }
    return null;
};

export const getUserdata = () => {
    let userData = localStorage.getItem('spme_user_data');
    if (userData) {
        return JSON.parse(userData);
    }
    return null;
};

export const updateUserData = (userData) => {
    localStorage.setItem('spme_user_data', JSON.stringify(userData));
}

export const deleteToken = () => {
    localStorage.removeItem('spme_jwt');
    localStorage.removeItem('spme_user_data');
};

export const isSessionExpired = () => {
    const jwt = localStorage.getItem('spme_jwt_expire');
    if (!jwt) {
        return true;
    } else {
        return moment(JSON.parse(jwt)).isBefore(moment());
    }
};