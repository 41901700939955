import * as types from './actionTypes'

export const messageStart = () => ({
    type: types.MESSAGE_START
})
export const messageSuccess = (payload) => ({
    type: types.MESSAGE_SUCCESS,
    payload
})
export const messageError = (payload) => ({
    type: types.MESSAGE_SUCCESS,
    payload
})

export const closeMessage = () => ({
    type: types.MESSAGE_CLOSE
})