import axios from "axios";

// Creates a base instance for all axios based request
// So no need the call the full url when using this
// Just call axios.get("/jobs")
// We could set other properties here like headers, etc

const instance = axios.create({

  // baseURL: "http://localhost:8000/api/v1/"
  // baseURL: "https://api.firstepdcspme.com/api/v1"
  baseURL: "https://api.dicl-ict.com/api/v1/"
});

export default instance;