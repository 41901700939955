import * as types from './../actions/actionTypes';

const initialState = {
  type: "success",
  content: "",
  open: 0,
  loading: 0
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.MESSAGE_START:
          return {
            ...state, 
            loading: 1
          }
        case types.MESSAGE_SUCCESS:
        return {
            ...state, 
            type: "success",
            content: action.payload.message,
            open: 1, 
            loading: 0
        }
        case types.MESSAGE_ERROR: {
            return {
                ...state, 
                type: "error",
                content: action.payload.message,
                open: 1, 
                loading: 0
            }
        }
        case types.MESSAGE_CLOSE: {
            return {
                ...state, 
                type: "success",
                content: "",
                open: 0, 
                loading: 0
            }
        }
        default:
            return state;
    }
  }