import React, {useEffect} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import * as grievanceActions from '../../redux/actions/grievanceActions';
import Layout from '../../components/Layout';

export default function Grievances() {
    const grievances = useSelector(state => state.grievances.data);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(grievanceActions.getAllGrievances());
        return () => {
            dispatch(grievanceActions.getAllGrievances());
        };
    },[]);

  return (

    <Layout>
        <BreadCrumbSection
            content={`Grievances`}
        />
        <div className="container p-3">
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table className="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Reference Number</th>
                            <th>Project OML</th>
                            <th>Complainant Name</th>
                            <th>Nature of Complaint</th>
                            <th>Status</th>
                            <th>Date Submitted</th>
                            <th>Action Taken</th>
                            <th>Final Resolution</th>
                            <th>Close Out Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {grievances && grievances.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.reference_number}</td>
                                <td>{item.project_oml}</td>
                                <td>{item.complainant_name}</td>
                                <td>{item.nature_of_complaint}</td>
                                <td>{item.submitted_on}</td>
                                <td>{item.action_taken}</td>
                                <td>{item.final_resolution || <span className="text-info">Not resolved</span>}</td>
                                <td>{item.status === 0 ? <div className="badge badge-danger">Pending</div> : 
                                        item.status === 1 ? <div className="badge badge-warning">Ongoing</div> : 
                                        item.status === 2 ? <div className="badge badge-success">Escalated</div> : ""
                                        }</td>
                                <td>{item.close_out_date || <span className="text-info">Not closed yet</span>}</td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
        </div>
    </Layout>
  )
}
