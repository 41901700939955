import React, {useEffect, useState} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import * as educationActions from '../../redux/actions/educationActions';
import Layout from '../../components/Layout';
import C3Chart from 'react-c3js';
import 'c3/c3.css';
import { Converter } from '../../components/helper';

export default function Issues({type}) {
    let {interventions,scholarships,teachersStats,oeiStats} = useSelector(state => state.education);
    const communities = useSelector(state => state.communities);
    const [community, setCommunity] = useState(1);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(educationActions.getAllInterventions());
        dispatch(educationActions.getAllScholarships());
        dispatch(educationActions.getAllTeachersStats());
        dispatch(educationActions.getAllOEIStats());
        return () => {
        };
    },[]);
    let interventions_chat_data =[]
    let scholarships_chat_data = []
    let teachers_chat_data = []

    interventions_chat_data = {
        x:"Years",
        columns: [
            ['Years'].concat(interventions.length>0 ? interventions.find(item=>item.id == community).interventions.filter(intervention=> intervention.type == "primary").map(item=>item.year):[]),
            ['boys'].concat(interventions.length>0 ? interventions.find(item=>item.id == community).interventions.filter(intervention=> intervention.type == "primary").map(item=>item.boys):[]),
            ['girls'].concat(interventions.length>0 ? interventions.find(item=>item.id == community).interventions.filter(intervention=> intervention.type == "primary").map(item=>item.girls):[]),
        ],
      }
    scholarships_chat_data = {
          x:"Years",
          columns: [
              ['Years'].concat(scholarships.length>0 ? scholarships.find(item=>item.id == community).scholarship_stats.map(item=>item.year):[]),
              ['Boys'].concat(scholarships.length>0 ? scholarships.find(item=>item.id == community).scholarship_stats.map(item=>item.boys):[]),
              ['Girls'].concat(scholarships.length>0 ? scholarships.find(item=>item.id == community).scholarship_stats.map(item=>item.girls):[]),
              ['Value'].concat(scholarships.length>0 ? scholarships.find(item=>item.id == community).scholarship_stats.map(item=>item.value):[]),
          ],
          axes: {
            Value: 'y2'
          },
          types: {
            Value: 'bar'
          }
      }
    teachers_chat_data = {
          x:"Years",
          columns: [
              ['Years'].concat(teachersStats.length>0 ? teachersStats.find(item=>item.id == community).teachers_stats.map(item=>item.year):[]),
              ['Males'].concat(teachersStats.length>0 ? teachersStats.find(item=>item.id == community).teachers_stats.map(item=>item.male):[]),
              ['Females'].concat(teachersStats.length>0 ? teachersStats.find(item=>item.id == community).teachers_stats.map(item=>item.female):[]),
              ['Salaries'].concat(teachersStats.length>0 ? teachersStats.find(item=>item.id == community).teachers_stats.map(item=>item.salaries):[]),
              ['Incentives'].concat(teachersStats.length>0 ? teachersStats.find(item=>item.id == community).teachers_stats.map(item=>item.other_incentives):[]),
          ],
          axes: {
            Salaries: 'y2',
            Incentives: 'y2'
          },
          type: 'bar',
          types: {
            Males: 'line',
            Females: 'line'
          }
      }

  return (

    <Layout>
        <BreadCrumbSection
            content={"Dashboard / Education"}
        />
        <div className="container p-3">
            <select className="form-control my-5" style={{maxWidth:200}} selected={community} onChange={(e)=>setCommunity(e.target.value)}>
                <option disabled>Select Community</option>
                    {communities.data ? communities.data.map(item=>
                        <option value={item.id}>{item.name}</option>
                    ):""}
                </select>
                <div>
                    
                </div>
            <h4>First E & P intervention for children age 6 - 17 years </h4>
            <C3Chart data={interventions_chat_data} />
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Number of Boys</th>
                            <th>Number of Girls</th>
                            <th>Total Number of Students</th>
                            <th>Percentage of Girls</th>
                            <th>Value</th>
                            <th>Nos of Primary 6 Pupils</th>
                            <th>Nos of Pupils who passed Primary 6 Exams</th>
                            <th>Percent Pupils passing Pry 6 Exams</th>
                            <th>Nos of Pupils qualifying for BiRKE interview</th>
                            <th>Nos of Students passing Birke interview at required level</th>
                            <th>Percent Pupils passing Birke inteview at required level </th>
                        </tr>
                    </thead>
                    <tbody>
                        {interventions.length>0 && interventions.find(item=>item.id == community).interventions.filter(intervention=> intervention.type == "primary").map(item=> {return {...item,boys: parseInt(item.boys), girls: parseInt(item.girls)}}).map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td>{item.boys}</td>
                                <td>{item.girls}</td>
                                <td>{item.girls + item.boys}</td>
                                <td>{(item.girls * 100 / (item.girls + item.boys)).toFixed(2)}</td>
                                <td><Converter value={item.value}/></td>
                                <td>{item.primary_6_pupils}</td>
                                <td>{item.primary_6_passed}</td>
                                <td>{(item.primary_6_passed * 100 / item.primary_6_pupils).toFixed(2)}</td>
                                <td>{item.birke_qualified}</td>
                                <td>{item.birke_passed}</td>
                                <td>{(item.birke_passed * 100 / item.birke_qualified).toFixed(2)}</td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
        
            
            <h4>Scholarship Beneficiaries age 6 - 17 years </h4>
            <C3Chart data={scholarships_chat_data} axis={{y2:{show:true}}}/>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Number of Boys</th>
                            <th>Number of Girls</th>
                            <th>Total Number of Students</th>
                            <th>Proportion of Girls</th>
                            <th>Value</th>
                            <th>Total Number of Pupils in Beneficiary's class</th>
                            <th>Number of Beneficiaries in top 30% of class</th>
                            <th>Percentage of Beneficiaries in top 30% of class</th>
                        </tr>
                    </thead>
                    <tbody>
                        {scholarships.length>0 && scholarships.find(item=>item.id == community).scholarship_stats.map(item=> {return {...item,boys: parseInt(item.boys), girls: parseInt(item.girls)}}).map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td>{item.boys}</td>
                                <td>{item.girls}</td>
                                <td>{item.girls + item.boys}</td>
                                <td>{(item.girls * 100 / (item.girls + item.boys)).toFixed(2)}</td>
                                <td><Converter value={item.value}/></td>
                                <td>{item.total_number_of_pupils}</td>
                                <td>{item.top_30_beneficiaries}</td>
                                <td>{(item.top_30_beneficiaries * 100 / item.total_number_of_pupils).toFixed(2)}</td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
            
            <h4>Teachers Salaries and Other Incentives </h4>
            <C3Chart data={teachers_chat_data} axis={{y2:{show:true}}}/>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Number of Males</th>
                            <th>Number of Females</th>
                            <th>Salaries</th>
                            <th>Other Incentives</th>
                            <th>Number of Male (Volunteer)</th>
                            <th>Number of Female (Volunteer)</th>
                            <th>Total Salaries (Volunteer)</th>
                            <th>Total Incentives (Volunteer)</th>
                            <th>Nos of community Volunteer Teachers Trained on Pedagogy</th>
                            <th>% Volunteer Teachers Trained on Pedagogy</th>
                            <th>Nos of community Volunteer Teachers Trained on Mentorship Skills</th>
                            <th>% Volunteer Teachers Trained on Mentorship Skills</th>
                            <th>Nos of community Volunteer Teachers in Grant Scholarship</th>
                            <th>% Volunteer Teachers in Grant Scholarship</th>
                            <th>Total Number of Male</th>
                            <th>Total Number of Female</th>
                            <th>Percentage of Females in Volunteer Cadre</th>
                            <th>Percentage of Females in Government Cadre</th>
                            <th>Percentage of Females in Teaching Cadre</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teachersStats.length>0 && teachersStats.find(item=>item.id == community).teachers_stats.map(item=> {return {...item,male: parseInt(item.male), female: parseInt(item.female)}}).map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td>{item.male}</td>
                                <td>{item.female}</td>
                                <td><Converter value={item.salaries}/></td>
                                <td><Converter value={item.other_incentives}/></td>
                                <td>{item.volunteer_male}</td>
                                <td>{item.volunteer_female}</td>
                                <td><Converter value={item.volunteer_salaries}/></td>
                                <td><Converter value={item.volunteer_other_incentives}/></td>
                                <td>{item.pedagogy_trained}</td>
                                <td>{(item.pedagogy_trained * 100 / (item.volunteer_female + item.volunteer_male)).toFixed(2)}</td>
                                <td>{item.mentorship_trained}</td>
                                <td>{(item.mentorship_trained * 100 / (item.volunteer_female + item.volunteer_male)).toFixed(2)}</td>
                                <td>{item.in_grant}</td>
                                <td>{(item.in_grant * 100 / (item.volunteer_female + item.volunteer_male)).toFixed(2)}</td>
                                <td>{item.male+item.volunteer_male}</td>
                                <td>{item.female+item.volunteer_female}</td>
                                <td>{(item.volunteer_female * 100 / (item.volunteer_female + item.volunteer_male)).toFixed(2)}</td>
                                <td>{(item.female * 100 / (item.female + item.male)).toFixed(2)}</td>
                                <td>{((item.female + item.volunteer_female) * 100 / (item.female + item.male + item.volunteer_male + item.volunteer_female)).toFixed(2)}</td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
        
            <h4>Building Projects </h4>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {oeiStats.length>0 && oeiStats.find(item=>item.id == community).building.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td><Converter value={item.value}/></td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
            <h4>Water Projects </h4>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {oeiStats.length>0 && oeiStats.find(item=>item.id == community).water.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td><Converter value={item.value}/></td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
            <h4>Sanitary Facility Projects </h4>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {oeiStats.length>0 && oeiStats.find(item=>item.id == community).sanitary_facilities.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td><Converter value={item.value}/></td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
            <h4>Security Projects </h4>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {oeiStats.length>0 && oeiStats.find(item=>item.id == community).security.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td><Converter value={item.value}/></td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
            <h4>Power Projects </h4>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {oeiStats.length>0 && oeiStats.find(item=>item.id == community).power.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td><Converter value={item.value}/></td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
        </div>
    </Layout>
  )
}
