import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';

import { getToken } from "../../utils/tokenConfig";
import * as message from './messageActions';

const getAllGMOUStatsStart = () => ({
    type: types.GET_ALL_GMOU_STATS_START
})

const getAllGMOUStatsSuccess = (data) => ({
    type: types.GET_ALL_GMOU_STATS_SUCCESS,
    payload: data
})

const getAllGMOUStatsFailed = () => ({
    type: types.GET_ALL_GMOU_STATS_FAILED
})

const createGMOUStatStart = () => ({
  type: types.CREATE_GMOU_STAT_START
})

const createGMOUStatSuccess = (data) => ({
  type: types.CREATE_GMOU_STAT_SUCCESS,
  payload: data
})

const createGMOUStatFailed = () => ({
  type: types.CREATE_GMOU_STAT_FAILED
})

export const getAllGMOUStats = () => dispatch => {
    dispatch(getAllGMOUStatsStart())
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_GMOU_STATS
    axios 
      .get(endpoint, config)
      .then(res => {
        //console.log(res)
        dispatch(getAllGMOUStatsSuccess(res.data))
      }).catch(err => {
          dispatch(getAllGMOUStatsFailed())
      });
  }

export const createGMOUStat = (data) => dispatch => {
  dispatch(message.messageStart());
    dispatch(createGMOUStatStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('access_token',getToken())
    const endpoint = endpoints.CREATE_GMOU_STAT
    //console.log(JSON.stringify(Object.fromEntries(formData)));
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        //console.log(res)
        dispatch(createGMOUStatSuccess(res.data.data))
        dispatch(getAllGMOUStats())
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        //console.log(err)
        dispatch(createGMOUStatFailed(err.response.data))
      });
  }