import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import styles from "./nav.module.scss";

const SubSubNavLink = ({data}) => {
    return (
        <NavLink
            className={styles.nav__sublink}
            activeClassName={styles.active}
            exact
            to={data.path}
        >
            {data.label}
            {data.submenu && data.submenu.length>0 ?
                <div className={styles.nav__subsublinksContainer}>
                    {data.submenu.map((data) => (
                        <SubSubNavLink data={data}/>
                    ))}
                </div>
                : ""
            }
        </NavLink>
    );
};

export default SubSubNavLink;