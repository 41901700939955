import React,{useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import DatePicker from "react-datepicker";
import MultiSelect from "react-multi-select-component";
import * as stakeholderActions from '../../../redux/actions/stakeholderActions';

const Schedule = () => {
    const [createData, setCreateData] = useState({
        details: "",
        stakeholders:[],
        date:moment().format("YYYY/MM/DD")
    })
    const handleCreateData = (e)=>{
        let field = e.target.id;
        setCreateData({...createData, [field] :e.target.value});
    }
    const dispatch = useDispatch();
    const submitData = (e)=>{
        e.preventDefault();
        let data = {...createData,stakeholders: createData.stakeholders.map(item=>item.value)};
        //console.log(data)
        dispatch(stakeholderActions.createSchedule(data));
    }
    
    const sieve = (array,store=[], prepend="")=>{
        array.forEach(element => {
            if(element.children.length > 0){
                store.concat(sieve(element.children,store, element.name+ " > "))
            }else{
                store.push({...element, name: prepend + element.name});
            }
        });
        return store;
    }
    const all_stakeholders = useSelector(state => state.all_stakeholders);
    const schedules = useSelector(state => state.schedules);
    const stakeholders = sieve(all_stakeholders.data)
    
    useEffect(() => {
        dispatch(stakeholderActions.getAllSchedules());
    },[]);
    return (
        <div className="card">
            <div className="card-header">
                Set a Meeting Schedule
            </div>
            <div className="card-body" style={{maxHeight: "100vh", overflow:"scroll"}}>
                <div className="row">
                    <div className="col-md-6 m-auto">
                        <form className="w-100" onSubmit={submitData}>
                            <div className="form-group">
                                <label>Select Stakeholder</label>
                                <MultiSelect
                                    options={stakeholders.map(item=> {
                                            return {value: item.id, label: item.name}
                                        }
                                    )}
                                    value={createData.stakeholders}
                                    onChange={(data) => setCreateData({...createData, stakeholders :data})}
                                    labelledBy={"Select"}
                                />
                            </div>
                            <div className="form-group">
                                <label>Meeting Details</label>
                                <textarea className="form-control" id="details" value={createData.details} onChange={handleCreateData}>

                                </textarea>
                            </div>
                            <div className="form-group">
                                <label>Date</label><br/>
                                
                                <DatePicker id="date"  className="form-control"
                                    selected={moment(createData.date, 'YYYY/MM/DD').toDate()}
                                    onChange={(e)=>setCreateData({...createData, 'date' :moment(e).format('YYYY/MM/DD')})}
                                />
                            </div>
                            <button type="submit" className={'btn btn-success w-100 mt-3'}>Set</button>
                        </form>
                        <div>
                            {
                                schedules && schedules.data.map(item=>
                                    <div className="border mt-3 p-2">
                                        <b>{item.details} </b> <p className="text-success">{item.stakeholders.map(stakeholder=> stakeholder.name + ", ")}</p> <p><small><i className="fa fa-calendar"></i> {item.date}</small></p></div>)
                            }
                        </div>
                    </div>
                    
                </div>
            
            </div>
        </div>
       );
};

export default Schedule;