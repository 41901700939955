export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const LOAD_AUTH_USER_START = "LOAD_AUTH_USER_START";
export const LOAD_AUTH_USER_SUCCESS = "LOAD_AUTH_USER_SUCCESS";
export const MENU_LOADING = 'MENU_LOADING';
export const MENU_SUCCESS = 'MENU_SUCCESS';
export const GET_ALL_STAKEHOLDERS = 'GET_ALL_STAKEHOLDERS';
export const GET_ALL_STAKEHOLDERS_START = 'GET_ALL_STAKEHOLDERS_START';
export const GET_ALL_STAKEHOLDERS_SUCCESS = 'GET_ALL_STAKEHOLDERS_SUCCESS';
export const GET_ALL_STAKEHOLDERS_FAILED = 'GET_ALL_STAKEHOLDERS_FAILED';
export const GET_ALL_COMMUNITIES_START = 'GET_ALL_COMMUNITIES_START';
export const GET_ALL_COMMUNITIES_SUCCESS = 'GET_ALL_COMMUNITIES_SUCCESS';
export const GET_ALL_COMMUNITIES_FAILED = 'GET_ALL_COMMUNITIES_FAILED';
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const GET_ALL_PROJECTS_START = 'GET_ALL_PROJECTS_START';
export const GET_ALL_PROJECTS_SUCCESS = 'GET_ALL_PROJECTS_SUCCESS';
export const GET_ALL_PROJECTS_FAILED = 'GET_ALL_PROJECTS_FAILED';
export const CREATE_PROJECT_START = 'CREATE_PROJECT_START';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED';
export const GET_ALL_DOCUMENTS_START = 'GET_ALL_DOCUMENTS_START';
export const GET_ALL_DOCUMENTS_SUCCESS = 'GET_ALL_DOCUMENTS_SUCCESS';
export const GET_ALL_DOCUMENTS_FAILED = 'GET_ALL_DOCUMENTS_FAILED';
export const CREATE_DOCUMENT_START = 'CREATE_DOCUMENT_START';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILED = 'CREATE_DOCUMENT_FAILED';
export const CREATE_DOCUMENT_CATEGORY_START = 'CREATE_DOCUMENT_CATEGORY_START';
export const CREATE_DOCUMENT_CATEGORY_SUCCESS = 'CREATE_DOCUMENT_CATEGORY_SUCCESS';
export const CREATE_DOCUMENT_CATEGORY_FAILED = 'CREATE_DOCUMENT_CATEGORY_FAILED';
export const GET_ALL_CURRENT_PROJECTS = 'GET_ALL_CURRENT_PROJECTS';
export const GET_ALL_CURRENT_PROJECTS_START = 'GET_ALL_CURRENT_PROJECTS_START';
export const GET_ALL_CURRENT_PROJECTS_SUCCESS = 'GET_ALL_CURRENT_PROJECTS_SUCCESS';
export const GET_ALL_CURRENT_PROJECTS_FAILED = 'GET_ALL_CURRENT_PROJECTS_FAILED';

export const GET_STAKEHOLDER_START = 'GET_STAKEHOLDER_START';
export const GET_STAKEHOLDER_SUCCESS = 'GET_STAKEHOLDER_SUCCESS';
export const GET_STAKEHOLDER_FAILED = 'GET_STAKEHOLDER_FAILED';
export const CREATE_STAKEHOLDER_START = 'CREATE_STAKEHOLDER_START';
export const CREATE_STAKEHOLDER_SUCCESS = 'CREATE_STAKEHOLDER_SUCCESS';
export const CREATE_STAKEHOLDER_FAILED = 'CREATE_STAKEHOLDER_FAILED';

export const GET_ALL_SCHEDULES_START = 'GET_ALL_SCHEDULES_START';
export const GET_ALL_SCHEDULES_SUCCESS = 'GET_ALL_SCHEDULES_SUCCESS';
export const GET_ALL_SCHEDULES_FAILED = 'GET_ALL_SCHEDULES_FAILED';
export const CREATE_SCHEDULE_START = 'CREATE_SCHEDULE_START';
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_FAILED = 'CREATE_SCHEDULE_FAILED';

export const GET_ALL_ISSUES_START = 'GET_ALL_ISSUES_START';
export const GET_ALL_ISSUES_SUCCESS = 'GET_ALL_ISSUES_SUCCESS';
export const GET_ALL_ISSUES_FAILED = 'GET_ALL_ISSUES_FAILED';
export const CREATE_ISSUE_START = 'CREATE_ISSUE_START';
export const CREATE_ISSUE_SUCCESS = 'CREATE_ISSUE_SUCCESS';
export const CREATE_ISSUE_FAILED = 'CREATE_ISSUE_FAILED';

export const GET_ALL_INTERVENTIONS_START = 'GET_ALL_INTERVENTIONS_START';
export const GET_ALL_INTERVENTIONS_SUCCESS = 'GET_ALL_INTERVENTIONS_SUCCESS';
export const GET_ALL_INTERVENTIONS_FAILED = 'GET_ALL_INTERVENTIONS_FAILED';
export const CREATE_INTERVENTION_START = 'CREATE_INTERVENTION_START';
export const CREATE_INTERVENTION_SUCCESS = 'CREATE_INTERVENTION_SUCCESS';
export const CREATE_INTERVENTION_FAILED = 'CREATE_INTERVENTION_FAILED';

export const GET_ALL_SCHOLARSHIPS_START = 'GET_ALL_SCHOLARSHIPS_START';
export const GET_ALL_SCHOLARSHIPS_SUCCESS = 'GET_ALL_SCHOLARSHIPS_SUCCESS';
export const GET_ALL_SCHOLARSHIPS_FAILED = 'GET_ALL_SCHOLARSHIPS_FAILED';
export const CREATE_SCHOLARSHIP_START = 'CREATE_SCHOLARSHIP_START';
export const CREATE_SCHOLARSHIP_SUCCESS = 'CREATE_SCHOLARSHIP_SUCCESS';
export const CREATE_SCHOLARSHIP_FAILED = 'CREATE_SCHOLARSHIP_FAILED';

export const GET_ALL_TEACHERS_STATS_START = 'GET_ALL_TEACHERS_STATS_START';
export const GET_ALL_TEACHERS_STATS_SUCCESS = 'GET_ALL_TEACHERS_STATS_SUCCESS';
export const GET_ALL_TEACHERS_STATS_FAILED = 'GET_ALL_TEACHERS_STATS_FAILED';
export const CREATE_TEACHERS_STAT_START = 'CREATE_TEACHERS_STAT_START';
export const CREATE_TEACHERS_STAT_SUCCESS = 'CREATE_TEACHERS_STAT_SUCCESS';
export const CREATE_TEACHERS_STAT_FAILED = 'CREATE_TEACHERS_STAT_FAILED';

export const GET_ALL_HEALTH_STATS_START = 'GET_ALL_HEALTH_STATS_START';
export const GET_ALL_HEALTH_STATS_SUCCESS = 'GET_ALL_HEALTH_STATS_SUCCESS';
export const GET_ALL_HEALTH_STATS_FAILED = 'GET_ALL_HEALTH_STATS_FAILED';
export const CREATE_HEALTH_STAT_START = 'CREATE_HEALTH_STAT_START';
export const CREATE_HEALTH_STAT_SUCCESS = 'CREATE_HEALTH_STAT_SUCCESS';
export const CREATE_HEALTH_STAT_FAILED = 'CREATE_HEALTH_STAT_FAILED';

export const GET_ALL_OEI_STATS_SUCCESS = 'GET_ALL_OEI_STATS_SUCCESS';
export const CREATE_OEI_STAT_SUCCESS = 'CREATE_OEI_STAT_SUCCESS';

export const GET_ALL_GRIEVANCES_START = 'GET_ALL_GRIEVANCES_START';
export const GET_ALL_GRIEVANCES_SUCCESS = 'GET_ALL_GRIEVANCES_SUCCESS';
export const GET_ALL_GRIEVANCES_FAILED = 'GET_ALL_GRIEVANCES_FAILED';
export const CREATE_GRIEVANCE_START = 'CREATE_GRIEVANCE_START';
export const CREATE_GRIEVANCE_SUCCESS = 'CREATE_GRIEVANCE_SUCCESS';
export const CREATE_GRIEVANCE_FAILED = 'CREATE_GRIEVANCE_FAILED';

export const GET_ALL_ENTERPRISE_STATS_START = 'GET_ALL_ENTERPRISE_STATS_START';
export const GET_ALL_ENTERPRISE_STATS_SUCCESS = 'GET_ALL_ENTERPRISE_STATS_SUCCESS';
export const GET_ALL_ENTERPRISE_STATS_FAILED = 'GET_ALL_ENTERPRISE_STATS_FAILED';
export const CREATE_ENTERPRISE_STAT_START = 'CREATE_ENTERPRISE_STAT_START';
export const CREATE_ENTERPRISE_STAT_SUCCESS = 'CREATE_ENTERPRISE_STAT_SUCCESS';
export const CREATE_ENTERPRISE_STAT_FAILED = 'CREATE_ENTERPRISE_STAT_FAILED';

export const GET_ALL_LOCAL_CONTENT_STATS_START = 'GET_ALL_LOCAL_CONTENT_STATS_START';
export const GET_ALL_LOCAL_CONTENT_STATS_SUCCESS = 'GET_ALL_LOCAL_CONTENT_STATS_SUCCESS';
export const GET_ALL_LOCAL_CONTENT_STATS_FAILED = 'GET_ALL_LOCAL_CONTENT_STATS_FAILED';
export const CREATE_LOCAL_CONTENT_STAT_START = 'CREATE_LOCAL_CONTENT_STAT_START';
export const CREATE_LOCAL_CONTENT_STAT_SUCCESS = 'CREATE_LOCAL_CONTENT_STAT_SUCCESS';
export const CREATE_LOCAL_CONTENT_STAT_FAILED = 'CREATE_LOCAL_CONTENT_STAT_FAILED';

export const GET_ALL_GMOU_STATS_START = 'GET_ALL_GMOU_STATS_START';
export const GET_ALL_GMOU_STATS_SUCCESS = 'GET_ALL_GMOU_STATS_SUCCESS';
export const GET_ALL_GMOU_STATS_FAILED = 'GET_ALL_GMOU_STATS_FAILED';
export const CREATE_GMOU_STAT_START = 'CREATE_GMOU_STAT_START';
export const CREATE_GMOU_STAT_SUCCESS = 'CREATE_GMOU_STAT_SUCCESS';
export const CREATE_GMOU_STAT_FAILED = 'CREATE_GMOU_STAT_FAILED';

export const GET_ALL_SOCIAL_INVESTMENT_STATS_START = 'GET_ALL_SOCIAL_INVESTMENT_STATS_START';
export const GET_ALL_SOCIAL_INVESTMENT_STATS_SUCCESS = 'GET_ALL_SOCIAL_INVESTMENT_STATS_SUCCESS';
export const GET_ALL_SOCIAL_INVESTMENT_STATS_FAILED = 'GET_ALL_SOCIAL_INVESTMENT_STATS_FAILED';
export const CREATE_SOCIAL_INVESTMENT_STAT_START = 'CREATE_SOCIAL_INVESTMENT_STAT_START';
export const CREATE_SOCIAL_INVESTMENT_STAT_SUCCESS = 'CREATE_SOCIAL_INVESTMENT_STAT_SUCCESS';
export const CREATE_SOCIAL_INVESTMENT_STAT_FAILED = 'CREATE_SOCIAL_INVESTMENT_STAT_FAILED';

export const GET_ALL_PHILANTHROPY_STATS_START = 'GET_ALL_PHILANTHROPY_STATS_START';
export const GET_ALL_PHILANTHROPY_STATS_SUCCESS = 'GET_ALL_PHILANTHROPY_STATS_SUCCESS';
export const GET_ALL_PHILANTHROPY_STATS_FAILED = 'GET_ALL_PHILANTHROPY_STATS_FAILED';
export const CREATE_PHILANTHROPY_STAT_START = 'CREATE_PHILANTHROPY_STAT_START';
export const CREATE_PHILANTHROPY_STAT_SUCCESS = 'CREATE_PHILANTHROPY_STAT_SUCCESS';
export const CREATE_PHILANTHROPY_STAT_FAILED = 'CREATE_PHILANTHROPY_STAT_FAILED';

export const GET_ALL_IDP_STATS_START = 'GET_ALL_IDP_STATS_START';
export const GET_ALL_IDP_STATS_SUCCESS = 'GET_ALL_IDP_STATS_SUCCESS';
export const GET_ALL_IDP_STATS_FAILED = 'GET_ALL_IDP_STATS_FAILED';
export const CREATE_IDP_STAT_START = 'CREATE_IDP_STAT_START';
export const CREATE_IDP_STAT_SUCCESS = 'CREATE_IDP_STAT_SUCCESS';
export const CREATE_IDP_STAT_FAILED = 'CREATE_IDP_STAT_FAILED';

export const GET_ALL_HUMANITARIAN_STATS_START = 'GET_ALL_HUMANITARIAN_STATS_START';
export const GET_ALL_HUMANITARIAN_STATS_SUCCESS = 'GET_ALL_HUMANITARIAN_STATS_SUCCESS';
export const GET_ALL_HUMANITARIAN_STATS_FAILED = 'GET_ALL_HUMANITARIAN_STATS_FAILED';
export const CREATE_HUMANITARIAN_STAT_START = 'CREATE_HUMANITARIAN_STAT_START';
export const CREATE_HUMANITARIAN_STAT_SUCCESS = 'CREATE_HUMANITARIAN_STAT_SUCCESS';
export const CREATE_HUMANITARIAN_STAT_FAILED = 'CREATE_HUMANITARIAN_STAT_FAILED';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_START = 'ADD_USER_START'
export const ADD_USER_FAILED = 'ADD_USER_FAILED'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'

//USER ACTIONS 

export const USER_START = 'USER_START';
export const USER_FAILED = 'USER_FAILED';
export const USER_SUCCESS='USER_SUCCESS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const BLOCK_USER = 'BLOCK_USER';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';

export const UNBLOCK_USER = 'UNBLOCK_USER';
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const GET_ALL_ACTIVITIES_SUCCESS = 'GET_ALL_ACTIVITIES_SUCCESS';

export const MESSAGE_START = "MESSAGE_START";
export const MESSAGE_SUCCESS = "MESSAGE_SUCCESS";
export const MESSAGE_ERROR = "MESSAGE_ERROR";
export const MESSAGE_CLOSE = "MESSAGE_CLOSE";

export const TOGGLE_CURRENCY = "TOGGLE_CURRENCY";
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const GET_USD_SUCCESS = 'GET_USD_SUCCESS';