import React, { useState } from 'react';
import style from './index.module.scss'

const EditableText = ({name,type,value='',editClassName,onEnter}) => {
  let curvalue= value;
    const [state, setState] = useState({
        name,
        type: type||'text',
        value: value,
        editClassName,
        edit: false});
    const edit = ()=> {
        setState({edit:edit!==false})
    }
    return (
        state.edit===true&&

        ( (value.length < 20 || !isNaN(value)) ?
        <input 
          name={state.name}
          type={state.type}
          value={state.value}
          className={state.editClassName}
          autoFocus
          onFocus={event=>{
            const value = event.target.value
            event.target.value = ''
            event.target.value = value
            setState({...state,backup:state.value})
          }}
          onChange={event=>{
            setState({...state,value:event.target.value})
          }}
          onBlur={()=>{
            // console.log(setState)
            (state.value != state.backup) && onEnter(state.value);
            setState({...state,edit:false})
          }}
          onKeyUp={event=>{
            if(event.key==='Escape') {
              setState({...state,edit:false, value:state.backup})
            }
            if(event.key==='Enter') {
                setState({...state,edit:false});
                onEnter(state.value);
            }
          }}
        />
        :
        <textarea
        row={5}
        name={state.name}
        value={state.value}
        className={state.editClassName}
        autoFocus
        onFocus={event=>{
          const value = event.target.value
          event.target.value = ''
          event.target.value = value
          setState({...state,backup:state.value})
        }}
        onChange={event=>{
          setState({...state,value:event.target.value})
        }}
        onBlur={event=>{
          (state.value != state.backup) && onEnter(state.value);
          setState({...state,edit:false})
        }}
        onKeyUp={event=>{
          // if(event.key==='Escape') {
          //   setState({...state,edit:false});
          //   onEnter(value);
          // }
          // if(event.key==='Enter') {
          //     setState({...state,edit:false});
          //     onEnter(state.value);
          // }
        }}>
          
        </textarea>)
        ||
        <span style={{borderBottom: '1px dotted #666',whiteSpace: "pre-wrap"}} className={style._} onClick={event=>{
            setState({...state,edit:true, value: curvalue})
          }}>
          {curvalue}
        </span>
    );
};

export default EditableText;