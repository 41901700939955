import * as types from '../actions/actionTypes';
import moment from 'moment';

const projects = {
    current_projects: [],
    all_projects: [],
    document: "",
    isLoading: false,
    isAdding: false,
    addingError: [],
};
export default function reducer(state = projects, action) {
    switch (action.type) {
        case types.GET_ALL_CURRENT_PROJECTS_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_CURRENT_PROJECTS_SUCCESS:
            return {
                ...state,
                current_projects: action.payload
            }
        case types.GET_ALL_PROJECTS_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_PROJECTS_SUCCESS:
            return {
                ...state,
                all_projects: action.payload
            }
        case types.CREATE_PROJECT_START:
            return {
                ...state,
                isAdding: true,
            };
        case types.CREATE_PROJECT_SUCCESS:
            const year = moment(action.payload.start_on,"YYYY/MM/DD").format("YYYY")
            //console.log(year,moment().format('YYYY'));
            const current = year == moment().format('YYYY') ? [action.payload,...state.current_projects] : [...state.current_projects];
            const all = [action.payload,...state.all_projects];
            return {
                ...state,
                current_projects: current,
                all_projects: all,
                addingError: [],
                isAdding:false
            }
        case types.CREATE_PROJECT_FAILED:
            return {
                ...state,
                addingError: ([action.payload]),
                isAdding:false
            }
        default:
            return state;
    }
}