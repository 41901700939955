import React, {useEffect, useState} from "react";
import styles from "./nav.module.scss";
import logo from '../../assets/images/gos_logo.png'
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch} from "react-redux";
import * as menuActions from '../../redux/actions/menuActions';
import * as documentActions from '../../redux/actions/documentActions';
import * as authActions from '../../redux/actions/authActions';
import * as currencyActions from '../../redux/actions/currencyActions';
import Switch from '@material-ui/core/Switch';
import CustomNavLink from "./CustomNavLink";
import Burger from "../Burger";

export default function Nav() {
    const navItems = useSelector(state => state.menu);
    const {type,modules} = useSelector(state => state.auth.user);
    const {USD} = useSelector(state => state.currency);
    const [open, setOpen] = useState(false);
    const user_type = type
    const dispatch = useDispatch();
    console.log(window.innerWidth)
    useEffect(() => {
        dispatch(menuActions.getAllStakeholders());
        dispatch(documentActions.getAllDocuments());
      },[]);


  return (
    <div className={styles.nav}>
        <div className={styles.nav__logoContainer}>
            <div className="container d-flex justify-content-between">
                <NavLink
                    exact
                    to={"/"}
                >
                    <img src={logo} alt="Logo" className={styles.nav__logo} />
                </NavLink>
                <div className={styles.nav__burger}>
                    <Burger open={open} setOpen={setOpen} />
                </div>
                {(open || window.innerWidth > 750) ? <div className="d-flex">
                    
                    <div className="form-group">
                        <label>Currency in USD: </label>
                        <Switch
                            checked={USD}
                            onChange={()=>dispatch(currencyActions.toggleCurrency())}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </div>
                    {/* <div className="form-group">
                        <label>Currency</label>
                        <select required className="form-control" id="type" selected={USD} onChange={handleCreateData}>
                            <option value={0}>Basic User</option>
                            <option value={1}>Editor</option>
                        </select>
                    </div> */}

                    {user_type == 5 ? <NavLink
                        className="btn"
                        activeClassName={styles.active}
                        exact
                        to={'/admin/projects'}
                    >Go to Admin Dashboard</NavLink>
                    :
                    user_type >= 3 ?
                    <NavLink
                    className="btn"
                    activeClassName={styles.active}
                    exact
                    to={'/admin/projects'}
                >Go to Data center</NavLink> :
                <NavLink
                className="btn"
                activeClassName={styles.active}
                exact
                to={'/settings'}
            >Settings</NavLink>
                }   
                    <button className="btn btn-danger" onClick={()=>{dispatch(authActions.logout())}}><i className="fa fa-sign-out mr-3"></i>Logout</button>
                </div>
                : "" }
            </div>
        </div>
        {(open || window.innerWidth > 750) ? 
        <div className={styles.nav__menuContainer}>
            <div className="container">
                <div className={styles.nav__linksContainer}>
                    {Object.keys(navItems).filter((item)=> user_type>0 || (navItems[item].label && modules.map(item=>item.name).includes(navItems[item].label))).map((item) => (
                        <CustomNavLink data={navItems[item]} />
                    ))}
                </div>
            </div>
        </div>
        :""}
    </div>
  );
}
