import React, { useState } from 'react';
import Dropzone from '../Dropzone'
import style from './index.module.scss'
import FileProgress from '../FileProgress'
import * as documentActions from  '../../../redux/actions/documentActions'
import { useDispatch } from 'react-redux';

const Upload = ({onUploaded,type="budget",title=Math.random().toString(36).substring(7)}) => {
    const [state,setState] = useState({
        files: [],
        data: {},
        uploading: false,
        uploadProgress: {},
        successfullUploaded: false
    });
    const dispatch = useDispatch()
    const onFilesAdded = (files) => {
        let fileData = {
            document_name : type == "budget"?"Budget_Document":title,
            file: files[0],
            type: type
        }
        files.length>0 && setState({...state,
          files: [files[0]],
          data: fileData
        });
      }
    const renderProgress = (file) => {
        const uploadProgress = state.uploadProgress[file.name];
        if (state.uploading || state.successfullUploaded) {
            return (
            <div className={style._progressWrapper}>
                <FileProgress progress={uploadProgress ? uploadProgress.percentage : 0} />
                {/* <img
                className={style._checkIcon}
                alt="done"
                src="baseline-check_circle_outline-24px.svg"
                style={{
                    opacity:
                    uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
                }}
                /> */}
            </div>
            );
        }
    }
    const uploadFiles = async () => {
        setState({...state, uploadProgress: {}, uploading: true });
        const promises = [];
        // state.files.forEach(file => {
            const document_name = state.files[0].name;
            const progressCallback = (event)=>{
                if (event.lengthComputable) {
                 const copy = { ...state.uploadProgress };
                 copy[document_name] = {
                  state: "pending",
                  percentage: (event.loaded / event.total) * 100
                 };
                setState({...state, uploadProgress: copy, uploading: true});
                }
               }
            const loadCallback = (data) => {
                const copy = { ...state.uploadProgress };
                copy[document_name] = { state: "done", percentage: 100 };
                setState({...state, uploadProgress: copy, successfullUploaded: true, uploading: false});
                onUploaded && onUploaded(data)
                // resolve(req.response);
               };
                
            const errorCallback = event => {
                const copy = { ...state.uploadProgress };
                copy[document_name] = { state: "error", percentage: 0 };
                setState({...state, uploadProgress: copy });
                // reject(req.response);
               }
            let callback = {
                load: loadCallback,
                error: errorCallback,
                progress: progressCallback
              }    
            promises.push(dispatch(documentActions.createDocument(state.data,callback)));
        // });
        try {
          await Promise.all(promises);
      
        //   setState({...state, successfullUploaded: true, uploading: false });
        } catch (e) {
          // Not Production ready! Do some error handling here instead...
        //   setState({...state, successfullUploaded: true, uploading: false });
        }
      }
    const renderActions = () => {
        if (state.successfullUploaded) {
          return (
            <button
              onClick={() =>
                setState({...state, data:{}, uploadProgress:{}, files: [], successfullUploaded: false })
              }
            >
              Clear
            </button>
          );
        } else {
          return (
            <button
              disabled={state.files.length < 0 || state.uploading}
              onClick={uploadFiles}
            >
              Upload
            </button>
          );
        }
      }
      
    return (
        <div className={style._}>
            {/* <span className={style._title}>Upload Document</span> */}
            <div className={style.__content}>
                <div>
                    <Dropzone
                    onFilesAdded={onFilesAdded}
                    disabled={state.uploading || state.successfullUploaded}
                    />
                </div>
                <div className={style.__files}>
                    {state.files.map(file => {
                        return (
                            <div key={file.name} className={style.__row}>
                                <b>File:</b>
                                <span className={style.__filename}>{file.name}</span>
                                {renderProgress(file)}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={style.__actions}>{renderActions()}</div>
        </div>
    );
};

export default Upload;