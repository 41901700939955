import React from 'react';
import Layout from '../../components/Layout';
import Settings from '../../components/Settings'

export default function AdminSettings() {
  return (
    <Layout>
        <Settings/>
    </Layout>
  )
}
