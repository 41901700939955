import React, {useState,useEffect} from 'react'
import BreadCrumbSection from '../../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import Layout from '../../../components/admin/Layout';
import * as userActions from '../../../redux/actions/userActions'
import EditableOption from '../../../components/admin/EditableOption';
import MultiSelect from "react-multi-select-component";

export default function Users() {
    const all_users = useSelector(state => state.users);
    const access_levels = [{value:1, title: "User"}, {value:3, title: "Editor"}, {value:4, title: "Admin"}, {value:5, title: "Super Admin"}]
    const [createData, setCreateData] = useState({
        type:1,
        email:"",
        first_name:"",
        last_name:"",
        password:"",
        conpassword: "",
    })
    const passwordMismatch = createData.password !== createData.conpassword;
    const [editData, setEditData] = useState({
    })
    const dispatch = useDispatch();
    // const error = useSelector(state=>state.auth.error[0]);
    const modules = {
        data: [
            {id:1, name: "Funding and Budget"},
            {id:2, name: "Stakeholder Management"},
            {id:3, name: "Document Management"},
            {id:4, name: "Issues and Grievances"},
            {id:5, name: "Project Management"},
            {id:6, name: "Dashboard"}
        ]
    }

    const handleCreateData = (e)=>{
        let field = e.target.id;
        setCreateData({...createData, [field] :e.target.value});
    }
    const handleEditData = (e)=>{
        let field = e.target.id;
        setEditData({...editData, [field] :e.target.value});
    }
    const handleCreate = (e) => {
        e.preventDefault()
        if(passwordMismatch){
            //console.log(passwordMismatch, createData.password, createData.conpassword)
            return
        }
        let data = createData;
        delete data.conpassword;
        
        let mod = data.modules ? data.modules.map(item=>item.value): null;
        let formdata = data.modules ? {...data,modules: mod} : {...data};

        dispatch(userActions.addUser(formdata));
        setCreateData({});
        // setCreate(false);
    }
    const handleBlock = (id) => {
        dispatch(userActions.blockUser(id));
    }
    const handleUnblock = (id) => {
        dispatch(userActions.unblockUser(id));
    }
    const handleUpdateUser = (id, field) => {
        dispatch(userActions.updateUser(id,field))
    }
    
    useEffect(() => {
        dispatch(userActions.getAllUsers());
    },[]);

  return (

    <Layout>
        <BreadCrumbSection
            content="Manage and view Users"
        />
        <div className="container p-3">
            <div className="row"> 
                <div className="col-md-12">
                    <div className="card mt-2">
                        <div className="card-header d-flex justify-content-between">
                            Create Users
                        </div>
                        <div className="card-body py-5 row d-flex justify-content-center">
                            <form onSubmit={handleCreate} className="w-100" style={{maxWidth:500}}>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input required type="email" autoComplete="new-password" name="email" className="form-control" id="email" value={createData.email} onChange={handleCreateData}/>
                                </div>
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input required type="text" autoComplete="new-password" name="first_name" className="form-control" id="first_name" value={createData.first_name} onChange={handleCreateData}/>
                                </div>
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input required type="text" autoComplete="new-password" name="last_name" className="form-control" id="last_name" value={createData.last_name} onChange={handleCreateData}/>
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input required type="password" autoComplete="new-password" className="form-control" id="password" value={createData.password} onChange={handleCreateData}/>
                                </div>
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input required type="password" autoComplete="new-password" className={`form-control${passwordMismatch?" is-invalid":""}`} id="conpassword" value={createData.conpassword} onChange={handleCreateData}/>
                                    {!passwordMismatch || <div class="invalid-feedback">
                                    Password Mismatch.
                                    </div>}
                                </div>
                                <div className="form-group">
                                    <label>Access Level</label>
                                    <select required className="form-control" id="type" selected={createData.type} onChange={handleCreateData}>
                                        <option disabled>Select Access Level</option>
                                        <option value={1}>Basic User</option>
                                        <option value={3}>Editor</option>
                                        <option value={4}>Admin</option>
                                        <option value={5}>Super Admin</option>
                                        <option value={0}>Custom</option>
                                    </select>
                                </div>
                                {
                                    createData.type==0 &&  
                                    <div className="form-group">
                                        <label>Select accessible modules</label>
                                        <MultiSelect
                                            options={modules.data.map(item=> {
                                                    return {value: item.id, label: item.name}
                                                }
                                            )}
                                            value={createData.modules}
                                            onChange={(data) => setCreateData({...createData, modules :data})}
                                            labelledBy={"Select"}
                                        />
                                    </div>
                                }
                                <button className={'btn btn-success w-100 mt-3'}>Create User</button>
                            </form>
                        </div>
                    </div>
                    <div className="card mt-2">
                        <div className="card-header d-flex justify-content-between">
                            <span className="my-auto" style={{height:"min-content"}}>Users</span>
                        </div>
                        <div className="card-body row">
                            <div style={{overflow:"scroll"}}><table class="table bg-white">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Access Level</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {all_users && all_users.data.map(
                                    (item,index)=> 
                                    <tr>
                                        <td>{index+1}</td>
                                        <td>{`${item.first_name} ${item.last_name}`}</td>
                                        <td>{item.email}</td>
                                <td>{item.type==0 ? "Custom" : <EditableOption  value={item.type} onEnter={value=>handleUpdateUser(item.id,{"type": value})} options={access_levels}/>}</td>
                                        <td>{item.deleted_at ? <div className="badge badge-danger">Blocked</div> 
                                        : <div className="badge badge-success">Active</div>}</td>
                                        <td>
                                            {item.deleted_at ? <button className="btn btn-info mr-2" onClick={()=>handleUnblock(item.id)}>UnBlock</button>
                                                                : <button className="btn btn-danger mr-2" onClick={()=>handleBlock(item.id)}>Block</button>}
                                        
                                        </td>
                                    </tr>
                                    )
                               }
                               </tbody>
                            </table></div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  )
}
