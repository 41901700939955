import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';

const getAllStakeholdersStart = () => ({
    type: types.GET_ALL_STAKEHOLDERS_START
})
const getAllStakeholdersSuccess = (data) => ({
    type: types.GET_ALL_STAKEHOLDERS_SUCCESS,
    payload: data
})
const getAllStakeholdersFailed = () => ({
    type: types.GET_ALL_STAKEHOLDERS_FAILED
})
export const getAllStakeholders = () => dispatch => {
    dispatch(getAllStakeholdersStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_STAKEHOLDERS
    axios 
      .get(endpoint, config)
      .then(res => {
        //console.log(res);
        dispatch(getAllStakeholdersSuccess(res.data))
      }).catch(err => {
          dispatch(getAllStakeholdersFailed())
      });
  }
