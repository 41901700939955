import * as types from '../actions/actionTypes';

const grievances = {
    data: [],
    isLoading: false,
};
export default function reducer(state = grievances, action) {
    switch (action.type) {
        case types.GET_ALL_GRIEVANCES_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_GRIEVANCES_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
        case types.CREATE_GRIEVANCE_START:
            return {
                ...state,
                isAdding: true,
            };
        case types.CREATE_GRIEVANCE_SUCCESS:
            const all = [action.payload,...state.data];
            return {
                ...state,
                data: all
            }
        case types.CREATE_GRIEVANCE_FAILED:
            return {
                ...state,
                addingError: ([action.payload]),
                isAdding:false
            }
        default:
            return state;
    }
}