import React from 'react'
import Layout from '../../../components/admin/Layout';
import Settings from '../../../components/Settings'

export default function AdminSettings() {
  return (
    <Layout>
        <Settings/>
    </Layout>
  )
}
