import React, { useState, useEffect } from 'react';
import style from './index.module.scss'
import * as authActions from '../../redux/actions/authActions'
import { useDispatch, useSelector } from 'react-redux';

const SignIn = props => {
    //console.log(style)
    const [formData, setFormData] = useState({
        "email":"",
        "password":""
    })
    const dispatch = useDispatch();
    const auth = useSelector(state=>state.auth);
    const error = auth.error[0];
    const isLoading = auth.isLoading;

    const handleFormData = (e)=>{
        let field = e.target.id;
        setFormData({...formData, [field] :e.target.value});
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(authActions.login(formData));
        //console.log(formData)
    }
    
    return (
        <div className={style._container}>
            <div className={style._signInCard + " m-auto card  p-5"}>
                <h2>Sign In</h2>
                <form className={style._signInForm + " mt-5"} onSubmit={handleSubmit}>
                    {error ? <div className="alert alert-danger mb-3">{error}</div> : ""}
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" class="form-control" id="email" placeholder="Enter your email" value={formData.email} onChange={handleFormData}/>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" class="form-control" id="password" placeholder="Enter authorizated password" value={formData.password} onChange={handleFormData}/>
                    </div>
                    <button className={'btn btn-primary mt-3'}>{isLoading ? <i className="fa fa-spinner fa-spin"></i> :"Login"}</button>
                </form>
            </div>
        </div>
    );
};

export default SignIn;