import * as types from '../actions/actionTypes';

const initialState = {
    data: [],
    isLoading: false,
};
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_COMMUNITIES_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_COMMUNITIES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading:false
            }
        default:
            return state;
    }
}