import React, {useEffect, useState} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import * as enterpriseActions from '../../redux/actions/enterpriseActions';
import Layout from '../../components/Layout';
import C3Chart from 'react-c3js';
import 'c3/c3.css';
import { Converter } from '../../components/helper';

export default function Enterprise({type}) {
    const {enterpriseStats} = useSelector(state => state.enterprise);
    const communities = useSelector(state => state.communities);
    const [community, setCommunity] = useState(1);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(enterpriseActions.getAllEnterpriseStats());
        return () => {
        };
    },[]);
    let chat_data = {};
    const groupByYearAndAdd = (array)=>{
        let years = []
        let totals = []
        let counts = []
        while(array.length > 0){
            years.push(array[0].year);
            let this_year_values = array.filter(item => item.year == array[0].year);
            totals.push(this_year_values.reduce((prev,next) => prev + parseInt(next.amount),0));
            counts.push(this_year_values.length)
            array = array.filter(item=>item.year != array[0].year)
        };
        return ([years,totals,counts])
    }
    let contractors_details = enterpriseStats.length>0 ? groupByYearAndAdd(enterpriseStats.find(item=>item.id == community).enterprise_stats):[[],[],[]]
    chat_data = {
          x:"Years",
          columns: [
              ['Years'].concat(contractors_details[0]),
              ['Number of Contracts'].concat(contractors_details[2]),
              ['Total value of contract'].concat(contractors_details[1]),
          ],
          axes: {
            'Total value of contract': 'y2',
          }
      }

  return (

    <Layout>
        <BreadCrumbSection
            content={"Dashboard / Enterprise"}
        />
        <div className="container p-3">
            <select className="form-control my-5" style={{maxWidth:200}} selected={community} onChange={(e)=>setCommunity(e.target.value)}>
                <option disabled>Select Community</option>
                    {communities.data ? communities.data.map(item=>
                        <option value={item.id}>{item.name}</option>
                    ):""}
                </select>
                <div>
                    
                </div>
            <h4>Enterprise projects Statistics </h4>
            <C3Chart data={chat_data} axis={{y2:{show:true}}}/>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Name</th>
                            <th>Contract</th>
                            <th>Job Title</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {enterpriseStats.length>0 && enterpriseStats.find(item=>item.id == community).enterprise_stats.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td>{item.name}</td>
                                <td>{item.contract}</td>
                                <td>{item.job_title}</td>
                                <td><Converter value={item.amount}/></td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
        </div>
    </Layout>
  )
}
