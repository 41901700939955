import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';
import { getToken } from "../../utils/tokenConfig";
import * as message from './messageActions';

const getAllCurrentProjectsStart = () => ({
  type: types.GET_ALL_CURRENT_PROJECTS_START
})
const getAllCurrentProjectsSuccess = (data) => ({
  type: types.GET_ALL_CURRENT_PROJECTS_SUCCESS,
  payload: data
})
const getAllCurrentProjectsFailed = () => ({
  type: types.GET_ALL_CURRENT_PROJECTS_FAILED
})
export const getAllCurrentProjects = () => dispatch => {
  dispatch(getAllCurrentProjectsStart())
  // const token = tokenConfig.getToken()
  const config = {
    headers: {
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.GET_ALL_CURRENT_PROJECTS + "?access_token="+ getToken();
  axios 
    .get(endpoint, config)
    .then(res => {
      dispatch(getAllCurrentProjectsSuccess(res.data))
    }).catch(err => {
        dispatch(getAllCurrentProjectsFailed())
    });
}
const getAllProjectsStart = () => ({
    type: types.GET_ALL_PROJECTS_START
})
const getAllProjectsSuccess = (data) => ({
    type: types.GET_ALL_PROJECTS_SUCCESS,
    payload: data
})
const getAllProjectsFailed = () => ({
    type: types.GET_ALL_PROJECTS_FAILED
})

const createProjectStart = () => ({
  type: types.CREATE_PROJECT_START
})
const createProjectSuccess = (data) => ({
  type: types.CREATE_PROJECT_SUCCESS,
  payload: data
})
const createProjectFailed = () => ({
  type: types.CREATE_PROJECT_FAILED
})
export const getAllProjects = () => dispatch => {
    dispatch(getAllProjectsStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_PROJECTS
    axios 
      .get(endpoint, config)
      .then(res => {
        dispatch(getAllProjectsSuccess(res.data))
      }).catch(err => {
          dispatch(getAllProjectsFailed())
      });
  }

export const addProjectDetail = (id,data,broadcast=true) => dispatch => {
  //console.log(id,data,broadcast);
  const formData = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
  }, new FormData());
  formData.append('access_token',getToken())
  const config = {
    headers: {
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.PROJECTS +id+ '/add_details'
  axios 
    .post(endpoint, formData, config)
    .then(res => {
      broadcast && dispatch(getAllProjects())
      // console.log(res)
      broadcast && dispatch(message.messageSuccess({message:res.data.message}));
    }).catch(err => {
    });
}
export const trashDetails = (id) => dispatch => {
  let data={id};
  const formData = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
  }, new FormData());
  formData.append('access_token',getToken())
  const config = {
    headers: {
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.PROJECTS + 'details/delete'
  axios 
    .post(endpoint, formData, config)
    .then(res => {
      dispatch(getAllProjects())
      dispatch(message.messageSuccess({message:res.data.message}));
    }).catch(err => {
      //console.log(err);
    });
}

  export const createProject = (data) => dispatch => {
      dispatch(createProjectStart())
      // const token = tokenConfig.getToken()
      const config = {
        headers: {
          "Accept": "application/json, text/plain, */*",
        }
      }
      const fields = Object.entries(data.fields);
      delete data.fields;
      const formData = Object.keys(data).reduce((formData, key) => {
                          formData.append(key, data[key]);
                          return formData;
                      }, new FormData());
      formData.append('access_token',getToken())
      const endpoint = endpoints.CREATE_PROJECT
      // console.log(JSON.stringify(Object.fromEntries(formData)));
      axios 
        .post(endpoint, formData, config)
        .then(res => {
          //console.log(res)
          fields.forEach(([i,value],index)=>{
            let id = res.data.data.id;
            let data = value.type=="doc" ? {
              title:value.title,
              value: "Document",
              type: value.type,
              document_id :  value.value.id
            } : {
              title:value.title,
              value: value.value,
              type: value.type
            }
            dispatch(addProjectDetail(id,data,index == (fields.length - 1)))
          })
          dispatch(createProjectSuccess(res.data.data))
          dispatch(message.messageSuccess({message:res.data.message}));
        }).catch(err => {
          //console.log(err)
            dispatch(createProjectFailed(err.response.data))
        });
    }
    export const updateProject = (data) => dispatch => {
      dispatch(message.messageStart());
      const fields = Object.entries(data.fields).filter(item=> !item[1].id);
      //console.log(data)
      delete data.fields;
        const config = {
          headers: {
            "Accept": "application/json, text/plain, */*",
          }
        }
        const formData = Object.keys(data).reduce((formData, key) => {
                            formData.append(key, data[key]);
                            return formData;
                        }, new FormData());
        formData.append('access_token',getToken())
        const endpoint = endpoints.UPDATE_PROJECT
        axios 
          .post(endpoint, formData, config)
          .then(res => {
            fields.forEach(([i,value],index)=>{
              let id = data.id;
              console.log(data.id);
              let field = value.type=="doc" ? {
                title:value.title,
                value: "Document",
                type: value.type,
                document_id :  value.value.id
              } : {
                title:value.title,
                value: value.value,
                type: value.type
              }
              dispatch(addProjectDetail(id,field,index == (fields.length - 1)))
            })
            dispatch(getAllProjects())
            dispatch(message.messageSuccess({message:res.data.message}));
          }).catch(err => {

          });
      }
    export const deleteProject = (id) => dispatch => {
      dispatch(message.messageStart());
        const config = {
          headers: {
            "Accept": "application/json, text/plain, */*",
          }
        }
        const formData = new FormData();
        formData.append('id',id)
        formData.append('access_token',getToken())

        const endpoint = endpoints.DELETE_PROJECT
        axios 
          .post(endpoint, formData, config)
          .then(res => {
            dispatch(getAllProjects())
            dispatch(getAllCurrentProjects())
            dispatch(message.messageSuccess({message:res.data.message}));
          }).catch(err => {
          });
      }
