import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';
import {getToken} from '../../utils/tokenConfig';


const getAllCommunitiesStart = () => ({
  type: types.GET_ALL_COMMUNITIES_START
})
const getAllCommunitiesSuccess = (data) => ({
  type: types.GET_ALL_COMMUNITIES_SUCCESS,
  payload: data
})
const getAllCommunitiesFailed = () => ({
  type: types.GET_ALL_COMMUNITIES_FAILED
})
export const getAllCommunities = () => dispatch => {
  dispatch(getAllCommunitiesStart())
  // const token = tokenConfig.getToken()
  const config = {
    headers: {
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = endpoints.GET_ALL_COMMUNITIES
  axios 
    .get(endpoint, config)
    .then(res => {
      //console.log(res.data)
      dispatch(getAllCommunitiesSuccess(res.data))
    }).catch(err => {
        dispatch(getAllCommunitiesFailed())
    });
}
