import React from "react";
import style from './index.module.scss';

export default function DocumentCard({name, identity, showDetails, children, id, add,trashCallback, info=true,trash=true}) {
    return (
        <div className="col-md-12  mt-2 p-2">
            <div className="card" style={{border:"none"}}>
                <div className="card-header" style={{borderBottom:"none", backgroundColor:"rgba(102, 189, 79, 0.1)"}}>
                    <div className="d-flex align-items-center">
                        <h6 className="flex-fill mb-0" style={{wordBreak: "break-all"}}>{name}</h6>
                        {trash && <button onClick={trashCallback} className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", padding:0, borderRadius:"50%", backgroundColor: "transparent", color:"red"}}>
                            <i className="fa fa-trash"></i>
                        </button>}
                        <div className={"mr-md-3 mr-sm-1 "+style.__buttons}>
                            <button className="btn" onClick={()=>add(id)} style={{width:"36px", height:"36px", padding:0, borderRadius:"50%", backgroundColor: "transparent", color:"black"}}>
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                        {info && <button onClick={()=>showDetails(identity)} className="btn" style={{width:"36px", padding:0, height:"36px", borderRadius:"50%", backgroundColor: "rgb(121, 173, 108)", color:"white"}}>
                            <i className="fa fa-eye"></i>
                        </button>}
                    </div>
                </div>
                {
                    children.length>0 ? 

                    <div className="card-body pr-0 px-0">
                        <div className="row ml-md-5 ml-sm-1 ml-xs-0">
                            {children}
                        </div>
                    </div>
                    :
                    ""
                }
            </div>
        </div>
        
    );
}
