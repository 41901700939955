
import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as message from './messageActions';
import { getToken } from "../../utils/tokenConfig";
import * as endpoints from '../../utils/endpoints';

export const toggleCurrency = () => ({
  type: types.TOGGLE_CURRENCY
})
const getUSDSuccess = (conversion) => ({
  type: types.GET_USD_SUCCESS,
  payload: conversion
})
export const updateUSDSuccess = (conversion) => ({
    type: types.UPDATE_CURRENCY,
    payload: conversion
})
export const getUSD = () => dispatch => {
  const config = {
    headers: {
     "Accept": "application/json, text/plain, */*",
    }
  }
  const endpoint = "/currencies/usd";
  axios 
    .get(endpoint, config)
    .then(res => {
      dispatch(getUSDSuccess(res.data.data.conversion));
      //console.log(res)
    }).catch(err => {
    });
}
export const updateUSD = data => (dispatch) => {
  dispatch(message.messageStart());
  const formData = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
  }, new FormData());
  formData.append('name',"USD")
  formData.append('access_token',getToken())
  const config = {
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
      }
  }
  const endPoint = "/currencies/update"
  axios
      .post(endPoint, formData, config)
      .then(res => {
          dispatch(updateUSDSuccess(res.data.data))
          dispatch(message.messageSuccess({message:"Update successful"}));
          getUSD();
      })
      .catch(err => {
          dispatch(message.messageError({message:"An error occured"}));
      });
}