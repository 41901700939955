import * as types from '../actions/actionTypes';

const philanthropy = {
    philanthropyStats: [],
    isLoading: false,
};
export default function reducer(state = philanthropy, action) {
    switch (action.type) {
        case types.GET_ALL_PHILANTHROPY_STATS_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_PHILANTHROPY_STATS_SUCCESS:
            return {
                ...state,
                philanthropyStats: action.payload
            }
        case types.CREATE_PHILANTHROPY_STAT_START:
            return {
                ...state,
                isAdding: true,
            };
        case types.CREATE_PHILANTHROPY_STAT_SUCCESS:
            return {
                ...state,
                isAdding: false,
            }
        case types.CREATE_PHILANTHROPY_STAT_FAILED:
            return {
                ...state,
                addingError: ([action.payload]),
                isAdding:false
            }
        default:
            return state;
    }
}