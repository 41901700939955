import React, {useEffect, useState} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import * as philanthropyActions from '../../redux/actions/philanthropyActions';
import Layout from '../../components/Layout';
import 'c3/c3.css';

export default function Philanthropy() {
    const {philanthropyStats} = useSelector(state => state.philanthropy);
    const communities = useSelector(state => state.communities);
    const [community, setCommunity] = useState(1);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(philanthropyActions.getAllPhilanthropyStats());
        return () => {
        };
    },[]);

  return (

    <Layout>
        <BreadCrumbSection
            content={"Dashboard / Philanthropy"}
        />
        <div className="container p-3">
            <select className="form-control my-5" style={{maxWidth:200}} selected={community} onChange={(e)=>setCommunity(e.target.value)}>
                <option disabled>Select Community</option>
                    {communities.data ? communities.data.map(item=>
                        <option value={item.id}>{item.name}</option>
                    ):""}
                </select>
                <div>
                    
                </div>
            <h4>Philanthropy Project Records </h4>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Project</th>
                            <th>Beneficiary</th>
                            <th>Date Completed</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {philanthropyStats.length>0 && philanthropyStats.find(item=>item.id == community).philanthropy_stats.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td>{item.project}</td>
                                <td>{item.beneficiary}</td>
                                <td>{item.completed_on || <span className="text-info">Not Completed</span>}</td>
                                
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
        </div>
    </Layout>
  )
}
