import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';

import { getToken } from "../../utils/tokenConfig";
import * as message from './messageActions';

const getAllGrievancesStart = () => ({
    type: types.GET_ALL_GRIEVANCES_START
})
const getAllGrievancesSuccess = (data) => ({
    type: types.GET_ALL_GRIEVANCES_SUCCESS,
    payload: data
})
const getAllGrievancesFailed = () => ({
    type: types.GET_ALL_GRIEVANCES_FAILED
})

const createGrievancesStart = () => ({
  type: types.CREATE_GRIEVANCE_START
})
const createGrievancesSuccess = (data) => ({
  type: types.CREATE_GRIEVANCE_SUCCESS,
  payload: data
})
const createGrievancesFailed = () => ({
  type: types.CREATE_GRIEVANCE_FAILED
})
export const getAllGrievances = () => dispatch => {
    dispatch(getAllGrievancesStart())
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_GRIEVANCES
    axios 
      .get(endpoint, config)
      .then(res => {
        //console.log(res)
        dispatch(getAllGrievancesSuccess(res.data))
      }).catch(err => {
          dispatch(getAllGrievancesFailed())
      });
  }
export const createGrievances = (data) => dispatch => {
    dispatch(createGrievancesStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('access_token',getToken())
    const endpoint = endpoints.CREATE_GRIEVANCE
    //console.log(JSON.stringify(Object.fromEntries(formData)));
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        //console.log(res)
        dispatch(getAllGrievances)
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        //console.log(err)
          dispatch(createGrievancesFailed(err.response.data))
      });
  }
  export const update = (data) => dispatch => {
    //console.log(data);

    dispatch(message.messageStart());
      const config = {
        headers: {
          "Accept": "application/json, text/plain, */*",
        }
      }
      const formData = Object.keys(data).reduce((formData, key) => {
                          formData.append(key, data[key]);
                          return formData;
                      }, new FormData());
      formData.append('access_token',getToken())
      const endpoint = endpoints.UPDATE_GRIEVANCE;
      axios 
        .post(endpoint, formData, config)
        .then(res => {
          //console.log(res)
          dispatch(getAllGrievances())
          dispatch(message.messageSuccess({message:res.data.message}));
        }).catch(err => {
          //console.log(err)
        });
    }
