import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';

import { getToken } from "../../utils/tokenConfig";
import * as message from './messageActions';

const getAllLocalContentStatsStart = () => ({
    type: types.GET_ALL_LOCAL_CONTENT_STATS_START
})

const getAllLocalContentStatsSuccess = (data) => ({
    type: types.GET_ALL_LOCAL_CONTENT_STATS_SUCCESS,
    payload: data
})

const getAllLocalContentStatsFailed = () => ({
    type: types.GET_ALL_LOCAL_CONTENT_STATS_FAILED
})

const createLocalContentStatStart = () => ({
  type: types.CREATE_LOCAL_CONTENT_STAT_START
})

const createLocalContentStatSuccess = (data) => ({
  type: types.CREATE_LOCAL_CONTENT_STAT_SUCCESS,
  payload: data
})

const createLocalContentStatFailed = () => ({
  type: types.CREATE_LOCAL_CONTENT_STAT_FAILED
})

export const getAllLocalContentStats = () => dispatch => {
    dispatch(getAllLocalContentStatsStart())
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_LOCAL_CONTENT_STATS
    axios 
      .get(endpoint, config)
      .then(res => {
        //console.log(res)
        dispatch(getAllLocalContentStatsSuccess(res.data))
      }).catch(err => {
          dispatch(getAllLocalContentStatsFailed())
      });
  }

export const createLocalContentStat = (type,data) => dispatch => {
  dispatch(message.messageStart());
    dispatch(createLocalContentStatStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('type',type)
    formData.append('access_token',getToken())
    const endpoint = endpoints.CREATE_LOCAL_CONTENT_STAT
    //console.log(JSON.stringify(Object.fromEntries(formData)));
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        //console.log(res)
        dispatch(createLocalContentStatSuccess(res.data.data))
        dispatch(getAllLocalContentStats())
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        //console.log(err)
        dispatch(createLocalContentStatFailed(err.response.data))
      });
  }