import * as types from '../actions/actionTypes';
import moment from 'moment';

const issues = {
    data: {
        slow_burn:[],
        critical:[]
    },
    isLoading: false,
};
export default function reducer(state = issues, action) {
    switch (action.type) {
        case types.GET_ALL_ISSUES_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_ISSUES_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
        case types.CREATE_ISSUE_START:
            return {
                ...state,
                isAdding: true,
            };
        case types.CREATE_ISSUE_SUCCESS:
            const all = [action.payload,...state.data];
            return {
                ...state,
                // data: all
            }
        case types.CREATE_ISSUE_FAILED:
            return {
                ...state,
                addingError: ([action.payload]),
                isAdding:false
            }
        default:
            return state;
    }
}