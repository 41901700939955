import React, { useEffect, useState, Children } from "react";
import style from './index.module.scss';

export default function StakeholderCard({name,budget,spent,identity, showDetails, children, slug, add,trashCallback, info=true,trash=true}) {
    const [budgetState,setBudgetState] = useState(0);
    const [spentState,setSpentState] = useState(0);
    const [addDialog,setAddDialog] = useState(false);
    const [expanded,setExpanded] = useState(false);
    const percentage = spentState*100/budget;

    let status = "danger"
    if (percentage<=40) {
        status = "success"
    }
    else if (percentage > 40 && percentage <= 70){
        status = "warning"
    }
    else if (percentage >70 ){
        status = "danger"
    }

    useEffect(() => {
        let timer = 0;
        if(budgetState < budget){
            timer = setTimeout(function(){
                setBudgetState(budgetState + budget/20);
                setSpentState(spentState + spent/20);
                clearInterval(timer);
            },50)
        }
        return (()=>clearInterval(timer));
      });
    return (
        <div className="col-md-12  mt-2 p-2">
            <div className="card" style={{border:"none"}}>
                <div className="card-header" style={{borderBottom:"none", backgroundColor:"rgba(195, 156, 25, 0.05)"}} onClick={()=>setExpanded(!expanded)}>
                    <div className="d-flex align-items-center">
                    <h6 className="flex-fill mb-0" style={Object.assign({wordBreak: "break-all"},children.length>0 ? {cursor:"pointer"}:{})}>{(children.length>0) ? <><i className="fa fa-caret-down"></i> {name}</> : name}</h6>
                        {trash && <button onClick={trashCallback} className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"red"}}>
                            <i className="fa fa-trash"></i>
                        </button>}
                        <div className={"mr-md-3 mr-sm-1 "+style.__buttons}>
                            <button className="btn" onBlur={()=>setAddDialog(false)} onFocus={()=>{setAddDialog(true)}} style={{width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"black"}}>
                                <i className="fa fa-plus"></i>
                            </button>
                                 
                                    <div onBlur={()=>setAddDialog(false)} onFocus={()=>{setAddDialog(true)}} className={style.__newStakeholderPopUp} style={addDialog ? {} : {height:0,width:0,padding:0}}>
                                        <button className="btn w-100 text-left" onClick={()=>add('submenu',slug)}>Add Submenu</button>
                                        <button className="btn w-100 text-left" onClick={()=>add('stakeholder',slug)}>Add Stakeholder</button>
                                    </div>
                        </div>
                        {info && <button onClick={()=>showDetails(identity)} className="btn" style={{width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "#fecc28", color:"white"}}>
                            <i className="fa fa-info"></i>
                        </button>}
                    </div>
                </div>
                {
                    (children.length>0 && expanded) ? 

                    <div className="card-body pr-0 px-0">
                        <div className="row ml-md-5 ml-sm-1 ml-xs-0">
                            {children}
                        </div>
                    </div>
                    :
                    ""
                }
            </div>
        </div>
        
    );
}
