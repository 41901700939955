import React, {useState,useEffect} from 'react'
import BreadCrumbSection from '../../../components/BreadCrumbSection'
import StakeholderCard from '../../../components/admin/StakeholderCard'
import { useSelector, useDispatch} from "react-redux";
import * as documentActions from '../../../redux/actions/documentActions';
import * as stakeholderActions from '../../../redux/actions/stakeholderActions';
import Layout from '../../../components/admin/Layout';
import Upload from '../../../components/admin/Upload';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Schedule from './Schedule.js';

export default function Stakeholders() {
    const stakeholders = useSelector(state => state.all_stakeholders);
    const [view, setView] = useState(false);
    const [create, setCreate] = useState(false);
    const [createSubmenu, setCreateSubmenu] = useState(false);
    const [createParent, setCreateParent] = useState("");
    const [expanded, setExpanded] = useState(null);
    const [createData, setCreateData] = useState({
        name: "",
        parent: createParent,
        fields: {}
    })
    const [editData, setEditData] = useState({
        "id":0,
        fields: {}
    })
    const [createSubmenuData, setCreateSubmenuData] = useState({
        name: "",
        parent: createParent,
        fields:{}
    })
    const [edit, setEdit] = useState(false);
    const identityRecur = (item,identity)=>{
        if(identity.length>1 && Array.isArray(item[identity[0]].children)){
            let nextIdentity = identity;
            let nextItem = item[identity[0]].children;
            nextIdentity.shift();
            return identityRecur(nextItem,nextIdentity)
        }
        return item[identity[0]];
    }
    const createField = (key,type="text")=>{
        setCreateData({...createData,fields:{...createData.fields,[key]:{title:"",value:null,type,status:0}}})
    }
    const addField = (key,type="text")=>{
        setEditData({...editData,fields:{...editData.fields,[key]:{title:"",value:null,type,status:0}}})
    }
    const add = (type,parent)=>{
        switch(type){
            case "submenu":
                setCreateSubmenu(true)
                setCreateData({...createData,parent});
                setCreateSubmenuData({...createSubmenuData,parent});
                break;
            case "stakeholder":
                setCreate(true)
                setCreateData({...createData,parent});
                setCreateSubmenuData({...createSubmenuData,parent});
                break;
            default:
                setCreate(true)
                setCreateData({...createData,parent});
                setCreateSubmenuData({...createSubmenuData,parent});
                break;
        }
    }
    const dispatch = useDispatch();
    // const error = useSelector(state=>state.auth.error[0]);
  
    const trash = (slug)=>{
        //console.log(slug);
        dispatch(stakeholderActions.trashStakeholder(slug));
    }
    const trashDetail = (id)=>{
        // console.log(id);
        dispatch(stakeholderActions.trashStakeholderDetails(id));
    }
    const handleClose = () => setView(false);

    const handleCreateData = (e)=>{
        let field = e.target.id;
        setCreateData({...createData, [field] :e.target.value});
    }
    const handleCreateSubmenuData = (e)=>{
        let field = e.target.id;
        setCreateSubmenuData({...createSubmenuData, [field] :e.target.value});
    }
    const handleCreate = (e) => {
        e.preventDefault()
        let data = createData;
        if(data.parent==""){
            delete data.parent
        }
        dispatch(stakeholderActions.createStakeholder(data));
        setCreate(false);
        setCreateData({
            name: "",
            parent: createParent,
            fields: {}
        })
    }
    const handleCreateSubmenu = (e) => {
        e.preventDefault()
        let data = createSubmenuData;
        if(data.parent==""){
            delete data.parent
        }
        dispatch(stakeholderActions.createStakeholder(data));
        setCreateSubmenu(false);
        setCreateSubmenuData({
            name: "",
            parent: createParent,
            fields:{}
        })
    }

    const handleEdit = (e) => {
        e.preventDefault()
        let data = Object.assign({},editData);
        //console.log(data);
        dispatch(stakeholderActions.updateStakeholder(data));
        setEdit(false);
    }

    const showDetails = (identity)=>{
        setView(true)
        setExpanded(identityRecur(stakeholders.data,identity))
    }
    useEffect(() => {
        dispatch(stakeholderActions.getAllStakeholders());
        dispatch(documentActions.getAllDocuments());
    },[]);

    // <StakeholderCard key={index} identity={[index]} showDetails={showDetails} name={`${item.name}`} slug={item.slug} add={add} info={true}>
    //     <StakeholderCard key={index} identity={[index]} showDetails={showDetails} name={`${item.name}`} slug={item.slug} add={add} info={true}/>
    // </StakeholderCard>
    const viewBuilder = (stakeholders,identity=[])=>{
        return stakeholders.map((item,index)=>(
            <StakeholderCard key={index} identity={[...identity,index]} showDetails={showDetails} name={`${item.name}`} slug={item.slug} trashCallback={()=>trash(item.slug)} add={add} info={item.children.length==0}>
                {item.children.length>0 ? viewBuilder(item.children,[...identity,index]) : ""}
            </StakeholderCard>
        ));
    }

  return (

    <Layout>
        <BreadCrumbSection
            content="Here you can manage all stakeholders"
        />
        <div className="container p-3">
            <div className="row">
                <div className="col-md-12">
                    <div className="card mt-2">
                        <div className="card-header d-flex justify-content-between">
                            <span className="my-auto" style={{height:"min-content"}}>All Stakeholders</span>
                        </div>
                        <div className="card-body row" style={{maxWidth:700}}>
                            <StakeholderCard identity={[]} showDetails={()=>{}} info={false} slug={""} trash={false} add={add} name="Stakeholders">
                                {
                                    viewBuilder(stakeholders.data)
                                }
                            </StakeholderCard>
                        </div>
                    </div>
                    <Schedule/>
                    {/* <iframe className="mt-3" src="https://calendar.google.com/calendar/embed?src=38fth0g3qldaqse1m8h037ijgo%40group.calendar.google.com&ctz=Africa%2FLagos" style={{border: 0, width:"100%",height:500}} frameBorder="0" scrolling="no"></iframe> */}
                </div>
            </div>
        </div>
      <Modal show={createSubmenu} onHide={()=>setCreateSubmenu(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add a new Submenu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleCreateSubmenu}>
                <div className="form-group">
                    <label>Name</label>
                    <input type="text" className="form-control" id="name" value={createSubmenuData.name} onChange={handleCreateSubmenuData}/>
                </div>
                <button className={'btn btn-success w-100 mt-3'}>Add</button>
            </form>
        </Modal.Body>
      </Modal>
      <Modal show={create} onHide={()=>setCreate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add a new Stakeholder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleCreate}>
                <div className="form-group">
                    <label>Stakeholder Name</label>
                    <input required type="text" className="form-control" id="name" value={createData.name} onChange={handleCreateData}/>
                </div>

                {Object.values(createData.fields).map((item,index)=> {
                        switch(item.type){
                            case "text":
                                return <div className="form-group d-flex">
                                    <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={createData.fields[index].title} onChange={(e)=>{
                                        setCreateData(
                                            {...createData, 
                                            fields :{...createData.fields,
                                                [index]:{...createData.fields[index],title:e.target.value}
                                                }
                                            })
                                        }
                                    }/>
                                    <input required style={{width:"50%"}} type="text" placeholder="Value" className="form-control" value={createData.fields[index].value} onChange={(e)=>{
                                        setCreateData(
                                            {...createData, 
                                            fields :{...createData.fields,
                                                [index]:{...createData.fields[index],value:e.target.value}
                                                }
                                            })
                                        }
                                    }/>
                                </div> ;
                            case "link":
                                return <div className="form-group d-flex">
                                    <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={createData.fields[index].title} onChange={(e)=>{
                                        setCreateData(
                                            {...createData, 
                                            fields :{...createData.fields,
                                                [index]:{...createData.fields[index],title:e.target.value}
                                                }
                                            })
                                        }
                                    }/>
                                    <input required style={{width:"50%"}} type="text" placeholder="Url" className="form-control" value={createData.fields[index].value} onChange={(e)=>{
                                        setCreateData(
                                            {...createData, 
                                            fields :{...createData.fields,
                                                [index]:{...createData.fields[index],value:e.target.value}
                                                }
                                            })
                                        }
                                    }/>
                                </div> ;
                            case "doc":
                                return <div className={`form-group d-flex ${(createData.fields[index].title.length > 0 && createData.fields[index].status == 0)? "flex-column":""} mt-3`}>
                                    <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={createData.fields[index].title} onChange={(e)=>{
                                        setCreateData(
                                            {...createData, 
                                            fields :{...createData.fields,
                                                [index]:{...createData.fields[index],title:e.target.value}
                                                }
                                            })
                                        }
                                    }/>
                                    {createData.fields[index].status == 1 ? <a  style={{width:"50%"}} className="btn btn-secondary" href= {`${createData.fields[index].value.url}`} target="_blank">Document</a> : ""}
                                    {createData.fields[index].title.length > 0 && createData.fields[index].status == 0 ? <Upload onUploaded={data=>setCreateData(
                                            {...createData, 
                                            fields :{...createData.fields,
                                                [index]:{...createData.fields[index],value:{url:data.data.url, id:data.data.id},status:1}
                                                }
                                            })
                                        } type="general" title={createData.fields[index].title}/>:""}
                                </div> ;
                            default:
                                return "";
                        }
                        }
                )}

                <button onClick={()=>createField(Object.keys(createData.fields).length)} className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                    <i className="fa fa-plus"></i>
                </button>
                <button onClick={()=>createField(Object.keys(createData.fields).length,"link")} className="btn mr-md-3 mr-sm-1" style={{position: "relative", width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                    <i className="fa fa-link">
                        <i style={{fontWeight:"normal",fontSize:"10px",position:"absolute", top:10, right:0,}} className="fa fa-plus"></i>
                    </i>
                </button>
                <button onClick={()=>createField(Object.keys(createData.fields).length,"doc")} className="btn mr-md-3 mr-sm-1" style={{position: "relative", width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                    <i className="fa fa-file">
                        <i style={{fontWeight:"normal",fontSize:"10px",position:"absolute", top:10, right:0,}} className="fa fa-plus"></i>
                    </i>
                </button>
                <button className={'btn btn-success w-100 mt-3'}>Add</button>
            </form>
        </Modal.Body>
      </Modal>
      <Modal show={view} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Stakeholder Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {expanded && (
                <React.Fragment>
                    <div className="mt-3">
                        <b>Stakeholder Name: </b>
                        <span>{expanded.name}</span>
                    </div>
                    {
                        expanded.details.map(item=>
                                <div className="mt-3 d-flex">
                                    <b className="mr-4">{item.title}: </b>
                                    {item.type == "text" ? <span className="flex-fill">{item.value}</span>:""}
                                    {item.type == "doc" ? <a href={`${item.document && item.document.url}`} className="flex-fill btn btn-secondary" target="_blank">View document</a>:""}
                                    {item.type == "link" ? <a href={`${item.value}`} className="flex-fill btn btn-outline-success" target="_blank">Visit Link</a>:""}
                                </div>
                            )
                    }
                </React.Fragment>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{handleClose();setEdit(true); setEditData({...expanded, fields :expanded.details});}}>
            Edit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={edit} onHide={()=>setEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Stakeholder</Modal.Title>
        </Modal.Header>
            {expanded && (
        <Modal.Body>
            <form onSubmit={handleEdit}>
                <div className="form-group">
                    <label>Stakeholder Name:</label>
                    <input required type="text" className="form-control" id="name" value={editData.name || expanded.name} onChange={ (e)=>
                        setEditData({
                            ...editData, 
                            name: e.target.value
                        })}/>
                </div>
                    {
                        expanded.details.map(item=>
                                <div className="mt-3 d-flex">
                                    <b className="mr-4">{item.title}: </b>
                                    {item.type == "text" ? <span className="flex-fill">{item.value}</span>:""}
                                    {item.type == "doc" ? <a href={`${item.document && item.document.url}`} className="flex-fill btn btn-secondary" target="_blank">View document</a>:""}
                                    {item.type == "link" ? <a href={`${item.value}`} className="flex-fill btn btn-outline-success" target="_blank">Visit Link</a>:""}
                                    { item.type != "doc" ? <button type="button" className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", backgroundColor: "transparent", color:"green"}} onClick={()=>{
                                        setExpanded({...expanded,details: expanded.details.filter(detail=>detail!=item)}); 
                                        setEditData(
                                            {...editData, 
                                            fields :{...editData.fields,
                                                [editData.details.length]:{title: item.title,value:item.value,exists:true, type:item.type}
                                                }
                                            }
                                            )
                                        }
                                    }><i className="fa fa-pencil"></i></button> : ""}
                                    <button type="button" onClick={()=>{setExpanded({...expanded, details: expanded.details.filter(d=> d.id != item.id)}); trashDetail(item.id)}} className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", backgroundColor: "transparent", color:"red"}}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </div>
                            )
                    }
                    {Object.values(editData.fields).filter(field => !(field.id && true)).map((item,index)=> {
                            switch(item.type){
                                case "text":
                                    return <div className="form-group d-flex">
                                        <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={editData.fields[index + editData.details.length].title} onChange={(e)=>{
                                            setEditData(
                                                {...editData, 
                                                fields :{...editData.fields,
                                                    [index + editData.details.length]:{...editData.fields[index + editData.details.length],title:e.target.value}
                                                    }
                                                })
                                            }
                                        }/>
                                        <input required style={{width:"50%"}} type="text" placeholder="Value" className="form-control" value={editData.fields[index + editData.details.length].value} onChange={(e)=>{
                                            setEditData(
                                                {...editData, 
                                                fields :{...editData.fields,
                                                    [index + editData.details.length]:{...editData.fields[index + editData.details.length],value:e.target.value}
                                                    }
                                                })
                                            }
                                        }/>
                                    </div> ;
                                case "link":
                                    return <div className="form-group d-flex">
                                        <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={editData.fields[index + editData.details.length].title} onChange={(e)=>{
                                            setEditData(
                                                {...editData, 
                                                fields :{...editData.fields,
                                                    [index + editData.details.length]:{...editData.fields[index + editData.details.length],title:e.target.value}
                                                    }
                                                })
                                            }
                                        }/>
                                        <input required style={{width:"50%"}} type="text" placeholder="Url" className="form-control" value={editData.fields[index + editData.details.length].value} onChange={(e)=>{
                                            setEditData(
                                                {...editData, 
                                                fields :{...editData.fields,
                                                    [index + editData.details.length]:{...editData.fields[index + editData.details.length],value:e.target.value}
                                                    }
                                                })
                                            }
                                        }/>
                                    </div> ;
                                case "doc":
                                    return <div className={`form-group d-flex ${(editData.fields[index + editData.details.length].title.length > 0 && editData.fields[index + editData.details.length].status == 0)? "flex-column":""} mt-3`}>
                                        <input required style={{width:"50%"}} type="text" placeholder="Title" className="form-control" value={editData.fields[index + editData.details.length].title} onChange={(e)=>{
                                            setEditData(
                                                {...editData, 
                                                fields :{...editData.fields,
                                                    [index + editData.details.length]:{...editData.fields[index + editData.details.length],title:e.target.value}
                                                    }
                                                })
                                            }
                                        }/>
                                        {editData.fields[index + editData.details.length].status == 1 ? <a  style={{width:"50%"}} className="btn btn-secondary" href= {`${editData.fields[index + editData.details.length].value.url}`} target="_blank">Document</a> : ""}
                                        {editData.fields[index + editData.details.length].title.length > 0 && editData.fields[index + editData.details.length].status == 0 ? <Upload onUploaded={data=>setEditData(
                                                {...editData, 
                                                fields :{...editData.fields,
                                                    [index + editData.details.length]:{...editData.fields[index + editData.details.length],value:{url:data.data.url, id:data.data.id},status:1}
                                                    }
                                                })
                                            } type="general" title={editData.fields[index + editData.details.length].title}/>:""}
                                    </div> ;
                                default:
                                    return "";
                            }
                            }
                    )}

                    <button type="button" onClick={()=>addField(Object.keys(editData.fields).length)} className="btn mr-md-3 mr-sm-1" style={{width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                        <i className="fa fa-plus"></i>
                    </button>
                    <button type="button" onClick={()=>addField(Object.keys(editData.fields).length,"link")} className="btn mr-md-3 mr-sm-1" style={{position: "relative", width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                        <i className="fa fa-link">
                            <i style={{fontWeight:"normal",fontSize:"10px",position:"absolute", top:10, right:0,}} className="fa fa-plus"></i>
                        </i>
                    </button>
                    <button type="button" onClick={()=>addField(Object.keys(editData.fields).length,"doc")} className="btn mr-md-3 mr-sm-1" style={{position: "relative", width:"36px", height:"36px", borderRadius:"50%", backgroundColor: "transparent", color:"#666"}}>
                        <i className="fa fa-file">
                            <i style={{fontWeight:"normal",fontSize:"10px",position:"absolute", top:10, right:0,}} className="fa fa-plus"></i>
                        </i>
                    </button>
                <button className={'btn btn-success w-100 mt-3'}>Save</button>
            </form>
        </Modal.Body>)}
      </Modal>
      
    </Layout>
  )
}
