import React, {useEffect} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import * as stakeholderActions from '../../redux/actions/stakeholderActions';
import style from './index.module.scss'
import Layout from '../../components/Layout';

export default function Stakeholders(props) {
    const {name,details} = useSelector(state => state.stakeholder);
     const dispatch = useDispatch();
     const {stakeholder} = props.match.params;
    useEffect(() => {
        dispatch(stakeholderActions.getStakeholderDetails(stakeholder));
        return () => {
            dispatch(stakeholderActions.getStakeholderDetails(stakeholder));
        };
    },[stakeholder]);

  return (

    <Layout>
        <BreadCrumbSection
            content={`Stakeholder Management / Stakeholders / ${name}`}
        />
        <div className="container p-3">
            <div className="p-3 bg-white">
                {details && details.map(item=>(
                    <div className="col-md-8">
                        <p className={`${style.stakeholder__title} card-header`}>{item.type=="text" && <i className="pr-3 fa fa-file-text"></i>} {item.type=="link" && <i className="pr-3 fa fa-link"></i>} {item.type=="doc" && <i className="pr-3 fa fa-file"></i>} {item.title} </p>
                        {item.type==="text" && <p className="card-body">{item.value}</p>}
                        {item.type == "doc" ? <a  className="card-body" href={`${item.document && item.document.url}`} className="btn btn-secondary" target="_blank">View document</a>:""}
                        {item.type == "link" ? <a className="card-body" href={`${item.value}`} className="btn text-success" target="_blank">Visit Link</a>:""}
                    </div>
                ))}
            </div>
        </div>
    </Layout>
  )
}
