import React, {useState,useEffect} from 'react'
import BreadCrumbSection from '../../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import Layout from '../../../components/admin/Layout';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import * as educationActions from '../../../redux/actions/educationActions'
import * as healthActions from '../../../redux/actions/healthActions'
import * as enterpriseActions from '../../../redux/actions/enterpriseActions'
import * as localContentActions from '../../../redux/actions/localContentActions'
import * as gmouActions from '../../../redux/actions/gmouActions'
import * as socialInvestmentActions from '../../../redux/actions/socialInvestmentActions'
import * as philanthropyActions from '../../../redux/actions/philanthropyActions'
import * as idpActions from '../../../redux/actions/idpActions'
import * as humanitarianActions from '../../../redux/actions/humanitarianActions'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Upload from '../../../components/admin/Upload';
import moment from 'moment';
import { Converter } from '../../../components/helper';

export default function Dasbhoard() {
    const PRIMARY_INTERVENTION="PRIMARY_INTERVENTION", SECONDARY_INTERVENTION="SECONDARY_INTERVENTION",SCHOLARSHIP="SCHOLARSHIP",
    TEACHER="TEACHER",BUILDING="BUILDING",WATER="WATER",
    SANITARY_FACILITIES="SANITARY_FACILITIES",
    SECURITY="SECURITY",POWER="POWER", HEALTH="HEALTH",
    ENTERPRISE="ENTERPRISE",EMPLOYMENT="EMPLOYMENT",PROCUREMENT="PROCUREMENT",GMOU="GMOU"
    ,SOCIAL_INVESTMENT="SOCIAL_INVESTMENT",PHILANTHROPY="PHILANTHROPY",IDP="IDP",HUMANITARIAN="HUMANITARIAN";
    const fields = [
            {type:"year",field:"year",label:"Year"},
            {type:"integer",field:"boys",label:"Number of boys"},
            {type:"integer",field:"girls", label:"Number of girls"},
            {type:"integer",field:"boys_dropout",label:"Number of male dropouts"},
            {type:"integer",field:"girls_dropout", label:"Number of female dropouts"},
            {type:"integer",field:"male",label:"Number of male"},
            {type:"integer",field:"female", label:"Number of female"},
            {type:"decimal",field:"salaries", label:"Total Salaries Value in Naira"},
            {type:"decimal",field:"other_incentives", label:"Total Value of other Incentives in Naira"},
            {type:"integer",field:"volunteer_male",label:"Number of volunteer male"},
            {type:"integer",field:"volunteer_female", label:"Number of volunteer female"},
            {type:"decimal",field:"volunteer_salaries", label:"Total Volunteer Salaries Value in Naira"},
            {type:"decimal",field:"volunteer_other_incentives", label:"Total Value of other Volunteer Incentives in Naira"},
            {type:"integer",field:"mortality", label:"Mortality rate per year per community"},
            {type:"tiny_decimal",field:"proportion_of_attended_birth", label:"Proportion of Birth Attended by skilled medical personnel"},
            {type:"integer",field:"number_of_attended_birth", label:"Number of Birth Attended by skilled medical personnel"},
            {type:"integer",field:"total_birth", label:"Total Birth in community"},
            {type:"integer",field:"adolescent_pregnancy", label:"Early Age/Adolescent pregnancies in Primary and Secondary School"},
            {type:"integer",field:"health_workers", label:"Number of Health Workers"},
            {type:"integer",field:"insurance_covered", label:"Number of people covered under Health Insurance"},
            {type:"string",field:"name", label:"Name of contractor"},
            {type:"string",field:"contract", label:"Contract"},
            {type:"string",field:"job_title", label:"Job Title"},
            {type:"decimal",field:"amount", label:"Amount"},
            {type:"integer",field:"skilled",label:"Number of skilled labor"},
            {type:"integer",field:"unskilled",label:"Number of unskilled labor"},
            {type:"integer",field:"semi_skilled",label:"Number of semi-skilled labor"},
            {type:"string",field:"item_name", label:"Item Name"},
            {type:"decimal",field:"value", label:"Total Value in Naira"},
            {type:"integer",field:"tenure_duration", label:"Duration of Tenure"},
            {type:"decimal",field:"amount_pledged", label:"Amount Pledge"},
            {type:"document",field:"document_id", label:"Document"},
            {type:"string",field:"project", label:"Project Name"},
            {type:"string",field:"beneficiary", label:"Beneficiary"},
            {type:"date",field:"completed_on", label:"Date Completed"},
            {type:"integer",field:"primary_6_pupils", label:"Nos of Primary 6 Pupils"},
            {type:"integer",field:"primary_6_passed", label:"Nos of Pupils who passed Primary 6 Exams"},
            {type:"integer",field:"birke_qualified", label:"Nos of Pupils qualifying for BiRKE interview"},
            {type:"integer",field:"birke_passed", label:"Nos of Students passing Birke interview at required level"},
            {type:"integer",field:"jss3_students", label:"Nos of JSS 3 Students "},
            {type:"integer",field:"jss3_passed", label:"Nos of JSS 3 Students who passed JS3 Exams "},
            {type:"integer",field:"sss3_science_students", label:"Nos of SSS3 Students in Science"},
            {type:"integer",field:"sss3_science_passed", label:"Nos of SSS3 Students who pass SS3 Exams in Science"},
            {type:"integer",field:"sss3_arts_students", label:"Nos of SSS3 Students in Arts"},
            {type:"integer",field:"sss3_arts_passed", label:"Nos of SSS3 Students who pass SS3 Exams in Arts"},
            {type:"integer",field:"sss_to_tertiary", label:"Nos of SS Students transitioned to Tertiary Institutions"},
            {type:"integer",field:"sss_mentorship_trained", label:"Nos of SS Students trained in Mentorship"},
            {type:"integer",field:"teenage_pregnancy_reached", label:"Nos of JS & SS Students reached on Teenage Pregnancy Issues"},
            {type:"integer",field:"skill_acquisition_enrollment", label:"Nos of JS & SS Students enroled in skill acquisition"},
            {type:"integer",field:"out_of_school_skill_acquisition_enrollment", label:"Nos of Out-of-School youth enroled in skill acquisition"},
            {type:"integer",field:"total_number_of_pupils", label:"Total Number of Pupils in Beneficiary's class"},
            {type:"integer",field:"top_30_beneficiaries", label:"Number of Beneficiaries in top 30% of class"},
            {type:"integer",field:"pedagogy_trained", label:"Nos of community Volunteer Teachers Trained on Pedagogy"},
            {type:"integer",field:"mentorship_trained", label:"Nos of community Volunteer Teachers Trained on Mentorship Skills"},
            {type:"integer",field:"in_grant", label:"Number of Beneficiaries in grant scholarship"},
            {type:"integer",field:"fg_health_workers", label:"Number of Health Workers on LG Payroll "},
            {type:"integer",field:"hospital_attendance", label:"Nos of Under 5's Attending Health Facility"},
            {type:"integer",field:"u5_hospital_attendance", label:"Frequency of Under 5's Attendance at Health Facility"},
            {type:"integer",field:"u5_mortality", label:"Mortality: Nos of Deaths of Under 5's/'000 of population"},
            {type:"integer",field:"u5_morbidity", label:"Morbidity: Nos of Reported Specific illnesses of Under 5's/'000 of population"},
            {type:"integer",field:"pregnant_hospital_attendance", label:"Nos of Pregnant Women Attending Health Facility"},
            {type:"integer",field:"pregnant_hospital_frequency", label:"Frequency of Pregnant Women Attendance at Health Facility"},
            {type:"integer",field:"pregnant_mortality", label:"Mortality: Nos of Deaths of Pregnant Women/'000 of population"},
            {type:"integer",field:"pregnant_morbidity", label:"Morbidity: Nos of Reported Specific illnesses of Pregnant Women /'000 of population"},
            {type:"integer",field:"breast_feeding_hospital_attendance", label:"Nos of Breastfeeding Women Attending Health Facility"},
            {type:"integer",field:"breast_feeding_hospital_frequency", label:"Frequency of Breastfeeding Women Attending Health Facility"},
            {type:"integer",field:"breast_feeding_mortality", label:"Mortality: Nos of Deaths of  Breastfeeding Women/'000 of population"},
            {type:"integer",field:"breast_feeding_morbidity", label:"Morbidity: Nos of Reported Specific illnesses of Breastfeeding Women/'000 of population"},
        ]

    const education = useSelector(state => state.education);
    const health = useSelector(state => state.health);
    const gmou = useSelector(state => state.gmou);
    const socialInvestment = useSelector(state => state.socialInvestment);
    const philanthropy = useSelector(state => state.philanthropy);
    const idp = useSelector(state => state.idp);
    const humanitarian = useSelector(state => state.humanitarian);
    const enterprise = useSelector(state => state.enterprise);
    const localContent = useSelector(state => state.localContent);
    const communities = useSelector(state => state.communities);

    const [primaryIntervention,setPrimaryIntervention] = useState({community_id:1})
    const [secondaryIntervention,setSecondaryIntervention] = useState({community_id:1})
    const [scholarship,setScholarship] = useState({community_id:1})
    const [teachersStat,setTeachersStat] = useState({community_id:1})
    const [building,setBuilding] = useState({community_id:1})
    const [water,setWater] = useState({community_id:1})
    const [sanitary_facilities,setSanitaryFacilities] = useState({community_id:1})
    const [security,setSecurity] = useState({community_id:1})
    const [power,setPower] = useState({community_id:1})
    const [healthStats,setHealthStats] = useState({community_id:1})
    const [enterpriseStats,setEnterpriseStats] = useState({community_id:1})
    const [employmentStats,setEmploymentStat] = useState({community_id:1})
    const [procurementStats,setProcurementStat] = useState({community_id:1})
    const [gmouStats,setGMOUStats] = useState({community_id:1})
    const [socialInvestmentStats,setSocialInvestmentStats] = useState({community_id:1})
    const [philanthropyStats,setPhilanthropyStats] = useState({community_id:1})
    const [idpStats,setIDPStats] = useState({community_id:1})
    const [humanitarianStats,setHumanitarianStats] = useState({community_id:1})

    const current_primary_intervention = education.interventions && education.interventions.find(item=>item.id == primaryIntervention.community_id);
    const current_secondary_intervention = education.interventions && education.interventions.find(item=>item.id == secondaryIntervention.community_id);
    const current_scholarship = education.scholarships && education.scholarships.find(item=>item.id == scholarship.community_id);
    const current_teachersStat = education.teachersStats && education.teachersStats.find(item=>item.id == teachersStat.community_id);
    const current_building = education.oeiStats && education.oeiStats.find(item=>item.id == building.community_id);
    const current_water = education.oeiStats && education.oeiStats.find(item=>item.id == water.community_id);
    const current_sanitary_facilities = education.oeiStats && education.oeiStats.find(item=>item.id == sanitary_facilities.community_id);
    const current_security = education.oeiStats && education.oeiStats.find(item=>item.id == security.community_id);
    const current_power = education.oeiStats && education.oeiStats.find(item=>item.id == power.community_id);
    const current_healthStat = health.healthStats && health.healthStats.find(item=>item.id == healthStats.community_id);
    const current_enterpriseStat = enterprise.enterpriseStats && enterprise.enterpriseStats.find(item=>item.id == enterpriseStats.community_id);
    const current_employment = localContent.localContentStats && localContent.localContentStats.find(item=>item.id == employmentStats.community_id);
    const current_procurement = localContent.localContentStats && localContent.localContentStats.find(item=>item.id == procurementStats.community_id);
    const current_gmouStat = gmou.gmouStats && gmou.gmouStats.find(item=>item.id == gmouStats.community_id);
    const current_socialInvestmentStat = socialInvestment.socialInvestmentStats && socialInvestment.socialInvestmentStats.find(item=>item.id == socialInvestmentStats.community_id);
    const current_philanthropyStat = philanthropy.philanthropyStats && philanthropy.philanthropyStats.find(item=>item.id == philanthropyStats.community_id);
    const current_idpStat = idp.idpStats && idp.idpStats.find(item=>item.id == idpStats.community_id);
    const current_humanitarianStat = humanitarian.humanitarianStats && humanitarian.humanitarianStats.find(item=>item.id == humanitarianStats.community_id);

    const [create, setCreate] = useState(false);
    const [createData, setCreateData] = useState({
        type: PRIMARY_INTERVENTION,
        data: {community_id : primaryIntervention.community_id}
    })

    let createFields = [];

    switch(createData.type){
        case (PRIMARY_INTERVENTION):
            createFields = fields.filter(item=>['year','boys','girls','value','primary_6_pupils','primary_6_passed','birke_qualified','birke_passed'].includes(item.field));
            break;
        case (SECONDARY_INTERVENTION):
            createFields = fields.filter(item=>['year','boys','girls','boys_dropout','girls_dropout','value','jss3_students','jss3_passed','sss3_science_students','sss3_science_passed','sss3_arts_students','sss3_arts_passed','sss3_commercial_students','sss3_commercial_passed','sss_to_tertiary','sss_mentorship_trained','teenage_pregnancy_reached','skill_acquisition_enrollment','out_of_school_skill_acquisition_enrollment'].includes(item.field));
            break;
        case (SCHOLARSHIP):
            createFields = fields.filter(item=>['year','boys','girls','value','total_number_of_pupils','top_30_beneficiaries'].includes(item.field));
            break;
        case (TEACHER):
            createFields = fields.filter(item=>['year','male','female','salaries','other_incentives','volunteer_male','volunteer_female','volunteer_salaries','volunteer_other_incentives','pedagogy_trained','mentorship_trained','in_grant'].includes(item.field));
            break;
        case (BUILDING):
            createFields = fields.filter(item=>['year','value'].includes(item.field));
            break;
        case (WATER):
            createFields = fields.filter(item=>['year','value'].includes(item.field));
            break;
        case (SANITARY_FACILITIES):
            createFields = fields.filter(item=>['year','value'].includes(item.field));
            break;
        case (SECURITY):
            createFields = fields.filter(item=>['year','value'].includes(item.field));
            break;
        case (POWER):
            createFields = fields.filter(item=>['year','value'].includes(item.field));
            break;
        case (HEALTH):
            createFields = fields.filter(item=>['year','mortality','number_of_attended_birth','total_birth','adolescent_pregnancy','health_workers','insurance_covered','fg_health_workers','hospital_attendance','u5_hospital_attendance','u5_mortality','u5_morbidity','pregnant_hospital_attendance','pregnant_hospital_frequency','pregnant_mortality','pregnant_morbidity','breast_feeding_hospital_attendance','breast_feeding_hospital_frequency','breast_feeding_mortality','breast_feeding_morbidity'].includes(item.field));
            break;
        case (ENTERPRISE):
            createFields = fields.filter(item=>['year','name','contract','job_title','amount'].includes(item.field));
            break;
        case (PROCUREMENT):
            createFields = fields.filter(item=>['year','item_name','value'].includes(item.field));
            break;
        case (EMPLOYMENT):
            createFields = fields.filter(item=>['year','skilled','semi_skilled','unskilled'].includes(item.field));
            break;
        case (GMOU):
            createFields = fields.filter(item=>['year','tenure_duration','amount_pledged','document_id'].includes(item.field));
            break;
        case (SOCIAL_INVESTMENT):
            createFields = fields.filter(item=>['year','project','beneficiary','completed_on'].includes(item.field));
            break;
        case (PHILANTHROPY):
            createFields = fields.filter(item=>['year','project','beneficiary','completed_on'].includes(item.field));
            break;
        case (IDP):
            createFields = fields.filter(item=>['year','project','beneficiary','completed_on'].includes(item.field));
            break;
        case (HUMANITARIAN):
            createFields = fields.filter(item=>['year','project','beneficiary','completed_on'].includes(item.field));
            break;
        default:
            createFields = []
    }

    const dispatch = useDispatch();
  
    const trash = (id)=>{
        // dispatch(educationActions.trashIntervention(id));
    }

    const handleCreateData = (e)=>{
        let field = e.target.id;
        setCreateData({type:createData.type,data:{...createData.data, [field] :e.target.value}});
    }
    const handleCreate = (e) => {
        e.preventDefault()
        let data = {...createData.data,year: createData.data.year ? moment(createData.data.year,'YYYY/MM/DD').format('YYYY'):moment().format('YYYY')};
        if(createData.type === PRIMARY_INTERVENTION)
        dispatch(educationActions.createIntervention({...data,type:"primary", community_id:primaryIntervention.community_id}));
        if(createData.type === SECONDARY_INTERVENTION)
        dispatch(educationActions.createIntervention({...data,type:"secondary", community_id:secondaryIntervention.community_id}));
        if(createData.type === SCHOLARSHIP)
        dispatch(educationActions.createScholarship({...data,community_id:scholarship.community_id}));
        if(createData.type === TEACHER)
        dispatch(educationActions.createTeachersStat({...data,community_id:teachersStat.community_id}));
        let community_id = createData.type === BUILDING ? building.community_id :
        createData.type === WATER ? water.community_id :
        createData.type === SANITARY_FACILITIES ? sanitary_facilities.community_id :
        createData.type === SECURITY ? security.community_id :
        createData.type === POWER ? power.community_id :
        1
        if([BUILDING,WATER,SANITARY_FACILITIES,SECURITY,POWER].includes(createData.type))
        dispatch(educationActions.createOEIStat(createData.type.toLowerCase(),{...data,community_id:community_id}));
        if(createData.type === HEALTH)
        dispatch(healthActions.createHealthStat({...data,community_id:healthStats.community_id}));
        if(createData.type === ENTERPRISE)
        dispatch(enterpriseActions.createEnterpriseStat({...data,community_id:enterpriseStats.community_id}));
        community_id = createData.type === EMPLOYMENT ? employmentStats.community_id :
        createData.type === PROCUREMENT ? procurementStats.community_id :
        1
        if([EMPLOYMENT,PROCUREMENT].includes(createData.type))
        dispatch(localContentActions.createLocalContentStat(createData.type.toLowerCase(),{...data,community_id:community_id}));
        if(createData.type === GMOU)
        dispatch(gmouActions.createGMOUStat({...data,community_id:gmouStats.community_id}));
        if(createData.type === SOCIAL_INVESTMENT)
        dispatch(socialInvestmentActions.createSocialInvestmentStat({...data,community_id:socialInvestmentStats.community_id}));
        if(createData.type === PHILANTHROPY)
        dispatch(philanthropyActions.createPhilanthropyStat({...data,community_id:philanthropyStats.community_id}));
        if(createData.type === IDP)
        dispatch(idpActions.createIDPStat({...data,community_id:idpStats.community_id}));
        if(createData.type === HUMANITARIAN)
        dispatch(humanitarianActions.createHumanitarianStat({...data,community_id:humanitarianStats.community_id}));
         
        setCreate(false);
        setCreateData({
            type: createData.type,
            data: {community_id : primaryIntervention.community_id}
        })
    }
    useEffect(() => {
        dispatch(educationActions.getAllInterventions());
        dispatch(educationActions.getAllScholarships());
        dispatch(educationActions.getAllTeachersStats());
        dispatch(educationActions.getAllOEIStats());
        dispatch(healthActions.getAllHealthStats());
        dispatch(enterpriseActions.getAllEnterpriseStats());
        dispatch(localContentActions.getAllLocalContentStats());
        dispatch(gmouActions.getAllGMOUStats());
        dispatch(socialInvestmentActions.getAllSocialInvestmentStats());
        dispatch(philanthropyActions.getAllPhilanthropyStats());
        dispatch(idpActions.getAllIDPStats());
        dispatch(humanitarianActions.getAllHumanitarianStats());
    },[]);

  return (

    <Layout>
        <BreadCrumbSection
            content="Dashboard"
        />
        <div className="container p-3">
            <div className="row">
                <div className="col-md-12">
                <Accordion>
                    <div className="card mt-2">
                        <Accordion.Toggle  className="d-flex justify-content-between mb-0" as={Card.Header} variant="link" eventKey="0">
                            <span className="my-auto" style={{height:"min-content"}}>Education</span>
                            <i className="fa fa-caret-down"></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <div className="card-body p-5" style={{overflow:"scroll"}}>
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>First E & P intervention for primary education (age 6 - 11 years) for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={primaryIntervention.community_id} onChange={(e)=>setPrimaryIntervention({...primaryIntervention,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:PRIMARY_INTERVENTION})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Number of Boys</th>
                                            <th>Number of Girls</th>
                                            <th>Total Number of Students</th>
                                            <th>Percentage of Girls</th>
                                            <th>Value</th>
                                            <th>Nos of Primary 6 Pupils</th>
                                            <th>Nos of Pupils who passed Primary 6 Exams</th>
                                            <th>Percent Pupils passing Pry 6 Exams</th>
                                            <th>Nos of Pupils qualifying for BiRKE interview</th>
                                            <th>Nos of Students passing Birke interview at required level</th>
                                            <th>Percent Pupils passing Birke inteview at required level </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_primary_intervention && current_primary_intervention.interventions.filter(item=> item.type=="primary").map(item=> {return {...item,boys: parseInt(item.boys), girls: parseInt(item.girls)}}).map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.boys}</td>
                                                    <td>{item.girls}</td>
                                                    <td>{item.girls + item.boys}</td>
                                                    <td>{(item.girls * 100 / (item.girls + item.boys)).toFixed(2)}</td>
                                                    <td><Converter value={item.value}/></td>
                                                    <td>{item.primary_6_pupils}</td>
                                                    <td>{item.primary_6_passed}</td>
                                                    <td>{(item.primary_6_passed * 100 / item.primary_6_pupils).toFixed(2)}</td>
                                                    <td>{item.birke_qualified}</td>
                                                    <td>{item.birke_passed}</td>
                                                    <td>{(item.birke_passed * 100 / item.birke_qualified).toFixed(2)}</td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
                                <div className="py-2 d-flex justify-content-between">
                                <h4>First E & P intervention for secondary education (age 11 - 17 years) for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={secondaryIntervention.community_id} onChange={(e)=>setSecondaryIntervention({...secondaryIntervention,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:SECONDARY_INTERVENTION})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Number of Boys</th>
                                            <th>Number of Girls</th>
                                            <th>Total Number of Students</th>
                                            <th>Percentage of Girls</th>
                                            <th>Number of Male Dropouts</th>
                                            <th>Number of Female Dropouts</th>
                                            <th>Total number of Dropouts</th>
                                            <th>Percentage of Female Dropouts to total dropouts</th>
                                            <th>Percentage of Male Dropouts to total dropouts</th>
                                            <th>Percentage of Dropouts to total student</th>
                                            <th>Value</th>
                                            <th>Nos of JSS 3 Students</th>
                                            <th>Nos of JSS 3 Students who passed JS3 Exams </th>
                                            <th>Percent Students passing JS3 Exams</th>
                                            <th>Nos of SSS3 Students in Science</th>
                                            <th>Nos of SSS3 Students who pass SS3 Exams in Science</th>
                                            <th>Percent Students who pass SS3 Exams in Science</th>
                                            <th>Nos of SSS3 Students in Arts</th>
                                            <th>Nos of SSS3 Students who pass SS3 Exams in Arts</th>
                                            <th>Percent Students who pass SS3 Exams in Arts</th>
                                            <th>Nos of SS Students transitioned to Tertiary Institutions</th>
                                            <th>Nos of SS Students trained in Mentorship</th>
                                            <th>Nos of JS & SS Students reached on Teenage Pregnancy Issues</th>
                                            <th>Nos of JS & SS Students enroled in skill acquisition</th>
                                            <th>Nos of Out-of-School youth enroled in skill acquisition</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_secondary_intervention && current_secondary_intervention.interventions.filter(item=> item.type=="secondary").map(item=> {return {...item,boys: parseInt(item.boys), girls: parseInt(item.girls), boys_dropout: item.boys_dropout ? parseInt(item.boys_dropout) : 0, girls_dropout: item.girls_dropout ? parseInt(item.girls_dropout) : 0}}).map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.boys}</td>
                                                    <td>{item.girls}</td>
                                                    <td>{item.girls + item.boys}</td>
                                                    <td>{(item.girls * 100 / (item.girls + item.boys)).toFixed(2)}</td>
                                                    <td>{item.boys_dropout}</td>
                                                    <td>{item.girls_dropout}</td>
                                                    <td>{item.girls_dropout + item.boys_dropout}</td>
                                                    <td>{(item.girls_dropout * 100 / (item.girls_dropout + item.boys_dropout)).toFixed(2)}</td>
                                                    <td>{(item.boys_dropout * 100 / (item.girls_dropout + item.boys_dropout)).toFixed(2)}</td>
                                                    <td>{((item.girls_dropout + item.boys_dropout) * 100 / (item.girls + item.boys)).toFixed(2)}</td>
                                                    <td><Converter value={item.value}/></td> 
                                                    <td>{item.jss3_students}</td>
                                                    <td>{item.jss3_passed}</td>
                                                    <td>{item.jss3_passed * 100 / item.jss3_students}</td>
                                                    <td>{item.sss3_science_students}</td>
                                                    <td>{item.sss3_science_passed}</td>
                                                    <td>{item.sss3_science_passed * 100 / item.sss3_science_students}</td>
                                                    <td>{item.sss3_arts_students}</td>
                                                    <td>{item.sss3_arts_passed}</td>
                                                    <td>{(item.sss3_arts_passed * 100 / item.sss3_arts_students).toFixed(2)}</td>
                                                    {/* <td>{item.sss3_commercial_students}</td>
                                                    <td>{item.sss3_commercial_passed}</td> */}
                                                    <td>{item.sss_to_tertiary}</td>
                                                    <td>{item.sss_mentorship_trained}</td>
                                                    <td>{item.teenage_pregnancy_reached}</td>
                                                    <td>{item.skill_acquisition_enrollment}</td>
                                                    <td>{item.out_of_school_skill_acquisition_enrollment}</td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
            
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Scholarship Beneficiaries (Aged 6 - 17 years) for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={scholarship.community_id} onChange={(e)=>setScholarship({...scholarship,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:SCHOLARSHIP})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Number of Boys</th>
                                            <th>Number of Girls</th>
                                            <th>Total Number of Students</th>
                                            <th>Percentage of Girls</th>
                                            <th>Scholarship Value</th>
                                            <th>Total Number of Pupils in Beneficiary's class</th>
                                            <th>Number of Beneficiaries in top 30% of class</th>
                                            <th>Percentage of Beneficiaries in top 30% of class</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_scholarship && current_scholarship.scholarship_stats.map(item=> {return {...item,boys: parseInt(item.boys), girls: parseInt(item.girls)}}).map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.boys}</td>
                                                    <td>{item.girls}</td>
                                                    <td>{item.girls + item.boys}</td>
                                                    <td>{(item.girls * 100 / (item.girls + item.boys)).toFixed(2)}</td>
                                                    <td><Converter value={item.value}/></td>
                                                    <td>{item.total_number_of_pupils}</td>
                                                    <td>{item.top_30_beneficiaries}</td>
                                                    <td>{(item.top_30_beneficiaries * 100 / item.total_number_of_pupils).toFixed(2)}</td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>

                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Teachers' Salaries and Incentives for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={teachersStat.community_id} onChange={(e)=>setTeachersStat({...teachersStat,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:TEACHER})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Number of Male (Government)</th>
                                            <th>Number of Female (Government)</th>
                                            <th>Total Salaries (Government)</th>
                                            <th>Total Incentives (Government)</th>
                                            <th>Number of Male (Volunteer)</th>
                                            <th>Number of Female (Volunteer)</th>
                                            <th>Total Salaries (Volunteer)</th>
                                            <th>Total Incentives (Volunteer)</th>
                                            <th>Nos of community Volunteer Teachers Trained on Pedagogy</th>
                                            <th>% Volunteer Teachers Trained on Pedagogy</th>
                                            <th>Nos of community Volunteer Teachers Trained on Mentorship Skills</th>
                                            <th>% Volunteer Teachers Trained on Mentorship Skills</th>
                                            <th>Nos of community Volunteer Teachers in Grant Scholarship</th>
                                            <th>% Volunteer Teachers in Grant Scholarship</th>
                                            <th>Total Number of Male</th>
                                            <th>Total Number of Female</th>
                                            <th>Percentage of Females in Volunteer Cadre</th>
                                            <th>Percentage of Females in Government Cadre</th>
                                            <th>Percentage of Females in Teaching Cadre</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_teachersStat && current_teachersStat.teachers_stats.map(item=> {return {...item,male: parseInt(item.male), female: parseInt(item.female), volunteer_male: parseInt(item.volunteer_male), volunteer_female: parseInt(item.volunteer_female)}}).map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.male}</td>
                                                    <td>{item.female}</td>
                                                    <td><Converter value={item.salaries}/></td>
                                                    <td><Converter value={item.other_incentives}/></td>
                                                    <td>{item.volunteer_male}</td>
                                                    <td>{item.volunteer_female}</td>
                                                    <td><Converter value={item.volunteer_salaries}/></td>
                                                    <td><Converter value={item.volunteer_other_incentives}/></td>
                                                    <td>{item.pedagogy_trained}</td>
                                                    <td>{(item.pedagogy_trained * 100 / (item.volunteer_female + item.volunteer_male)).toFixed(2)}</td>
                                                    <td>{item.mentorship_trained}</td>
                                                    <td>{(item.mentorship_trained * 100 / (item.volunteer_female + item.volunteer_male)).toFixed(2)}</td>
                                                    <td>{item.in_grant}</td>
                                                    <td>{(item.in_grant * 100 / (item.volunteer_female + item.volunteer_male)).toFixed(2)}</td>
                                                    <td>{item.male+item.volunteer_male}</td>
                                                    <td>{item.female+item.volunteer_female}</td>
                                                    <td>{(item.volunteer_female * 100 / (item.volunteer_female + item.volunteer_male)).toFixed(2)}</td>
                                                    <td>{(item.female * 100 / (item.female + item.male)).toFixed(2)}</td>
                                                    <td>{((item.female + item.volunteer_female) * 100 / (item.female + item.male + item.volunteer_male + item.volunteer_female)).toFixed(2)}</td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
            
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Building Projects for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={building.community_id} onChange={(e)=>setBuilding({...building,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:BUILDING})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Total Value of Building Projects</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_building && current_building.building.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td><Converter value={item.value}/></td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
            
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Water Projects for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={water.community_id} onChange={(e)=>setWater({...water,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:WATER})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Total Value of Water Projects</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_water && current_water.water.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td><Converter value={item.value}/></td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
                            
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Sanitary Facilities Projects for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={sanitary_facilities.community_id} onChange={(e)=>setSanitaryFacilities({...sanitary_facilities,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:SANITARY_FACILITIES})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Total Value of Sanitary Facilities Projects</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_sanitary_facilities && current_sanitary_facilities.sanitary_facilities.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td><Converter value={item.value}/></td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
                                
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Security Projects for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={security.community_id} onChange={(e)=>setSecurity({...security,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:SECURITY})}}>Add new record</button>
                                </div>

                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Total Value of Power Projects</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_security && current_security.security.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td><Converter value={item.value}/></td>
                                                    {/* <td></td> */}
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table></div>
                            
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Power Projects for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={power.community_id} onChange={(e)=>setPower({...power,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:POWER})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Total Value of Power Projects</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_power && current_power.power.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td><Converter value={item.value}/></td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
            
                                {/* <div className="py-2 d-flex justify-content-between">
                                    <h4>School Based Health</h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={scholarship.community_id} onChange={(e)=>setScholarship({...scholarship,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:SCHOLARSHIP})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Number of Boys</th>
                                            <th>Number of Girls</th>
                                            <th>Total Number of Students</th>
                                            <th>Percentage of Girls</th>
                                            <th>Scholarship Value</th>
                                        </tr>
                                    </thead>
                                    <tbody> */}
                                        {
                                            // current_scholarship && current_scholarship.scholarship_stats.map(item=> {return {...item,boys: parseInt(item.boys), girls: parseInt(item.girls)}}).map((item,index)=>
                                            //     <tr>
                                            //         <td>{index+1}</td>
                                            //         <td>{item.year}</td>
                                            //         <td>{item.boys}</td>
                                            //         <td>{item.girls}</td>
                                            //         <td>{item.girls + item.boys}</td>
                                            //         <td>{(item.girls * 100 / (item.girls + item.boys)).toFixed(2)}</td>
                                            //         <td><Converter value={item.value}/></td>
                                            //         {/* <td></td> */}
                                            //     </tr>
                                            //     )
                                        }
                                    {/* </tbody>
                                </table></div> */}
            
                            </div>
                        </Accordion.Collapse>
                    </div>
                    <div className="card mt-2">
                        <Accordion.Toggle  className="d-flex justify-content-between mb-0" as={Card.Header} variant="link" eventKey="1">
                            <span className="my-auto" style={{height:"min-content"}}>Health</span>
                            <i className="fa fa-caret-down"></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <div className="card-body" style={{overflow:"scroll"}} >
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Health Statistics for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={healthStats.community_id} onChange={(e)=>setHealthStats({...healthStats,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:HEALTH})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Mortality rate per year per community</th>
                                            <th>Birth attendance by medical personel</th>
                                            <th>Total Birth recorded in community</th>
                                            <th>Proportion of Birth attended by skilled medical personnel</th>
                                            <th>Adolescent Pregnancies	</th>
                                            <th>Health Workers</th>
                                            <th>Number of Health Workers on LG Payroll </th>
                                            <th>Health Insurance</th>
                                            <th>Percent Health Workers on Government Payroll</th>
                                            <th>Nos of Under 5's Attending Health Facility</th>
                                            <th>Frequency of Under 5's Attendance at Health Facility</th>
                                            <th>Mortality: Nos of Deaths of Under 5's/'000 of population</th>
                                            <th>Morbidity: Nos of Reported Specific illnesses of Under 5's/'000 of population </th>
                                            <th>Nos of Pregnant Women Attending Health Facility</th>
                                            <th>Frequency of Pregnant Women Attendance at Health Facility</th>
                                            <th>Mortality: Nos of Deaths of Pregnant Women/'000 of population</th>
                                            <th>Morbidity: Nos of Reported Specific illnesses of Pregnant Women /'000 of population </th>
                                            <th>Nos of Breastfeeding Women Attending Health Facility</th>
                                            <th>Frequency of Breastfeeding Women Attending Health Facility</th>
                                            <th>Mortality: Nos of Deaths of  Breastfeeding Women/'000 of population</th>
                                            <th>Morbidity: Nos of Reported Specific illnesses of Breastfeeding Women/'000 of population</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_healthStat && current_healthStat.health_stats.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.mortality}</td>
                                                    <td>{item.number_of_attended_birth}</td>
                                                    <td>{item.total_birth}</td>
                                                    <td>{(item.number_of_attended_birth*100/item.total_birth).toFixed(2)}</td>
                                                    <td>{item.adolescent_pregnancy}</td>
                                                    <td>{item.health_workers}</td>
                                                    <td>{item.fg_health_workers}</td>
                                                    <td>{item.insurance_covered}</td>
                                                    <td>{item.hospital_attendance}</td>
                                                    <td>{item.u5_hospital_attendance}</td>
                                                    <td>{item.u5_mortality}</td>
                                                    <td>{item.u5_morbidity}</td>
                                                    <td>{item.pregnant_hospital_attendance}</td>
                                                    <td>{item.pregnant_hospital_frequency}</td>
                                                    <td>{item.pregnant_mortality}</td>
                                                    <td>{item.pregnant_morbidity}</td>
                                                    <td>{item.breast_feeding_hospital_attendance}</td>
                                                    <td>{item.breast_feeding_hospital_frequency}</td>
                                                    <td>{item.breast_feeding_mortality}</td>
                                                    <td>{item.breast_feeding_morbidity}</td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
            
                            </div>
                        </Accordion.Collapse>
                    </div>
                    <div className="card mt-2">
                        <Accordion.Toggle  className="card-header d-flex justify-content-between mb-0" as={Card.Header} variant="link" eventKey="2">
                            <span className="my-auto" style={{height:"min-content"}}>Enterprise</span>
                            <i className="fa fa-caret-down"></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <div className="card-body" style={{overflow:"scroll"}} >
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Enterprise Statistics for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={enterpriseStats.community_id} onChange={(e)=>setEnterpriseStats({...enterpriseStats,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:ENTERPRISE})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Name</th>
                                            <th>Contract</th>
                                            <th>Job Title</th>
                                            <th>Amount</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_enterpriseStat && current_enterpriseStat.enterprise_stats.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.contract}</td>
                                                    <td>{item.job_title}</td>
                                                    <td><Converter value={item.amount}/></td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
                            </div>
                        </Accordion.Collapse>
                    </div>
                    <div className="card mt-2">
                        <Accordion.Toggle  className="card-header d-flex justify-content-between mb-0" as={Card.Header} variant="link" eventKey="3">
                            <span className="my-auto" style={{height:"min-content"}}>Local Content</span>
                            <i className="fa fa-caret-down"></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                            <div className="card-body" style={{overflow:"scroll"}} >
                            <   div className="py-2 d-flex justify-content-between">
                                    <h4>Procurement Records for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={procurementStats.community_id} onChange={(e)=>setProcurementStat({...procurementStats,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:PROCUREMENT})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Item Name</th>
                                            <th>Value of Item</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_procurement && current_procurement.procurement_stats.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.item_name}</td>
                                                    <td><Converter value={item.value}/></td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
            
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Employment Statistics for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={employmentStats.community_id} onChange={(e)=>setEmploymentStat({...employmentStats,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:EMPLOYMENT})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Number of Skilled Labour</th>
                                            <th>Number of Semi-skilled Labour</th>
                                            <th>Number of Unskilled Labour</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_employment && current_employment.employment_stats.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.skilled}</td>
                                                    <td>{item.semi_skilled}</td>
                                                    <td>{item.unskilled}</td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
            
                            </div>
                        </Accordion.Collapse>
                    </div>
                    <div className="card mt-2">
                        <Accordion.Toggle  className="card-header d-flex justify-content-between mb-0" as={Card.Header} variant="link" eventKey="4">
                            <span className="my-auto" style={{height:"min-content"}}>GMOU</span>
                            <i className="fa fa-caret-down"></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                            <div className="card-body" style={{overflow:"scroll"}} >
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>GMOU Records for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={gmouStats.community_id} onChange={(e)=>setGMOUStats({...gmouStats,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:GMOU})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Duration of Tenure (in years)</th>
                                            <th>Document</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_gmouStat && current_gmouStat.gmou_stats.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.tenure_duration}</td>
                                                    <td><a target="_blank" href={item.document.url}>View Document</a></td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
                            </div>
                        </Accordion.Collapse>
                    </div>
                    <div className="card mt-2">
                        <Accordion.Toggle  className="card-header d-flex justify-content-between mb-0" as={Card.Header} variant="link" eventKey="5">
                            <span className="my-auto" style={{height:"min-content"}}>Social Investment</span>
                            <i className="fa fa-caret-down"></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="5">
                            <div className="card-body" style={{overflow:"scroll"}} >
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Social Investment Records for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={socialInvestmentStats.community_id} onChange={(e)=>setSocialInvestmentStats({...socialInvestmentStats,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:SOCIAL_INVESTMENT})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Project</th>
                                            <th>Beneficiary</th>
                                            <th>Date Completed</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_socialInvestmentStat && current_socialInvestmentStat.social_investment_stats.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.project}</td>
                                                    <td>{item.beneficiary}</td>
                                                    <td>{item.completed_on}</td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
                            </div>
                        </Accordion.Collapse>
                    </div>
                    <div className="card mt-2">
                        <Accordion.Toggle  className="card-header d-flex justify-content-between mb-0" as={Card.Header} variant="link" eventKey="6">
                            <span className="my-auto" style={{height:"min-content"}}>Philanthropy</span>
                            <i className="fa fa-caret-down"></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="6">
                            <div className="card-body" style={{overflow:"scroll"}} >
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Philanthropy Projects for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={philanthropyStats.community_id} onChange={(e)=>setPhilanthropyStats({...philanthropyStats,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:PHILANTHROPY})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Project</th>
                                            <th>Beneficiary</th>
                                            <th>Date Completed</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_philanthropyStat && current_philanthropyStat.philanthropy_stats.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.project}</td>
                                                    <td>{item.beneficiary}</td>
                                                    <td>{item.completed_on}</td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
                            </div>
                        </Accordion.Collapse>
                    </div>
                    <div className="card mt-2">
                        <Accordion.Toggle  className="card-header d-flex justify-content-between mb-0" as={Card.Header} variant="link" eventKey="7">
                            <span className="my-auto" style={{height:"min-content"}}>IDP</span>
                            <i className="fa fa-caret-down"></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="7">
                            <div className="card-body" style={{overflow:"scroll"}} >
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>IDP Projects for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={idpStats.community_id} onChange={(e)=>setIDPStats({...idpStats,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:IDP})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Project</th>
                                            <th>Beneficiary</th>
                                            <th>Date Completed</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_idpStat && current_idpStat.idp_stats.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.project}</td>
                                                    <td>{item.beneficiary}</td>
                                                    <td>{item.completed_on}</td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
                            </div>
                        </Accordion.Collapse>
                    </div>
                    <div className="card mt-2">
                        <Accordion.Toggle  className="card-header d-flex justify-content-between mb-0" as={Card.Header} variant="link" eventKey="8">
                            <span className="my-auto" style={{height:"min-content"}}>Humanitarian</span>
                            <i className="fa fa-caret-down"></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="8">
                            <div className="card-body" style={{overflow:"scroll"}} >
                                <div className="py-2 d-flex justify-content-between">
                                    <h4>Humanitarian Projects for </h4>
                                    <select className="form-control" style={{maxWidth:200}} selected={humanitarianStats.community_id} onChange={(e)=>setHumanitarianStats({...humanitarianStats,community_id:e.target.value})}>
                                        <option disabled>Select Community</option>
                                            {communities.data ? communities.data.map(item=>
                                                <option value={item.id}>{item.name}</option>
                                            ):""}
                                        </select>
                                    <button className="btn btn-success" onClick={()=>{setCreate(true); setCreateData({...createData,type:HUMANITARIAN})}}>Add new record</button>
                                </div>
                                <div style={{overflow:"scroll"}}><table class="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Year</th>
                                            <th>Project</th>
                                            <th>Beneficiary</th>
                                            <th>Date Completed</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            current_humanitarianStat && current_humanitarianStat.humanitarian_stats.map((item,index)=>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.project}</td>
                                                    <td>{item.beneficiary}</td>
                                                    <td>{item.completed_on}</td>
                                                    {/* <td></td> */}
                                                </tr>
                                                )
                                        }
                                    </tbody>
                                </table></div>
                            </div>
                        </Accordion.Collapse>
                    </div>
                </Accordion>
                </div>
            </div>
        </div>
      <Modal show={create} onHide={()=>setCreate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleCreate}>
                {createFields.map(item=>
                    item.type == 'string' ?
                        <div className="form-group">
                            <label>{item.label}</label>
                            <input required type="text" className="form-control" id={`${item.field}`} value={createData.data[item.field] ? createData.data[item.field] : ""} onChange={handleCreateData}/>
                        </div>
                        : 
                        item.type == 'integer' ?
                            <div className="form-group">
                                <label>{item.label}</label>
                                <input required type="number" className="form-control" id={`${item.field}`} value={createData.data[item.field] ? createData.data[item.field] : ""} onChange={handleCreateData}/>
                            </div>
                            : 
                            item.type == "year" ?
                                
                                <div className="form-group">
                                    <label>{item.label}</label>
                                    <DatePicker 
                                        id={`${item.field}`}  
                                        className="form-control"
                                        showYearPicker
                                        dateFormat="yyyy"
                                        // selected={new Date()}
                                        selected={createData.data[item.field] ? moment(createData.data[item.field], 'YYYY/MM/DD').toDate() : new Date()}
                                        onChange={(e)=>setCreateData({type:createData.type,data:{...createData.data, [item.field] :moment(e).format('YYYY/MM/DD')}})}
                                    />
                                </div>
                                :
                                item.type == "decimal" ?
                                
                                    <div className="form-group">
                                        <label>{item.label}</label>
                                        <input type="number" step="0.01" className="form-control" id={`${item.field}`} value={createData.data[item.field] ? createData.data[item.field] : ""} onChange={handleCreateData}/>
                                    </div>
                                    :
                                    item.type == "tiny_decimal" ?
                                        <div className="form-group">
                                            <label>{item.label}</label>
                                            <input type="number" min="0" max="1" step="0.01" className="form-control" id={`${item.field}`} value={createData.data[item.field] ? createData.data[item.field] : ""} onChange={handleCreateData}/>
                                        </div>
                                        :
                                        item.type == "document" ?
                                            <div className="form-group">
                                                <label>{item.label}</label>
                                                <Upload onUploaded={data=> setCreateData({type:createData.type,data:{...createData.data, [item.field] :data.data.id}})} type="general" title={item.label}/>
                                            </div>
                                            :
                                                item.type == "date" ?
                                                    <DatePicker id={`${item.field}`}  
                                                    className="form-control" 
                                                    selected={createData.data[item.field] ? moment(createData.data[item.field], 'YYYY/MM/DD').toDate() : new Date()}
                                                    onChange={(e)=>setCreateData({type:createData.type,data:{...createData.data, [item.field] :moment(e).format('YYYY/MM/DD')}})}
                                                />
                                                :
                                         ""
                )}
                <button className={'btn btn-success w-100 mt-3'}>Add</button>
            </form>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}
