import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';

import { getToken } from "../../utils/tokenConfig";
import * as message from './messageActions';

const getAllIssuesStart = () => ({
    type: types.GET_ALL_ISSUES_START
})
const getAllIssuesSuccess = (data) => ({
    type: types.GET_ALL_ISSUES_SUCCESS,
    payload: data
})
const getAllIssuesFailed = () => ({
    type: types.GET_ALL_ISSUES_FAILED
})

const createIssuesStart = () => ({
  type: types.CREATE_ISSUE_START
})
const createIssuesSuccess = (data) => ({
  type: types.CREATE_ISSUE_SUCCESS,
  payload: data
})
const createIssuesFailed = () => ({
  type: types.CREATE_ISSUE_FAILED
})
export const getAllIssues = () => dispatch => {
    dispatch(getAllIssuesStart())
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_ISSUES
    axios 
      .get(endpoint, config)
      .then(res => {
        //console.log(res)
        dispatch(getAllIssuesSuccess(res.data))
      }).catch(err => {
          dispatch(getAllIssuesFailed())
      });
  }
  export const createIssues = (data) => dispatch => {
    dispatch(message.messageStart());
      dispatch(createIssuesStart())
      const config = {
        headers: {
          "Accept": "application/json, text/plain, */*",
        }
      }
      const formData = Object.keys(data).reduce((formData, key) => {
                          formData.append(key, data[key]);
                          return formData;
                      }, new FormData());
      formData.append('access_token',getToken())
      const endpoint = endpoints.CREATE_ISSUE
      //console.log(JSON.stringify(Object.fromEntries(formData)));
      axios 
        .post(endpoint, formData, config)
        .then(res => {
          //console.log(res)
          dispatch(getAllIssues())
          dispatch(message.messageSuccess({message:res.data.message}));
        }).catch(err => {
          //console.log(err)
        });
    }
    export const updateIssue = (data) => dispatch => {
      dispatch(message.messageStart());
        const config = {
          headers: {
            "Accept": "application/json, text/plain, */*",
          }
        }
        const formData = Object.keys(data).reduce((formData, key) => {
                            formData.append(key, data[key]);
                            return formData;
                        }, new FormData());
        formData.append('access_token',getToken())
        const endpoint = endpoints.UPDATE_ISSUE
        //console.log(JSON.stringify(Object.fromEntries(formData)));
        axios 
          .post(endpoint, formData, config)
          .then(res => {
            //console.log(res)
            dispatch(getAllIssues())
            dispatch(message.messageSuccess({message:res.data.message}));
          }).catch(err => {
            //console.log(err)
          });
      }
  export const deleteIssue = (id) => dispatch => {
    dispatch(message.messageStart());
      const config = {
        headers: {
          "Accept": "application/json, text/plain, */*",
        }
      }
      const formData = new FormData();
      formData.append('access_token',getToken())
      const endpoint = endpoints.ISSUES;
      axios 
        .post(`${endpoint}${id}/delete`, formData, config)
        .then(res => {
          //console.log(res)
          dispatch(getAllIssues())
          dispatch(message.messageSuccess({message:res.data.message}));
        }).catch(err => {
          //console.log(err)
        });
    }
