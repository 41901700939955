import * as types from '../actions/actionTypes';
import slugify from 'slugify';

const funding = {
    label: "Funding and Budget",
    path: null,
    submenu: [
        {
            label: "View Current Year's Budget",
            path: '/current-year-budget'
        },
        {
            label: "Historical Budget Records",
            path: '/historical'
        }
    ]
  }
const stakeholders = {
    label: "Stakeholder Management",
    path: null,
    submenu: [
    ]
}
const documents = {
    label: "Document Management",
    path: null,
    submenu: [
    ]
}
const issues_grievances = {
    label: "Issues and Grievances",
    path: null,
    submenu: [
        {
            label: "Slow Burn Issues",
            path: '/issues/slowburn'
        },
        {
            label: "Critical / Urgent Issues",
            path: '/issues/critical'
        },
        {
            label: "Grievances",
            path: '/grievances'
        }
    ]
  }
  const project_management = {
      label: "Project Management",
      path: null,
      submenu: [
          {
              label: "Project",
              path: '/project',
              external: "https://project.microsoft.com/"
          },
          {
              label: "Tracking",
              path: '/tracking',
              external: "https://project.microsoft.com/"
          },
          {
              label: "KEFEES Projects",
              path: '/kefees-projects',
              external: "https://project.microsoft.com/"
          }
      ]
      }
      const dashboard = {
          label: "Dashboard",
          path: null,
          submenu: [
              {
                  label: "Education",
                  path: '/dashboard/education'
              },
              {
                  label: "Health",
                  path: '/dashboard/health'
              },
              {
                  label: "Enterprise",
                  path: '/dashboard/enterprise'
              },
              {
                  label: "Local Content",
                  path: '/dashboard/local-content'
              },
              {
                  label: "GMOU",
                  path: '/dashboard/gmou'
              },
              {
                  label: "Social Investments",
                  path: '/dashboard/social-investments'
              },
              {
                    label: "Grants and Donations",
                    path:"#",
                    submenu: [
                        {
                            label: "Philanthropy",
                            path: '/dashboard/philanthropy'
                        },
                        {
                            label: "IDP",
                            path: '/dashboard/IDP'
                        },
                        {
                            label: "Humanitarian",
                            path: '/dashboard/humanitarian'
                        }
                    ]
              },
              {
                  label: "Generate Reports",
                  path: '/dashboard/reports'
              }
          ]
          }
const initialState = {
    dashboard,
    funding,
    stakeholders,
    documents,
    issues_grievances,
    project_management,
    isLoading: false,
};
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_STAKEHOLDERS_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_STAKEHOLDERS_SUCCESS:
            stakeholders.submenu=[]
            action.payload.forEach(element => {
                let subsubmenu = [];
                element.children.forEach(subelement=>{
                    let subsubsubmenu = [];
                    subelement.children.forEach(subsubelement=>{
                        let subsubsubsubmenu = [];
                        subsubelement.children.forEach(subsubsubelement=>{
                            subsubsubsubmenu.push({label: subsubsubelement.name, path: `/stakeholders/${subsubsubelement.slug}`})
                        })
                        subsubsubmenu.push({label: subsubelement.name, path: subsubelement.children.length==0 ? `/stakeholders/${subsubelement.slug}` : "#", submenu:subsubsubsubmenu})
                    })
                    subsubmenu.push({label: subelement.name, path: subelement.children.length==0 ? `/stakeholders/${subelement.slug}` : "#", submenu:subsubsubmenu})
                })
                stakeholders.submenu.push({label: element.name, path: element.children.length==0 ? `/stakeholders/${element.slug}` : "#", submenu:subsubmenu})
            });
            return {
                ...state,
                stakeholders,
                isLoading:false
            }
        case types.GET_ALL_DOCUMENTS_SUCCESS:
            documents.submenu=[]
            action.payload.menu.forEach(element => {
                let subsubmenu = [];
                element.children.forEach(subelement=>{
                    let subsubsubmenu = [];
                    subelement.children.forEach(subsubelement=>{
                        let subsubsubsubmenu = [];
                        subsubelement.children.forEach(subsubsubelement=>{
                            subsubsubsubmenu.push({label: subsubsubelement.name, path: `/documents/${subsubsubelement.id}`})
                        })
                        subsubsubmenu.push({label: subsubelement.name, path: subsubelement.children.length==0 ? `/documents/${subsubelement.id}` : "#", submenu:subsubsubsubmenu})
                    })
                    subsubmenu.push({label: subelement.name, path: subelement.children.length==0 ? `/documents/${subelement.id}` : "#", submenu:subsubsubmenu})
                })
                documents.submenu.push({label: element.name, path: element.children.length==0 ? `/documents/${element.id}` : "#", submenu:subsubmenu})
            });
            // action.payload.menu.forEach(element => {
            //     let subsubmenu = [];
            //     element.children.forEach(subelement=>{
            //         subsubmenu.push({label: subelement.name, path: `/documents/${subelement.id}`})
            //     })
            //     documents.submenu.push({label: element.name, path: element.children.length==0 ? `/documents/${element.id}` : "#", submenu:subsubmenu})
            // });
            return {
                ...state,
                documents,
                isLoading:false
            }
        default:
            return state;
    }
}