import React, { useState } from 'react';

const EditableOption = ({name,options,value,editClassName,onEnter}) => {
    const [state, setState] = useState({
        name,
        value: value||1,
        editClassName,
        edit: false});
    const edit = ()=> {
        setState({edit:edit!==false})
    }
    return (
        state.edit===true&&
        <select 
          name={state.name}
          type={state.type}
          value={state.value}
          className={state.editClassName}
          autoFocus
          onFocus={event=>{
            const value = event.target.value
            event.target.value = ''
            event.target.value = value
            setState({...state,backup:state.value})
          }}
          onChange={event=>{
            //console.log(event.target.value)
            setState({...state,value:event.target.value})
          }}
          onBlur={event=>{
            setState({...state,edit:false})
            onEnter(state.value);
          }}
        >
          {options.map(item =>
            <option value={item.value}>{item.title}</option>
            )}
        </select>
        ||
        <span onClick={event=>{
            setState({...state,edit:true})
          }} style={{borderBottom: '1px dotted #666'}}>
          {options && options.find(option=> {return option.value == state.value}).title}
        </span>
    );
};

export default EditableOption;