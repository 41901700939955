import { combineReducers } from 'redux';
import userReducer from './userReducer';
import menuReducer from './menuReducer';
import budgetReducer from './budgetReducer';
import issueReducer from './issueReducer';
import stakeholderReducer from './stakeholderReducer';
import stakeholdersReducer from './stakeholdersReducer';
import communityReducer from './communityReducer';
import authReducer from './authReducer';
import documentReducer from './documentReducer';
import grievanceReducer from './grievanceReducer';
import educationReducer from './educationReducer';
import healthReducer from './healthReducer';
import enterpriseReducer from './enterpriseReducer';
import localContentReducer from './localContentReducer';
import gmouReducer from './gmouReducer';
import socialInvestmentReducer from './socialInvestmentReducer';
import idpReducer from './idpReducer';
import philanthropyReducer from './philanthropyReducer';
import humanitarianReducer from './humanitarianReducer';
import activityReducer from './activityReducer';
import scheduleReducer from './scheduleReducer';
import messageReducer from './messageReducer';
import currencyReducer from './currencyReducer';

const rootReducer = combineReducers({
  menu: menuReducer,
  auth: authReducer,
  users: userReducer,
  budget: budgetReducer,
  issues: issueReducer,
  grievances: grievanceReducer,
  documents: documentReducer,
  stakeholder: stakeholderReducer,
  communities: communityReducer,
  all_stakeholders: stakeholdersReducer,
  education: educationReducer,
  health: healthReducer,
  enterprise: enterpriseReducer,
  localContent: localContentReducer,
  gmou: gmouReducer,
  socialInvestment: socialInvestmentReducer,
  philanthropy: philanthropyReducer,
  idp: idpReducer,
  humanitarian: humanitarianReducer,
  activities: activityReducer,
  schedules: scheduleReducer,
  message: messageReducer,
  currency: currencyReducer,
});

export default rootReducer;


