import React, {useEffect} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import * as issueActions from '../../redux/actions/issueActions';
import Layout from '../../components/Layout';

export default function Issues({type}) {
    const all_issues = useSelector(state => state.issues);
    const {slow_burn, critical} = all_issues.data;
    const issues = type == "slow_burn" ? slow_burn : critical;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(issueActions.getAllIssues());
        return () => {
            dispatch(issueActions.getAllIssues());
        };
    },[]);

  return (

    <Layout>
        <BreadCrumbSection
            content={`${type=="slow_burn"?"Slow Burn" : "Critical"} Issues`}
        />
        <div className="container p-3">
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Issue Description</th>
                            <th>Date Received</th>
                            <th>Date Submitted</th>
                            <th>Status</th>
                            <th>Close Out Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {issues && issues.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.description}</td>
                                <td>{item.received_on || <span className="text-info">Not set</span>}</td>
                                <td>{item.submitted_on}</td>
                                <td>{item.status === 0 ? <div className="badge badge-danger">Pending</div> : 
                                                item.status == 1 ? <div className="badge badge-warning">Ongoing</div> : 
                                                item.status == 2 ? <div className="badge badge-info">Escalated</div> : 
                                                item.status == 3 ? <div className="badge badge-success">Closed</div> : ""
                                                }</td>
                                <td>{item.closed_on || <span className="text-info">Not closed yet</span>}</td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
        </div>
    </Layout>
  )
}
