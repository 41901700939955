import * as types from './../actions/actionTypes';


const initialState = {
  data: [],
  isLoading: false,
  user: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.USER_START:
        return {
          ...state, 
          isLoading: true,
        }
    case types.GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false
      }
    }
    case types.USER_SUCCESS: {
      return {
        ...state,
        isLoading: false
      }
    }
    case types.GET_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        user: action.data
      }
    }
    case types.USER_FAILED:
        return {
          ...state,
          data: [],
          isLoading: false,
          user: ''
        }
    default:
     return state;
  }
}