import React, {useEffect, useState} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import { useSelector, useDispatch} from "react-redux";
import * as localContentActions from '../../redux/actions/localContentActions';
import Layout from '../../components/Layout';
import C3Chart from 'react-c3js';
import 'c3/c3.css';
import { Converter } from '../../components/helper';

export default function LocalContent({type}) {
    const {localContentStats} = useSelector(state => state.localContent);
    const communities = useSelector(state => state.communities);
    const [community, setCommunity] = useState(1);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(localContentActions.getAllLocalContentStats());
        return () => {
        };
    },[]);
    let procurement_chat_data = {};
    let employment_chat_data = {};
    const groupByYearAndAdd = (array)=>{
        let years = []
        let totals = []
        let counts = []
        while(array.length > 0){
            years.push(array[0].year);
            let this_year_values = array.filter(item => item.year == array[0].year);
            totals.push(this_year_values.reduce((prev,next) => prev + parseInt(next.value),0));
            counts.push(this_year_values.length)
            array = array.filter(item=>item.year != array[0].year)
        };
        return ([years,totals,counts])
    }
    let procurement_details = localContentStats.length>0 ? groupByYearAndAdd(localContentStats.find(item=>item.id == community).procurement_stats):[[],[],[]]
    let employment_details = localContentStats.length>0 ? localContentStats.find(item=>item.id == community).employment_stats: [];
    procurement_chat_data = {
        x:"Years",
        columns: [
            ['Years'].concat(procurement_details[0]),
            ['Number of Procurement Made'].concat(procurement_details[2]),
            ['Value of Procurement'].concat(procurement_details[1]),
        ],
        axes: {
          'Value of Procurement': 'y2',
        }
    }
    employment_chat_data = {
        x:"Years",
        columns: [
            ['Years'].concat(employment_details.map(item=>item.year)),
            ['Number of Unskilled Labour'].concat(employment_details.map(item=>item.unskilled)),
            ['Number of Semi-Skilled Labour'].concat(employment_details.map(item=>item.semi_skilled)),
            ['Number of Skilled Labour'].concat(employment_details.map(item=>item.skilled)),
        ]
    }

  return (

    <Layout>
        <BreadCrumbSection
            content={"Dashboard / Local Content"}
        />
        <div className="container p-3">
            <select className="form-control my-5" style={{maxWidth:200}} selected={community} onChange={(e)=>setCommunity(e.target.value)}>
                <option disabled>Select Community</option>
                    {communities.data ? communities.data.map(item=>
                        <option value={item.id}>{item.name}</option>
                    ):""}
                </select>
                <div>
                    
                </div>
            <h4>Procurement Statistics </h4>
            <C3Chart data={procurement_chat_data} axis={{y2:{show:true}}}/>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Item Name</th>
                            <th>Value of Item</th>
                        </tr>
                    </thead>
                    <tbody>
                        {localContentStats.length>0 && localContentStats.find(item=>item.id == community).procurement_stats.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td>{item.item_name}</td>
                                <td><Converter value={item.value}/></td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
            <h4>Employment Statistics </h4>
            <C3Chart data={employment_chat_data}/>
            <div className="table-responsive">
                <div style={{overflow:"scroll"}}><table class="table bg-white">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Number of Unskilled Labour</th>
                            <th>Number of Semi-Skilled Labour</th>
                            <th>Number of Skilled Labour</th>
                        </tr>
                    </thead>
                    <tbody>
                        {localContentStats.length>0 && localContentStats.find(item=>item.id == community).employment_stats.map((item,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.year}</td>
                                <td>{item.unskilled}</td>
                                <td>{item.semi_skilled}</td>
                                <td>{item.skilled}</td>
                            </tr>

                        ))}
                    
                    </tbody>
                </table></div>
            </div>
        </div>
    </Layout>
  )
}
