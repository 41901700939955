import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';

import { getToken } from "../../utils/tokenConfig";
import * as message from './messageActions';

const getAllPhilanthropyStatsStart = () => ({
    type: types.GET_ALL_PHILANTHROPY_STATS_START
})

const getAllPhilanthropyStatsSuccess = (data) => ({
    type: types.GET_ALL_PHILANTHROPY_STATS_SUCCESS,
    payload: data
})

const getAllPhilanthropyStatsFailed = () => ({
    type: types.GET_ALL_PHILANTHROPY_STATS_FAILED
})

const createPhilanthropyStatStart = () => ({
  type: types.CREATE_PHILANTHROPY_STAT_START
})

const createPhilanthropyStatSuccess = (data) => ({
  type: types.CREATE_PHILANTHROPY_STAT_SUCCESS,
  payload: data
})

const createPhilanthropyStatFailed = () => ({
  type: types.CREATE_PHILANTHROPY_STAT_FAILED
})

export const getAllPhilanthropyStats = () => dispatch => {
    dispatch(getAllPhilanthropyStatsStart())
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_PHILANTHROPY_STATS
    axios 
      .get(endpoint, config)
      .then(res => {
        //console.log(res)
        dispatch(getAllPhilanthropyStatsSuccess(res.data))
      }).catch(err => {
          dispatch(getAllPhilanthropyStatsFailed())
      });
  }

export const createPhilanthropyStat = (data) => dispatch => {
  dispatch(message.messageStart());
    dispatch(createPhilanthropyStatStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('access_token',getToken())
    const endpoint = endpoints.CREATE_PHILANTHROPY_STAT
    //console.log(JSON.stringify(Object.fromEntries(formData)));
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        //console.log(res)
        dispatch(createPhilanthropyStatSuccess(res.data.data))
        dispatch(getAllPhilanthropyStats())
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        //console.log(err)
        dispatch(createPhilanthropyStatFailed(err.response.data))
      });
  }