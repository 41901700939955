import * as types from '../actions/actionTypes';

const idp = {
    idpStats: [],
    isLoading: false,
};
export default function reducer(state = idp, action) {
    switch (action.type) {
        case types.GET_ALL_IDP_STATS_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_IDP_STATS_SUCCESS:
            return {
                ...state,
                idpStats: action.payload
            }
        case types.CREATE_IDP_STAT_START:
            return {
                ...state,
                isAdding: true,
            };
        case types.CREATE_IDP_STAT_SUCCESS:
            return {
                ...state,
                isAdding: false,
            }
        case types.CREATE_IDP_STAT_FAILED:
            return {
                ...state,
                addingError: ([action.payload]),
                isAdding:false
            }
        default:
            return state;
    }
}