import * as types from '../actions/actionTypes';

const gmou = {
    gmouStats: [],
    isLoading: false,
};
export default function reducer(state = gmou, action) {
    switch (action.type) {
        case types.GET_ALL_GMOU_STATS_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_ALL_GMOU_STATS_SUCCESS:
            return {
                ...state,
                gmouStats: action.payload
            }
        case types.CREATE_GMOU_STAT_START:
            return {
                ...state,
                isAdding: true,
            };
        case types.CREATE_GMOU_STAT_SUCCESS:
            return {
                ...state,
                isAdding: false,
            }
        case types.CREATE_GMOU_STAT_FAILED:
            return {
                ...state,
                addingError: ([action.payload]),
                isAdding:false
            }
        default:
            return state;
    }
}