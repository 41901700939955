import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';

import { getToken } from "../../utils/tokenConfig";
import * as message from './messageActions';

const getAllDocumentsStart = () => ({
    type: types.GET_ALL_DOCUMENTS_START
})
const getAllDocumentsSuccess = (data) => ({
    type: types.GET_ALL_DOCUMENTS_SUCCESS,
    payload: data
})
const getAllDocumentsFailed = () => ({
    type: types.GET_ALL_DOCUMENTS_FAILED
})

const createDocumentStart = () => ({
  type: types.CREATE_DOCUMENT_START
})
const createDocumentSuccess = (data) => ({
  type: types.CREATE_DOCUMENT_SUCCESS,
  payload: data
})
const createDocumentFailed = () => ({
  type: types.CREATE_DOCUMENT_FAILED
})
export const getAllDocuments = () => dispatch => {
    dispatch(getAllDocumentsStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.GET_ALL_DOCUMENTS
    axios 
      .get(endpoint, config)
      .then(res => {
          //console.log(res);
        dispatch(getAllDocumentsSuccess(res.data))
        let mapFunction = item => {
            let submenu = [];
            if (item.children.length>0){
                submenu = item.children.map(mapFunction)
            }
            return {
                name: item.name,
                submenu,
                documents:item.documents
            }
        }
        let menu_documents = res.data.menu.map(mapFunction);
        //console.log(menu_documents)
      }).catch(err => {
          dispatch(getAllDocumentsFailed())
      });
  }
export const createDocument = (data, callback) => dispatch => {
    dispatch(createDocumentStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('access_token',getToken())
    const endpoint = endpoints.CREATE_DOCUMENT
    //console.log(JSON.stringify(Object.fromEntries(formData)));
    return axios({
        url: endpoint,
        method: 'post',
        data: formData,
        onUploadProgress: callback.progress,
        headers: {
            "Accept": "application/json, text/plain, */*",
          }
    })
      .then(res => {
        // console.log(res)
        callback.load(res.data);
        dispatch(message.messageSuccess({message:"Document(s) saved"}));
        // dispatch(createDocumentSuccess(res.data.data))
      }).catch(err => {
          callback.error()
        // console.log(err)
        //   dispatch(createDocumentFailed(err.response.data))
      });
  }

  const createDocumentCategoryStart = () => ({
    type: types.CREATE_DOCUMENT_CATEGORY_START
  })
  const createDocumentCategorySuccess = (data) => ({
    type: types.CREATE_DOCUMENT_CATEGORY_SUCCESS,
    payload: data
  })
  const createDocumentCategoryFailed = () => ({
    type: types.CREATE_DOCUMENT_CATEGORY_FAILED
  })
  export const addDocumentToCategory = (data,broadcast=true) => dispatch => {
    broadcast && dispatch(message.messageStart());
    
    const formData = Object.keys(data).reduce((formData, key) => {
        formData.append(key, data[key]);
        return formData;
    }, new FormData());
    formData.append('access_token',getToken())
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.DOCUMENT_CATEGORIES + '/add_document'
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        broadcast && dispatch(getAllDocuments())
        broadcast && dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
      });
  }
  export const createDocumentCategory = (data) => dispatch => {
    dispatch(message.messageStart());
    dispatch(createDocumentCategoryStart())
    // const token = tokenConfig.getToken()
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const fields = Object.entries(data.fields);
    delete data.fields;
    const formData = Object.keys(data).reduce((formData, key) => {
                        formData.append(key, data[key]);
                        return formData;
                    }, new FormData());
    formData.append('access_token',getToken())
    //console.log(data);
    const endpoint = endpoints.DOCUMENT_CATEGORIES + '/create'
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        dispatch(createDocumentCategorySuccess(res.data.data))
        fields.forEach(([i,value],index)=>{
          let category_id = res.data.data.id;
          let data = {
            category_id,
            document_id :  value.value.id
          }
          dispatch(addDocumentToCategory(data,index == (fields.length - 1)))
        })
        if(fields.length==0){
          dispatch(getAllDocuments())
        }
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
          dispatch(createDocumentCategoryFailed(err.response.data))
      });
  }
  export const updateDocumentCategory = (payload) => dispatch => {
    dispatch(message.messageStart());
    const fields = Object.entries(payload.fields).filter(item=> !item[1].id);
    const config = {
      headers: {
        "Accept": "application/json, text/plain, */*",
      }
    }
    const formData = new FormData();
    formData.append('access_token',getToken())
    formData.append('name',payload.name)
    formData.append('id',payload.id)
    const endpoint = endpoints.UPDATE_DOCUMENT_CATEGORY
    axios 
    .post(endpoint, formData, config)
    .then(res => {
      fields.forEach(([i,value],index)=>{
        let category_id = payload.id;
        let data = {
          category_id,
          document_id :  value.value.id
        }
        dispatch(addDocumentToCategory(data,index == (fields.length - 1)))
      })

      dispatch(message.messageSuccess({message:res.data.message}));
      dispatch(getAllDocuments())
    })
  }
  export const trashDocumentCategory = (id) => dispatch => {
    dispatch(message.messageStart());
    let data={
      id,
      access_token: getToken()
    };
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.DOCUMENT_CATEGORIES + '/delete'
    axios 
      .post(endpoint, data, config)
      .then(res => {
        dispatch(getAllDocuments())
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        //console.log(err);
      });
  }
  export const trashDocument = (id) => dispatch => {
    dispatch(message.messageStart());
    let data={id};
    const formData = Object.keys(data).reduce((formData, key) => {
        formData.append(key, data[key]);
        return formData;
    }, new FormData());
    formData.append('access_token',getToken())
    const config = {
      headers: {
       "Accept": "application/json, text/plain, */*",
      }
    }
    const endpoint = endpoints.DOCUMENTS + '/delete'
    axios 
      .post(endpoint, formData, config)
      .then(res => {
        dispatch(getAllDocuments())
        dispatch(message.messageSuccess({message:res.data.message}));
      }).catch(err => {
        //console.log(err);
      });
  }
  