import React, {useEffect} from 'react'
import BreadCrumbSection from '../../components/BreadCrumbSection'
import BudgetCard from '../../components/BudgetCard'
import SpentCard from '../../components/SpentCard'
import { useSelector, useDispatch} from "react-redux";
import * as budgetActions from '../../redux/actions/budgetActions';
import Layout from '../../components/Layout';

export default function CurrentYearBudget() {
    const {current_projects} = useSelector(state => state.budget);
    const budget_document_array = useSelector(state => state.documents.budget);
    const budget_document = budget_document_array.length > 0 ? budget_document_array[0].url : null;
    const doc_status = budget_document ? "success" : "warning"
    let total_budget = current_projects.reduce((a, b) => parseInt(a) + (parseFloat(b["budget"] || 0)), 0);
    let total_spent = current_projects.reduce((a, b) => parseInt(a) + (parseFloat(b["spent"] || 0)), 0)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(budgetActions.getAllCurrentProjects());
        return () => {
            dispatch(budgetActions.getAllCurrentProjects());
        };
    },[]);

  return (

    <Layout>
        <BreadCrumbSection
            content="Funding and Budget / Current Year's Budget"
        />
        <div className="container p-3">
            <div className="row">
                <div className="col-md-4">
                    <BudgetCard title="Budget Figure" value={total_budget} status=""/>
                    <BudgetCard title="Spent till date" value={total_spent} progress={true} against={total_budget} status=""/>
                </div>
                <div className="col-md-4">
                    <div className={"card mt-2  border-"+doc_status}>
                        <div className={"card-header text-"+doc_status}>Budget Approval status</div>
                        <div className="card-body">
                            <p><b>Status:</b> {budget_document ? "Approved" : "Unapproved"}</p>
                            {budget_document && <button className="btn btn-success">View Document <i className="fa fa-document"></i></button>}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card mt-2">
                        <div className="card-header">Current Projects Spendings</div>
                        <div className="card-body" style={{maxHeight: "100vh", overflow: "scroll"}}>
                            {
                                current_projects.map(item=>(
                                    <SpentCard project_name={`${item.name}`} spent={item.spent} budget={item.budget}/>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  )
}
